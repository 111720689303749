<template>
  <div v-if="inactive === false" class="w-100" style="position: relative">
    <CaledarSelectDateLength
      v-show="blnDrop == true"
      style="width: 350px; z-index: 2; position: absolute; bottom: 140%"
      class=""
      :showFirstTime="showFirstTime"
      :showSeconTime="showSeconTime"
      :blncheckPastDate="blncheckPastDate"
      :labelFirstTime="labelFirstTime"
      :labelSecconTime="labelSecconTime"
      :firstDisable="strFirstDisable"
      :secondDisable="strSecondDisable"
      :blnDrop="blnDrop"
      :blnShowAlertInp="blnShowAlertInp"
      :listDataRepeat="listDataRepeat"
      :Value="valueCalenda"
      :numberYearPrev="numberYearPrev"
      @valueCalenda="convertData($event)"
      @blnDrop="
        (e) => {
          blnDrop = e;
        }
      "
      ref="calendar"
    />
    <label
      class="txt-inp d-flex"
      :class="
        size +
        ' ' +
        (strResult == null || strResult == ''
          ? ''
          : alertMsgStatus == true
          ? 'validate'
          : '')
      "
      @click="clickInput()"
    >
      <!-- <Icon icon="uiw:date" class="icon" /> -->
      <CalendarOutline
        width="24"
        heigth="24"
        class="fill-black-500 icon"
        @click="blnDrop = !blnDrop"
      />
      <input
        type="text"
        readonly
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="strResult"
        @keypress="isLetter($event)"
        @keyup.delete="deleteLetter()"
        :class="alertMsgStatus == true ? 'invalid' : '' + size"
        class="w-100"
        title=""
        :placeholder="$t(placeholder)"
        ref="input"
      />
      <Button
        v-if="blnRemove == true"
        style="z-index: 10"
        icon="CloseOutline"
        class="close-dropdown me-4 Remove-icon p-0 m-0"
        classBtnIcon="fill-black-500"
        :class="strResult == '' || strResult == [] ? 'd-none' : ''"
        @click="removeData()"
      />
      <p
        @click="blnDrop = !blnDrop"
        class="label-text bg-white"
        :class="
          strResult == undefined || strResult == null || strResult == ''
            ? device == 'mobile'
              ? 'fnt-caption'
              : ''
            : device == 'mobile'
            ? 'fnt-caption move-mobile'
            : 'move'
        "
      >
        {{ ValidNumber(label) ? label : $t(label) }}
        <span class="sub">{{ labelSub }}</span>
        <span class="require ms-1" v-if="request === true">*</span>
      </p>
    </label>
    <div
      :class="alertMsgStatus == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertMsgStatus == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertMsgInput) }}
    </div>
    <div
      :class="alertLength == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertLength == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertLengthMsg) }}
    </div>
  </div>

  <div class="txt-inp disable-inp" v-else-if="inactive === true">
    <label class="txt-inp">
      <CalendarOutline
        width="24"
        heigth="24"
        class="fill-black-500 icon"
        @click="blnDrop = !blnDrop"
      />
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="strResult"
        @keypress="isLetter($event)"
        @change="Valid()"
        :class="size"
        class="w-100"
        title=""
        disabled
        :placeholder="$t(placeholder)"
        ref="input"
      />
      <p
        class="rounded-2 bg-black-500"
        :class="
          strResult == undefined || strResult == null || strResult == ''
            ? device == 'mobile'
              ? 'fnt-caption'
              : ''
            : device == 'mobile'
            ? 'fnt-caption move-mobile'
            : 'move'
        "
      >
        {{ ValidNumber(label) ? label : $t(label)
        }}<span class="sub">{{ labelSub }}</span>
        <span class="require" v-if="request === true"> *</span>
      </p>
    </label>
  </div>
  <!-- <div
    class="overlay"
    v-if="blnDrop == true"
    @click="
      () => {
        blnDrop = false;
      }
    "
  ></div> -->
  <!-- </div> -->
</template>
  
<script>
import { Icon } from "@iconify/vue";
import { CalendarOutline } from "@/components/icon/index";
import Validation from "@/typescript/validator/validator";
import CaledarSelectDateLength from "@/components/input/CaledarSelectDateLength.vue";
import Button from "@/components/button/button.vue";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      inactive: false,
      strResult: "",
      strValue: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      alertLength: false,
      alertLengthMsg: "",
      blnDrop: false,
      month_names_th: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      month_names_en: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      data: "",
      checkdata: "",
      strFirstDisable: "",
      strSecondDisable: "",
      locales: null,
    };
  },
  components: {
    Icon,
    CaledarSelectDateLength,
    CalendarOutline,
    Button,
  },
  props: {
    request: { type: Boolean, default: false },
    name: { type: String },
    label: { type: String },
    value: { type: String },
    valueCalenda: { type: String },
    statename: { type: String },
    maxlength: { type: String },
    validate: { type: String, default: "" },
    labelSub: { type: String }, // เป็น Label เสริมเมื่อทำการ Select จะหายไป
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    size: { type: String },
    placeholder: { type: String, default: "" },
    showFirstTime: { type: Boolean, default: true },
    showSeconTime: { type: Boolean, default: true },
    blncheckPastTime: { type: Boolean, default: false },
    blncheckPastDate: { type: Boolean, default: false },
    labelFirstTime: { type: String, default: "688" },
    labelSecconTime: { type: String, default: "689" },
    firstDisable: { type: String },
    secondDisable: { type: String },
    listDataRepeat: { type: Array },
    blnShowAlertInp: { type: Boolean, default: false },
    blnRemove: { type: Boolean, default: false },
    numberYearPrev: { type: Number },
  },

  unmounted() {
    document.removeEventListener("click", this.close);
    // clearInterval(this.checkdata);
  },
  beforeUpdate() {
    //เรียกข้อมูลจาก local
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "th";
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (this.inactive !== true) {
      this.inactive = this.showTxtInp;
    }

    if (this.value != null && this.value != "" && this.value != undefined) {
      this.strResult = this.value;
    }
    setTimeout(() => {
      if (
        this.valueCalenda != null &&
        this.valueCalenda != "" &&
        this.valueCalenda != undefined
      ) {
        if (typeof this.valueCalenda == "string") {
          if (this.showFirstTime == true && this.showSeconTime == true) {
            if (this.valueCalenda.length == 33) {
              this.data = this.valueCalenda;
              let splitData = this.data.split("/");
              let publicDD = splitData[0];
              let publicMM = splitData[1];
              let publicYYYY = splitData[2];
              let publicTime = splitData[3];
              let endDD = splitData[4];
              let endMM = splitData[5];
              let endYYYY = splitData[6];
              let endTime = splitData[7];
              this.strValue =
                publicDD +
                " " +
                (this.$i18n.locale == "th"
                  ? this.month_names_th[parseInt(publicMM)]
                  : this.month_names_en[parseInt(publicMM)]) +
                " " +
                (this.$i18n.locale == "th"
                  ? publicYYYY
                  : parseInt(publicYYYY) - 543) +
                " " +
                publicTime +
                (this.$i18n.locale == "th" ? " น." : "") +
                " - " +
                endDD +
                " " +
                (this.$i18n.locale == "th"
                  ? this.month_names_th[parseInt(endMM)]
                  : this.month_names_en[parseInt(endMM)]) +
                " " +
                (this.$i18n.locale == "th"
                  ? endYYYY
                  : parseInt(endYYYY) - 543) +
                " " +
                endTime +
                (this.$i18n.locale == "th" ? " น." : "");
              // ---------------------------------------------------------------------------
              if (this.blnDrop == true) {
                let mount = parseInt(publicMM) + 1;
                this.startDate =
                  publicDD +
                  (mount.toString().length == 2
                    ? mount.toString()
                    : "0" + mount.toString()) +
                  publicYYYY;
                this.startTime = publicTime;
                this.endDate = endDD + endMM + endYYYY;
                this.endTime = endTime;
              }

              this.strResult = this.strValue;
              this.strFirstDisable = this.valueCalenda.slice(0, 16);
            }
          } else if (
            this.showFirstTime == true &&
            this.showSeconTime == false
          ) {
            if (this.valueCalenda.length == 33) {
              this.data = this.valueCalenda;
              let splitData = this.data.split("/");
              let publicDD = splitData[0];
              let publicMM = splitData[1];
              let publicYYYY = splitData[2];
              let publicTime = splitData[3];
              this.strValue =
                publicDD +
                " " +
                (this.$i18n.locale == "th"
                  ? this.month_names_th[parseInt(publicMM)]
                  : this.month_names_en[parseInt(publicMM)]) +
                " " +
                (this.$i18n.locale == "th"
                  ? publicYYYY
                  : parseInt(publicYYYY) - 543) +
                " " +
                publicTime +
                (this.$i18n.locale == "th" ? " น." : "");
              // ---------------------------------------------------------------------------
              if (this.blnDrop == true) {
                let mount = parseInt(publicMM) + 1;
                this.startDate =
                  publicDD +
                  (mount.toString().length == 2
                    ? mount.toString()
                    : "0" + mount.toString()) +
                  publicYYYY;
                this.startTime = publicTime;
              }

              this.strResult = this.strValue;

              this.strFirstDisable = this.valueCalenda.slice(0, 16);
              this.strSecondDisable = this.valueCalenda.slice(17, 33);
            }
          } else {
            // console.log(this.valueCalenda);
            this.data = this.valueCalenda;
            if (
              this.valueCalenda.length == 10 ||
              this.valueCalenda.length == 9
            ) {
              let splitData = this.data.split("/");
              let publicDD = splitData[0];
              let publicMM = splitData[1];
              let publicYYYY = splitData[2];
              this.strValue =
                publicDD +
                " " +
                (this.locales == "th"
                  ? this.month_names_th[parseInt(publicMM)]
                  : this.month_names_en[parseInt(publicMM)]) +
                " " +
                (this.locales == "th"
                  ? publicYYYY
                  : parseInt(publicYYYY) - 543) +
                " ";
            } else {
              let splitData = this.data.split(" ")[0].split("-");
              let publicDD = splitData[0];
              let publicMM = splitData[1];
              let publicYYYY = splitData[2];
              this.strValue =
                publicDD +
                " " +
                (this.locales == "th"
                  ? this.month_names_th[parseInt(publicMM)]
                  : this.month_names_en[parseInt(publicMM)]) +
                " " +
                (this.locales == "th"
                  ? publicYYYY
                  : parseInt(publicYYYY) - 543) +
                " ";
              // console.log(splitData);
            }

            this.strResult = this.strValue;
          }
        }
      }
      if (this.valueCalenda == "") {
        if (this.showFirstTime == true && this.showSeconTime == true) {
          let days_of_month = [
            31,
            this.getFebDays(new Date().getFullYear),
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31,
          ];
          let currDate = new Date();
          let publicDD = currDate.getDate();
          let publicMM = currDate.getMonth();
          let publicYYYY =
            this.$i18n.locale == "th"
              ? currDate.getFullYear() + 543
              : currDate.getFullYear();
          let publicTime =
            (currDate.getHours() + 1 > 23
              ? "00" +
                ":" +
                (currDate.getMinutes().toString().length < 2
                  ? "0" + currDate.getMinutes().toString()
                  : currDate.getMinutes())
              : (currDate.getHours() + 1).toString().length < 2
              ? "0" + (currDate.getHours() + 1).toString()
              : currDate.getHours() + 1) +
            ":" +
            (currDate.getMinutes().toString().length < 2
              ? "0" + currDate.getMinutes().toString()
              : currDate.getMinutes());

          let endDD;
          let endMM;
          if (currDate.getDate() + 1 > days_of_month[currDate.getMonth()]) {
            endDD = "01";
            endMM = currDate.getMonth() + 1;
          } else {
            endDD =
              (currDate.getDate() + 1).toString().length > 1
                ? currDate.getDate() + 1
                : "0" + (currDate.getDate() + 1).toString();
            endMM = currDate.getMonth();
          }
          let endYYYY =
            this.$i18n.locale == "th"
              ? currDate.getFullYear() + 543
              : currDate.getFullYear();
          let endTime =
            (currDate.getHours() > 23
              ? "00" +
                ":" +
                (currDate.getMinutes().toString().length < 2
                  ? "0" + currDate.getMinutes().toString()
                  : currDate.getMinutes())
              : currDate.getHours().toString().length < 2
              ? "0" + currDate.getHours().toString()
              : currDate.getHours()) +
            ":" +
            (currDate.getMinutes().toString().length < 2
              ? "0" + currDate.getMinutes().toString()
              : currDate.getMinutes());

          this.strValue =
            publicDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(publicMM)]
              : this.month_names_en[parseInt(publicMM)]) +
            " " +
            (this.$i18n.locale == "th" ? publicYYYY : publicYYYY) +
            " " +
            publicTime +
            (this.$i18n.locale == "th" ? " น." : "") +
            " - " +
            endDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(endMM)]
              : this.month_names_en[parseInt(endMM)]) +
            " " +
            (this.$i18n.locale == "th" ? endYYYY : endYYYY) +
            " " +
            endTime +
            (this.$i18n.locale == "th" ? " น." : "");

          this.strResult = this.strValue;
          this.strFirstDisable =
            publicDD + "/" + publicMM + "/" + publicYYYY + "/" + publicTime;
        } else if (this.showFirstTime == true && this.showSeconTime == false) {
          let currDate = new Date();
          let publicDD = currDate.getDate();
          let publicMM = currDate.getMonth();
          let publicYYYY =
            this.$i18n.locale == "th"
              ? currDate.getFullYear() + 543
              : currDate.getFullYear();
          let publicTime =
            (currDate.getHours() + 1 > 23
              ? "00" +
                ":" +
                (currDate.getMinutes().toString().length < 2
                  ? "0" + currDate.getMinutes().toString()
                  : currDate.getMinutes())
              : (currDate.getHours() + 1).toString().length < 2
              ? "0" + (currDate.getHours() + 1).toString()
              : currDate.getHours() + 1) +
            ":" +
            (currDate.getMinutes().toString().length < 2
              ? "0" + currDate.getMinutes().toString()
              : currDate.getMinutes());

          this.strValue =
            publicDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(publicMM)]
              : this.month_names_en[parseInt(publicMM)]) +
            " " +
            (this.$i18n.locale == "th" ? publicYYYY : publicYYYY) +
            " " +
            publicTime +
            (this.$i18n.locale == "th" ? " น." : "");

          this.strResult = this.strValue;
        }
      }
      if (this.valueCalenda == null) {
        if (this.showFirstTime == true && this.showSeconTime == true) {
          let currDate = new Date();
          let publicDD = currDate.getDate();
          let publicMM = currDate.getMonth();
          let publicYYYY = currDate.getFullYear() + 543;
          this.strFirstDisable = publicDD + "/" + publicMM + "/" + publicYYYY;
        }else{
          this.strSecondDisable = this.secondDisable
        }
      }
    }, 2000);

    this.onClickOutSide();
  },
  watch: {
    strResult(newstrResult, oldstrResult) {
      // console.log(newstrResult);
      // console.log(oldstrResult);
      // ตรวจสอบข้อมูลเมื่อข้อมูลมีการเปลี่ยนแปลง
      if (newstrResult != oldstrResult) {
        this.Valid();
      }
    },
    showTxtInp(newstrResult, oldstrResult){
      // ตรวจสอบข้อมูลเมื่อข้อมูลมีการเปลี่ยนแปลง
      if (newstrResult != oldstrResult) {
        this.inactive = newstrResult;
      }
    }
  },
  computed: {},
  methods: {
    isLeapYear(year) {
      return (
        (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) ||
        (year % 100 === 0 && year % 400 === 0)
      );
    },
    getFebDays(year) {
      return this.isLeapYear(year) ? 29 : 28;
    },
    clickInput() {
      this.blnDrop = !this.blnDrop;
      this.$emit("clickInput");
    },
    onClickOutSide() {
      const handleMouseDown = (e) => {
        let clickOutside = true;
        let el = e.target;
        while (el.parentElement) {
          if (el.id === "calenda") clickOutside = false;
          el = el.parentElement;
        }
        if (clickOutside == true) {
          this.blnDrop = false;
        }
      };
      document.addEventListener("mousedown", handleMouseDown);
    },
    convertData(event) {
      // console.log(event);
      if (typeof event == "string") {
        // console.log(event);
        this.data = event;
        // console.log(this.data);
        let splitData = this.data.split("/");
        let publicDD = splitData[0];
        let publicMM = splitData[1];
        let publicYYYY = splitData[2];
        let publicTime = splitData[3];
        let endDD = splitData[4];
        let endMM = splitData[5];
        let endYYYY = splitData[6];
        let endTime = splitData[7];
        if (this.showSeconTime == true && this.showFirstTime == true) {
          // let splitData = this.data.split("/");
          // let publicDD = splitData[0];
          // let publicMM = splitData[1];
          // let publicYYYY = splitData[2];
          // let publicTime = splitData[3];
          // let endDD = splitData[4];
          // let endMM = splitData[5];
          // let endYYYY = splitData[6];
          // let endTime = splitData[7];

          this.strValue =
            publicDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(publicMM)]
              : this.month_names_en[parseInt(publicMM)]) +
            " " +
            (this.$i18n.locale == "th"
              ? publicYYYY
              : parseInt(publicYYYY) - 543) +
            " " +
            publicTime +
            (this.$i18n.locale == "th" ? " น." : "") +
            " - " +
            endDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(endMM)]
              : this.month_names_en[parseInt(endMM)]) +
            " " +
            (this.$i18n.locale == "th" ? endYYYY : parseInt(endYYYY) - 543) +
            " " +
            endTime +
            (this.$i18n.locale == "th" ? " น." : "");
          // ---------------------------------------------------------------------------
          if (this.blnDrop == true) {
            let mount = parseInt(publicMM) + 1;
            this.startDate =
              publicDD +
              (mount.toString().length == 2
                ? mount.toString()
                : "0" + mount.toString()) +
              publicYYYY;
            this.startTime = publicTime;
            this.endDate = endDD + endMM + endYYYY;
            this.endTime = endTime;
          }
        } else if (this.showSeconTime == false && this.showFirstTime == true) {
          // console.log(event)

          this.strValue =
            publicDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(publicMM)]
              : this.month_names_en[parseInt(publicMM)]) +
            " " +
            (this.$i18n.locale == "th"
              ? publicYYYY
              : parseInt(publicYYYY) - 543) +
            " " +
            publicTime +
            (this.$i18n.locale == "th" ? " น." : "");
          // -------------------------------------------------------------------
          if (this.blnDrop == true) {
            let mount = parseInt(publicMM) + 1;
            this.startDate =
              publicDD +
              (mount.toString().length == 2
                ? mount.toString()
                : "0" + mount.toString()) +
              publicYYYY;
            this.startTime = publicTime;
          }
        } else if (this.showSeconTime == false && this.showFirstTime == false) {
          this.strValue =
            publicDD +
            " " +
            (this.$i18n.locale == "th"
              ? this.month_names_th[parseInt(publicMM)]
              : this.month_names_en[parseInt(publicMM)]) +
            " " +
            (this.$i18n.locale == "th"
              ? publicYYYY
              : parseInt(publicYYYY) - 543);
          // -------------------------------------------------------------------
          if (this.blnDrop == true) {
            let mount = parseInt(publicMM) + 1;
            this.startDate =
              publicDD +
              (mount.toString().length == 2
                ? mount.toString()
                : "0" + mount.toString()) +
              publicYYYY;
          }
          if (event == "") {
            this.strValue = "";
          }
          // console.log(this.strValue);
        }

        if (event == "") {
          this.strValue = "";
        }
      } else {
        this.strValue = "";
      }

      // if (this.blnDrop == true) {
      this.strResult = this.strValue;
      // console.log(this.strResult);
      this.$emit("input", event);
      this.$emit("startDate", this.startDate);
      this.$emit("startTime", this.startTime);
      this.$emit("endDate", this.endDate);
      this.$emit("endTime", this.endTime);
      // }
    },
    ValidNumber(value) {
      return isNaN(value);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        if (this.alertMsgStatus == true) {
          this.Valid();
        }
      }
    },
    deleteLetter() {
      this.alertMsgStatus = false;
      if (this.value != undefined) {
        if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
    },
    alertRequest() {
      // this.alertMsg, { fieldname: this.$t(this.label).toLowerCase() };
      if (this.value == null || this.value == "") {
        this.alertMsgInput = this.$t(this.alertMsg, {
          fieldname: this.$t(this.label).toLowerCase(),
        });
        this.alertMsgStatus = true;
        // console.log("alertMsgStatus :: " + this.alertMsgStatus);
        // console.log("alertMsgInput :: " + this.alertMsgInput);
      } else {
        this.Valid();
      }
    },
    alertDataDuplicate(msg) {
      this.alertMsgInput = this.$t(msg, {
        fieldname: this.$t(this.label).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    Valid() {
      // console.log("valid");
      if (this.strResult != undefined) {
        this.alertMsgStatus = false;
        let splitData = this.data.split("/");
        let publicDD = splitData[0];
        let publicMM = splitData[1];
        let publicYYYY = splitData[2];
        let publicTime = splitData[3];
        let endDD = splitData[4];
        let endMM = splitData[5];
        let endYYYY = splitData[6];
        let endTime = splitData[7];
        let cuurentDate = new Date();
        // en
        // console.log(this.strResult.slice(12, 14));
        // console.log(this.strResult.slice(15, 17));
        // th
        // console.log(this.strResult.slice(14, 16));
        // console.log(this.strResult.slice(17, 19));
        if (this.blncheckPastTime == true) {
          if (this.blnShowAlertInp == false) {
            if (
              parseInt(publicDD) == cuurentDate.getDate() &&
              parseInt(publicMM) == cuurentDate.getMonth() &&
              parseInt(publicYYYY) - 543 == cuurentDate.getFullYear()
            ) {
              if (parseInt(publicTime.split(":")[0]) < cuurentDate.getHours()) {
                this.alertMsgStatus = true;
                this.alertMsgInput = "647";
              } else {
                this.alertMsgStatus = false;
                if (
                  parseInt(publicTime.split(":")[0]) == cuurentDate.getHours()
                ) {
                  if (
                    parseInt(publicTime.split(":")[1]) <
                    cuurentDate.getMinutes()
                  ) {
                    this.alertMsgStatus = true;
                    this.alertMsgInput = "647";
                  }
                }
              }
            }
          }

          if (
            endDD == cuurentDate.getDate() &&
            endMM == cuurentDate.getMonth() &&
            endYYYY - 543 == cuurentDate.getFullYear()
          ) {
            if (parseInt(endTime.split(":")[0]) < cuurentDate.getHours()) {
              this.alertMsgStatus = true;
              this.alertMsgInput = "647";
            } else {
              if (parseInt(endTime.split(":")[0]) == cuurentDate.getHours()) {
                if (
                  parseInt(endTime.split(":")[1]) < cuurentDate.getMinutes()
                ) {
                  this.alertMsgStatus = true;
                  this.alertMsgInput = "647";
                }
              }
            }
          }

          if (publicDD == endDD && publicMM == endMM && publicYYYY == endYYYY) {
            if (
              parseInt(publicTime.split(":")[0]) >
              parseInt(endTime.split(":")[0])
            ) {
              this.alertMsgStatus = true;
              this.alertMsgInput = "647";
            } else {
              if (
                parseInt(publicTime.split(":")[0]) ==
                parseInt(endTime.split(":")[0])
              ) {
                this.alertMsgStatus = false;
                if (
                  parseInt(publicTime.split(":")[1]) >
                  parseInt(endTime.split(":")[1])
                ) {
                  this.alertMsgStatus = true;
                  this.alertMsgInput = "647";
                }
              }
            }
          }
        }
      }

      // this.alertMsgStatus = false;
      this.alertLength = false;
      if (this.value === "" || this.value === undefined) {
        return;
      } else {
        if (this.validate == "email") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          // console.log(this.alertMsgStatus);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "57";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "number") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "56";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "webUrl") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "59";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "facebookpage") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "58";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "letterTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "letterEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "InitialTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "InitialEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "addressTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "currency") {
          this.alertMsgStatus = false;
          this.$refs["input"].value = Intl.NumberFormat("en-US").format(
            this.value.replaceAll(",", "")
          );
          return Validation[this.validate](this.value);
        } else {
          if (this.validate !== "") {
            this.alertMsgStatus = !Validation[this.validate](this.value);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = this.$t("202", {
                fieldname: this.$t(this.label).toLowerCase(),
              });
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](this.value);
            }
          }
        }
      }
    },
    isLetter(e) {
      // console.log(e);
      this.alertMsgStatus = false;
      if (this.value != undefined && this.maxlength != undefined) {
        if (this.value.length + 1 > this.maxlength) {
          // this.alertLength = true;
          // console.log("เกิน");
          // this.alertLengthMsg = "427";
          // setTimeout(() => {
          //   this.alertLength = false;
          // }, 2000);
        } else if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
      if (this.validate != null) {
        let char = String.fromCharCode(e.keyCode);
        if (this.validate == "letterEN" || this.validate == "letterTH") {
          if (this.validate == "letterEN") {
            this.alertMsgStatus = !Validation[this.validate](char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "46";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](char);
            }
          } else if (this.validate == "letterTH") {
            if (/^[฿]+$/.test(char) == false) {
              this.alertMsgStatus = !/[ก-๏0-9- .]+$/.test(char);
              ///^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?ก-๏0-9-]+$/
              ///^[ก-๏0-9!@#\$%\^\&*\)\(+=._-]+$/g
              if (this.alertMsgStatus == true) {
                this.alertMsgInput = "45";
                e.preventDefault();
                return true;
              } else if (this.alertMsgStatus == false) {
                return Validation[this.validate](char);
              }
            } else if (/^[฿]+$/.test(char) == true) {
              this.alertMsgStatus = true;
              this.alertMsgInput = "45";
              e.preventDefault();
              return true;
            }
          }
        } else if (this.validate == "InitialEN") {
          if (/^[A-Za-z-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "231";
            e.preventDefault();
          }
        } else if (this.validate == "InitialTH") {
          if (/^[ก-๏-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "232";
            e.preventDefault();
          }
        } else if (this.validate == "addressTH") {
          if (/^[ก-๏0-9- /.-]+$/.test(char) && /^[฿]+$/.test(char) == false) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "45";
            e.preventDefault();
          }
        } else if (this.validate == "addressEN") {
          if (/^[a-zA-Z0-9-/ .-]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "46";
            e.preventDefault();
          }
        } else if (this.validate == "number") {
          if (/^[0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "currency") {
          if (/^[0-9.]+$/.test(char)) {
            this.alertMsgStatus = false;
            if (this.value.length > 0) {
              if (
                /^(0|[1-9][0-9]{0,2})*(\.)*(\d{1,1})?$/.test(
                  this.value.replaceAll(",", "")
                )
              ) {
                this.alertMsgStatus = false;
                return false;
              } else {
                this.alertMsgInput = "443";
                this.alertMsgStatus = true;
                e.preventDefault();
                return true;
              }
            }
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (
          this.validate !== "" &&
          this.validate !== "webUrl" &&
          this.validate !== "email" &&
          this.validate !== "facebookpage"
        ) {
          this.alertMsgStatus = !Validation[this.validate](char);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = this.$t("202", {
              fieldname: this.$t(this.label).toLowerCase(),
            });
            e.preventDefault();
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](char);
          }
        }
      }
    },
    UpdateData() {
      // console.log("Update data Input");
      if (this.$refs.calendar != undefined) {
        this.$refs.calendar.UpdateData();
      }
    },
    removeData() {
      // console.log("removeCalendar");
      this.blnDrop = false;
      this.strResult = "";
      this.$emit("input", "");
      setTimeout(() => {
        this.blnDrop = false;
      }, 100);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 1;
}

.validate {
  p {
    color: $danger !important;
  }
}

.move {
  top: -4% !important;
  left: 8px !important;
  // color: $primary;
  transition: all 0.2s !important;
  background-color: #fff !important;
  font-size: 14px !important;

  &:hover {
    color: $primary;
  }
}
.move-mobile {
  top: -4% !important;
  left: 8px !important;
  // color: $primary;
  transition: all 0.2s !important;
  background-color: #fff;
  font-size: 12px !important;

  &:hover {
    color: $primary;
  }
}

.disable-inp {
  input {
    padding: 10px 16px !important;

    &:hover {
      border: solid 1px $black-400;
    }
  }

  .label-text {
    // top: -6% !important;
    background-color: $black-200 !important;
    color: $black-400 !important;
    font-size: 14px !important;
  }
  .move {
    top: -6% !important;
    background-color: $black-200 !important;
    color: $black-400 !important;
    font-size: 14px !important;
  }
  .move-mobile {
    top: -4% !important;
    left: 8px !important;
    // color: $primary;
    transition: all 0.2s !important;
    background-color: #eeeeee !important;
    font-size: 12px !important;

    &:hover {
      color: $primary;
    }
  }

  input:disabled {
    background-color: $black-200 !important;
    color: $black;
    border: solid 1px $black-400 !important;
  }
}

.txt-inp {
  position: relative;
  width: 100%;
  cursor: pointer;

  &:hover {
    .icon {
      fill: $primary-600;
    }
    .Remove-icon {
      position: absolute;
      right: -13px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
    }
  }
  .Remove-icon {
    position: absolute;
    opacity: 0;
  }

  .icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  p {
    position: absolute;
    left: 45px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    // background: none;
    color: $black-500;
    cursor: pointer;
    display: flex;
    user-select: none;
  }

  input {
    border: none;
    outline: 0;
    font-size: 16px;
    // padding: 10px 45px !important;
    padding: 11px 11px 11px 42px !important;
    border: solid 1px $black-500;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    padding-top: 4px;

    &::placeholder {
      color: $white;
    }

    &:hover {
      border: solid 1px $primary;
      cursor: pointer !important;

      & + p {
        color: $primary;
      }
    }

    &:focus {
      border: solid 1px $primary;

      & + p {
        top: -4%;
        left: 8px;
        // color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;

        .sub {
          display: none !important;
        }

        .require {
          display: flex !important;
        }
      }

      &::placeholder {
        color: $black-500 !important;
      }
    }

    &:valid + p {
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -4%;
      left: 8px;

      .sub {
        display: none !important;
      }

      .require {
        display: flex !important;
      }
    }

    &:valid {
      &:hover + p {
        color: $primary !important;
      }
    }
  }
}
</style>
  
<template>
  <div class="bg-white shadow rounded py-2 px-3" id="calenda">
    <div class="d-flex justify-content-center">
      <div class="calendar border-bottom w-100 px-2 pb-2" ref="calendar">
        <div class="calendar-header d-flex flex-column align-items-center">
          <div
            class="year-picker fnt-body d-flex justify-content-between w-100"
          >
            <span
              class="year-change m-0"
              id="prev-year"
              @click="prevYear()"
              ref="year-change"
            >
              <!-- <pre class="m-0 text-black-400">&#10094;</pre> -->
              <moveLeft width="12" height="12" class="fill-black-400" />
            </span>
            <!-- <span id="year">{{ calendar_header_year }}</span> -->
            <div
              class="fnt-regular fnt-body"
              :class="blncurrent_year == true ? 'text-primary' : ''"
              id="all-year"
            >
              <span>{{ this.$i18n.locale == "th" ? "พ.ศ. " : "" }}</span>
              <!-- <span id="year">2565</span> -->
              <span id="year">
                {{
                  this.$i18n.locale == "th"
                    ? calendar_header_year
                    : calendar_header_year - 543
                }}
              </span>
            </div>
            <span class="year-change m-0" id="next-year" @click="nextYear()">
              <!-- <pre class="m-0 text-black-400">&#10095;</pre> -->
              <moveRight width="12" height="12" class="fill-black-400" />
            </span>
          </div>
          <div
            class="fnt-body d-flex justify-content-between align-items-center w-100"
          >
            <span
              class="year-change m-0 d-flex"
              id="first-mount"
              @click="firstMount()"
            >
              <!-- <pre class="m-0 text-black-400">&#10094;</pre> -->
              <moveLeft width="12" height="12" class="fill-black-400" />
              <moveLeft width="12" height="12" class="fill-black-400" />
            </span>
            <span class="year-change m-0" id="prev-mount" @click="prevMount()">
              <!-- <pre class="m-0 text-black-400">&#10094;</pre> -->
              <moveLeft width="12" height="12" class="fill-black-400" />
            </span>
            <span
              class="month-picker"
              :class="blncurrent_mouth == true ? 'text-primary' : 'fnt-regular'"
              id="month-picker"
              ref="month_picker"
            >
              {{ $t(month_value) }}
            </span>
            <span class="year-change m-0" id="next-mount" @click="nextMount()">
              <!-- <pre class="m-0 text-black-400">&#10095;</pre> -->
              <moveRight width="12" height="12" class="fill-black-400" />
            </span>
            <span
              class="year-change m-0 d-flex justify-content-end"
              id="last-mount"
              @click="lastMount()"
            >
              <!-- <pre class="m-0 text-black-400">&#10095;</pre> -->
              <moveRight width="12" height="12" class="fill-black-400" />
              <moveRight width="12" height="12" class="fill-black-400" />
            </span>
          </div>
        </div>
        <div class="calendar-body">
          <div class="calendar-week-day fnt-body fnt-medium">
            <div>{{ this.$i18n.locale == "th" ? "อา." : "Su" }}</div>
            <div>{{ this.$i18n.locale == "th" ? "จ." : "Mo" }}</div>
            <div>{{ this.$i18n.locale == "th" ? "อ." : "Tu" }}</div>
            <div>{{ this.$i18n.locale == "th" ? "พ." : "We" }}</div>
            <div>{{ this.$i18n.locale == "th" ? "พฤ." : "Th" }}</div>
            <div>{{ this.$i18n.locale == "th" ? "ศ." : "Fr" }}</div>
            <div>{{ this.$i18n.locale == "th" ? "ส." : "Sa" }}</div>
          </div>
          <div class="calendar-days" ref="test"></div>
          <div
            class="grid-container opacity-0"
            @click="
              () => {
                $emit('blnDrop', false);
              }
            "
          >
            <div
              v-for="(e, index) in days_of_month[curr_month.value]"
              :key="index"
              :id="e"
              class="calendar-day-hover text-center my-1 calenad-id-31"
              style="width: 24px; height: 24px"
              :ref="'calendartest_' + e"
              @click="addline($event)"
            >
              {{ e }}
            </div>
          </div>
        </div>

        <div class="month-list bg-white p-2 row" ref="month_list">
          <div
            class="d-flex justify-content-center align-items-center rounded col-4 p-0 month"
            v-for="(e, index) in month_names_th"
            :key="index"
            @click="selectMonth(index)"
          >
            <div class="w-100 text-center" data-month="{{index}}">
              {{ $t(e) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <!-- เวลาเริ่มเผยแพร่ -->
      <div class="d-flex mt-3 ms-2 row" v-if="showFirstTime == true">
        <div class="fnt-h6 col-1 p-0">{{ publicDD }}</div>
        <div class="mx-2 col-4 p-0">
          <div class="">
            <span class="me-1">
              <!-- {{ 'publicMM:'+publicMM }} -->
              <!-- {{ "---"+month_names_th }} -->
              <!-- {{ month_names_th[publicMM] }} -->
              {{
                $t(
                  this.$i18n.locale == "th"
                    ? month_names_th[publicMM]
                    : month_names_en[publicMM]
                )
              }}
            </span>
            <span>
              {{ this.$i18n.locale == "th" ? publicYYYY : publicYYYY - 543 }}
            </span>
          </div>

          <div>
            {{
              this.$i18n.locale == "th"
                ? day_name_th[publicDays]
                : day_name_en[publicDays]
            }}
          </div>
        </div>
        <div class="w-100 col">
          <inputtimekey
            class=""
            :label="labelFirstTime"
            @strValue="
              (e) => {
                intPublicTime = e;
                ValueCalenda();
              }
            "
            :ValueHours="
              intPublicTime == undefined
                ? Value == undefined || Value == ''
                  ? ''
                  : Value.length == 33
                  ? Value.split('/')[3].split(':')[0]
                  : ''
                : intPublicTime.split(':')[0]
            "
            :ValueMinutesl="
              intPublicTime == undefined
                ? Value == undefined || Value == ''
                  ? ''
                  : Value.length == 33
                  ? Value.split('/')[3].split(':')[1]
                  : ''
                : intPublicTime.split(':')[1]
            "
            :blnDrop="blnDrop"
            :AddHours="1"
            :AddMinut="0"
          />
        </div>
      </div>
      <!-- เวลาสิ้นสุดเผยแพร่ -->
      <div class="d-flex mt-3 ms-2 row" v-if="showSeconTime == true">
        <div class="fnt-h6 col-1 p-0">{{ endDD }}</div>
        <div class="mx-2 col-4 p-0">
          <div class="">
            <span class="me-1">
              {{
                $t(
                  this.$i18n.locale == "th"
                    ? month_names_th[endMM]
                    : month_names_en[endMM]
                )
              }}
            </span>
            <span>
              {{ this.$i18n.locale == "th" ? endYYYY : endYYYY - 543 }}
            </span>
          </div>

          <div>
            {{
              this.$i18n.locale == "th"
                ? day_name_th[endDays]
                : day_name_en[endDays]
            }}
          </div>
        </div>
        <div class="w-100 col">
          <inputtimekey
            class=""
            :label="labelSecconTime"
            @strValue="
              (e) => {
                intEndTime = e;
                ValueCalenda();
              }
            "
            :ValueHours="
              intEndTime == undefined
                ? Value == undefined
                  ? ''
                  : Value.length == 33
                  ? Value.split('/')[7].split(':')[0]
                  : ''
                : intEndTime.split(':')[0]
            "
            :ValueMinutesl="
              intEndTime == undefined
                ? Value == undefined
                  ? ''
                  : Value.length == 33
                  ? Value.split('/')[7].split(':')[1]
                  : ''
                : intEndTime.split(':')[1]
            "
            :blnDrop="blnDrop"
            :AddHours="0"
            :AddMinut="0"
            ref="endTime"
          />
        </div>
      </div>
    </div>
    <!-- <span class="d-none">{{ ValueCalenda() }}</span> -->
    <!-- <span class="d-none">{{ ValidRepeat() }}</span> -->
    <!-- <div class="grid-container">
      <div
        v-for="(e, index) in days_of_month[curr_month.value]"
        :key="index"
        :id="index + 1"
        class="calendar-day-hover text-center my-1 calenad-id-31"
        style="width: 24px; height: 24px"
        @click="addline($event)"
        :ref="'calendartest_id_' + (index + 1)"
      >
        {{ e }}
      </div>
    </div> -->
    <div
      v-if="
        (blnShowAlertInp == true && blnshowRepeat == true) ||
        blnshowRepeat == true
      "
      class="d-flex alertInput align-items-center p-0 mt-2 ms-2 fnt-body fnt-regular"
    >
      <CloseFill class="me-1" width="12" />
      {{ $t(alertMsgInput) }}
    </div>
  </div>
</template>
  
<script>
// import { Icon } from "@iconify/vue";
import { moveRight, moveLeft, CloseFill } from "@/components/icon/index";
// import inputTime from "@/components/input/inputTimePicker.vue";
import inputtimekey from "@/components/input/inputTimeKey.vue";
export default {
  components: {
    moveRight,
    moveLeft,
    // inputTime,
    CloseFill,
    // Icon,
    inputtimekey,
  },
  props: {
    Value: { type: String },
    Day: { type: String },
    Mount: { type: String },
    Year: { type: String },
    showFirstTime: { type: Boolean, default: true },
    showSeconTime: { type: Boolean, default: true },
    blnDrop: { type: Boolean },
    blncheckPastDate: { type: Boolean, default: false },
    blnShowAlertInp: { type: Boolean, default: false },
    labelFirstTime: { type: String, default: "688" },
    labelSecconTime: { type: String, default: "689" },
    firstDisable: { type: String },
    secondDisable: { type: String },
    listDataRepeat: { type: Array },
    numberYearPrev: { type: Number },
  },
  data() {
    return {
      alertMsgInput: "646",
      message: "Hello Vue!",
      calendar: null,
      month_names_th: [
        "605", // "มกราคม",
        "606", // "กุมภาพันธ์",
        "607", // "มีนาคม",
        "608", // "เมษายน",
        "609", // "พฤษภาคม",
        "610", // "มิถุนายน",
        "611", // "กรกฎาคม",
        "612", // "สิงหาคม",
        "613", // "กันยายน",
        "614", // "ตุลาคม",
        "615", // "พฤศจิกายน",
        "616", // "ธันวาคม",
      ],
      month_names_en: [
        "605", // "มกราคม",
        "606", // "กุมภาพันธ์",
        "607", // "มีนาคม",
        "608", // "เมษายน",
        "609", // "พฤษภาคม",
        "610", // "มิถุนายน",
        "611", // "กรกฎาคม",
        "612", // "สิงหาคม",
        "613", // "กันยายน",
        "614", // "ตุลาคม",
        "615", // "พฤศจิกายน",
        "616", // "ธันวาคม",
      ],
      day_name_th: [
        "วันอาทิตย์",
        "วันจันทร์",
        "วันอังคาร",
        "วันพุธ",
        "วันพฤหัสบดี",
        "วันศุกร์",
        "วันเสาร์",
      ],
      day_name_en: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday ",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      month_picker: null,
      month_list: null,
      month_value: "",

      // day_Value: null,
      // mount_Value: null,
      // year_Value: null,

      calendar_header_year: "",
      countClick: 0,
      blncurrent_year: false,
      blncurrent_mouth: false,
      all_day: [],
      Dummyall_day: [],
      publicDay: "",
      endDay: "",

      // วันเผยแพร่
      publicDD: "",
      publicMM: 0,
      publicYYYY: "",
      publicDays: "",
      // วันสิ้นสุด
      endDD: "",
      endMM: 0,
      endYYYY: "",
      endDays: "",

      dummyendDD: "",
      dummypublicDD: "",

      firstClick: null,
      seconClick: null,

      bgDiv: [],
      // emit
      intPublicTime: null,
      intEndTime: null,

      // phoneNumber: "",
      curr_month: "",
      curr_year: "",
      days_of_month: "",

      endValueHours: null,
      endValueMinutesl: null,
      blnshowRepeat: false,
      valueCalenda: "",
      blnUpdateData: false,
    };
  },
  created() {},
  watch: {
    blnDrop(newblnDrop) {
      // เปิดมาครั้งแรกจะใส่ค่าวันปัจจุบันให้ auto
      // console.log(newblnDrop);
      // console.log(this.Value);
      if (
        newblnDrop == true &&
        (this.Value == undefined || this.Value == null)
      ) {
        this.publicDD = "";
        this.publicMM = "";
        this.publicYYYY = "";
        // console.log("curr_year:" + this.curr_year.value);
        // console.log("etFullYear:" + new Date().getFullYear());
        // console.log("calendar_header:" + this.calendar_header_year);

        if (
          // this.curr_year.value == new Date().getFullYear() &&
          // this.curr_year.value == this.calendar_header_year - 543 &&
          this.calendar_header_year - 543 == new Date().getFullYear() &&
          this.numberYearPrev != undefined &&
          this.numberYearPrev != null &&
          this.numberYearPrev != ""
        ) {
          for (let i = 0; i < this.numberYearPrev; i++) {
            // setTimeout(() => {
            this.prevYear();
            // }, 200);
          }
        }
        if (
          this.numberYearPrev == undefined &&
          this.numberYearPrev == null &&
          this.numberYearPrev == ""
        ) {
          this.onMount_list();
        }
      } else if (
        newblnDrop == true &&
        (this.Value != undefined || this.Value != null || this.Value != "")
      ) {
        this.onMount_list();
      }
    },
    Value(newstrResult, oldstrResult) {
      // console.log(newstrResult);
      // console.log(oldstrResult);
      // ตรวจสอบข้อมูลเมื่อข้อมูลมีการเปลี่ยนแปลง
      if (newstrResult != oldstrResult && this.blnUpdateData == true) {
        this.UpdateData();
        this.blnUpdateData = false;
      }
    },
  },
  mounted() {
    let currentHours = new Date().getHours();
    let currentMinutes = new Date().getMinutes();
    let currDate = new Date();
    this.curr_month = { value: currDate.getMonth() };
    this.curr_year = { value: currDate.getFullYear() };
    setTimeout(() => {
      if (this.Value == undefined || this.Value == "" || this.Value == null) {
        this.intPublicTime =
          (currentHours + 1 > 23
            ? "00" +
              ":" +
              (currentMinutes.toString().length < 2
                ? "0" + currentMinutes.toString()
                : currentMinutes)
            : (currentHours + 1).toString().length < 2
            ? "0" + (currentHours + 1).toString()
            : currentHours + 1) +
          ":" +
          (currentMinutes.toString().length < 2
            ? "0" + currentMinutes.toString()
            : currentMinutes);
        this.intEndTime =
          (currentHours > 23
            ? "00" +
              ":" +
              (currentMinutes.toString().length < 2
                ? "0" + currentMinutes.toString()
                : currentMinutes)
            : currentHours.toString().length < 2
            ? "0" + currentHours.toString()
            : currentHours) +
          ":" +
          (currentMinutes.toString().length < 2
            ? "0" + currentMinutes.toString()
            : currentMinutes);
      } else {
        if (this.showFirstTime == true && this.showSeconTime == true) {
          if (this.Value.length == 33) {
            let data = this.Value.split("/");
            // console.log(data);
            this.intPublicTime = data[3];
            this.intEndTime = data[7];
          } else {
            let data = this.Value.split("-");
            let startHours = data[0].split(" ")[3].split(":")[0];
            let startMinu = data[0].split(" ")[3].split(":")[1];
            let endHours = data[1].split(" ")[4].split(":")[0];
            let endMinu = data[1].split(" ")[4].split(":")[1];
            this.intPublicTime = startHours + ":" + startMinu;
            this.intEndTime = endHours + ":" + endMinu;
          }
        } else if (this.showFirstTime == true && this.showSeconTime == false) {
          if (this.Value != undefined || this.Value != null) {
            if (typeof this.Value == "string") {
              if (this.Value.length == 33) {
                let data = this.Value.split("/");
                this.intPublicTime = data[3];
              } else {
                let data = this.Value.split(" ");
                this.intPublicTime = data[3];
              }
            }
          }
        }
      }
      setTimeout(() => {
        this.onMount_list();
      }, 100);
    }, 2000);
  },
  beforeUnmount() {},
  methods: {
    selectMonth(index) {
      this.month_list.classList.remove("show");
      this.curr_month.value = index;
      if (
        this.Value == undefined ||
        this.Value == "" ||
        this.Value == null ||
        this.Value.length < 10 ||
        this.Value.split("-")[2] == "ddT:"
      ) {
        this.generateCalendar(index, this.curr_year.value);
      } else {
        let Year;
        isNaN(this.Year) == true
          ? (Year = this.curr_year.value)
          : (Year = this.Year);
        this.generateCalendar(index, Year);
      }
    },
    prevYear() {
      --this.curr_year.value;
      this.generateCalendar(this.curr_month.value, this.curr_year.value);
    },
    nextYear() {
      ++this.curr_year.value;
      this.generateCalendar(this.curr_month.value, this.curr_year.value);
    },
    prevMount() {
      if (this.curr_month.value > 0) {
        --this.curr_month.value;
        this.generateCalendar(this.curr_month.value, this.curr_year.value);
      }
    },
    nextMount() {
      if (this.curr_month.value < 11) {
        ++this.curr_month.value;
        this.generateCalendar(this.curr_month.value, this.curr_year.value);
      }
    },
    lastMount() {
      this.curr_month.value = 11;
      this.generateCalendar(this.curr_month.value, this.curr_year.value);
    },
    firstMount() {
      this.curr_month.value = 0;
      this.generateCalendar(this.curr_month.value, this.curr_year.value);
    },
    onMount_list() {
      // this.calendar = document.querySelector(".calendar");
      this.calendar = this.$refs.calendar;
      // this.month_list = this.calendar.querySelector(".month-list");
      this.month_list = this.$refs.month_list;

      // this.month_picker = this.calendar.querySelector("#month-picker");
      this.month_picker = this.$refs.month_picker;
      if (this.month_picker != undefined) {
        this.month_picker.onclick = () => {
          this.month_list.classList.add("show");
        };
      }
      if (typeof this.firstDisable == "string" && this.firstDisable != "") {
        let firstDisable = this.firstDisable.split("/");
        let mm = parseInt(firstDisable[1]);
        let yy = parseInt(firstDisable[2]) - 543;
        this.curr_month = { value: parseInt(mm) };
        this.curr_year = { value: parseInt(yy) };
      } else {
        let currDate = new Date();

        this.curr_month = { value: currDate.getMonth() };
        this.curr_year = { value: currDate.getFullYear() };
      }
      if (this.Value == undefined || this.Value == "" || this.Value == null) {
        this.generateCalendar(this.curr_month.value, this.curr_year.value);
      } else if (this.Value != undefined || this.Value != null) {
        // console.log(this.Value);
        // if(this.Value.length == 33 ||this.Value.length == 16||this.Value.length == 10){
        //   //
        // }
        let data = this.Value.split("/");
        this.curr_month = { value: parseInt(data[1]) };
        this.curr_year = { value: parseInt(data[2]) - 543 };
        this.generateCalendar(this.curr_month.value, this.curr_year.value);
      }
    },
    isLeapYear(year) {
      return (
        (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) ||
        (year % 100 === 0 && year % 400 === 0)
      );
    },
    getFebDays(year) {
      return this.isLeapYear(year) ? 29 : 28;
    },
    generateCalendar(month, year) {
      if (this.calendar == undefined) {
        return;
      }
      let calendar_days = this.calendar.querySelector(".calendar-days");
      // let calendar_header_year = this.calendar.querySelector("#year");

      this.days_of_month = [
        31,
        this.getFebDays(year),
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ];

      calendar_days.innerHTML = "";

      let currDate = new Date();
      if (month > 11 || month < 0) month = currDate.getMonth();
      if (!year) year = currDate.getFullYear();

      let curr_month_en = `${this.month_names_en[month]}`;
      let curr_month_th = `${this.month_names_th[month]}`;

      this.month_value =
        this.$i18n.locale == "th" ? curr_month_th : curr_month_en;
      this.calendar_header_year = year + 543;

      //check cerrent year
      this.blncurrent_year = false;
      this.blncurrent_mouth = false;
      if (this.calendar_header_year == new Date().getFullYear() + 543) {
        this.blncurrent_year = true;

        //check cerrent mouth
        if (month == new Date().getMonth()) {
          this.blncurrent_mouth = true;
        }
      }

      // get first day of month
      let first_day = new Date(year, month, 1);
      this.all_day = [];
      this.Dummyall_day = [];
      for (
        let i = 0;
        i <= this.days_of_month[month] + first_day.getDay() - 1;
        i++
      ) {
        // console.log("สร้าง Div");
        let day = document.createElement("div");
        if (i >= first_day.getDay()) {
          // day.innerHTML = `<div ref="${"calenad_id_" + day.id}">${i - first_day.getDay() + 1}</div>`;
          day.classList.add("calendar-day-hover");
          day.classList.add("text-center");
          day.classList.add("my-1");
          day.style.cssText = "width: 24px;height: 24px;";
          day.innerHTML = i - first_day.getDay() + 1;
          day.id = i - first_day.getDay() + 1;
          day.classList.add("calenad-id-" + day.id);

          this.all_day.push(day);

          // if (
          //   this.Value == undefined ||
          //   this.Value == "" ||
          //   this.Value == null
          // ) {
          //วันก่อนหน้าปัจจุบัน (อดีต)
          if (
            i - first_day.getDay() + 1 < currDate.getDate() &&
            year === currDate.getFullYear() &&
            month === currDate.getMonth()
          ) {
            if (this.blncheckPastDate == true) {
              day.style.cssText =
                "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
            }
          }
          if (year < currDate.getFullYear()) {
            if (this.blncheckPastDate == true) {
              day.style.cssText =
                "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
            }
          } else if (month < currDate.getMonth()) {
            if (this.blncheckPastDate == true) {
              day.style.cssText =
                "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
            }
          }
          // disable วันตามค่าที่ส่งมา
          if (typeof this.firstDisable == "string") {
            let firstDisable = this.firstDisable.split("/");
            let dd = parseInt(firstDisable[0]);
            let mm = parseInt(firstDisable[1]);
            let yy = parseInt(firstDisable[2]) - 543;
            if (year < parseInt(yy)) {
              day.style.cssText =
                "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
            }
            // console.log(dd);
            // console.log(i - first_day.getDay() + 1);
            if (year === parseInt(yy)) {
              if (month < parseInt(mm)) {
                day.style.cssText =
                  "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
              }
              if (month === parseInt(mm)) {
                if (i - first_day.getDay() + 1 < parseInt(dd)) {
                  day.style.cssText =
                    "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
                }
              }
            }
          }
          if (typeof this.secondDisable == "string") {
            // console.log(this.secondDisable);
            let secondDisable = this.secondDisable.split("/");
            let dd = parseInt(secondDisable[0]);
            let mm = parseInt(secondDisable[1]);
            let yy = parseInt(secondDisable[2]) - 543;
            if (year > parseInt(yy)) {
              day.style.cssText =
                "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
            }
            if (year === parseInt(yy)) {
              if (month > parseInt(mm)) {
                day.style.cssText =
                  "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
              }
              if (month === parseInt(mm)) {
                if (i - first_day.getDay() + 1 > parseInt(dd)) {
                  day.style.cssText =
                    "pointer-events: none; width: 24px; height: 24px; color: #CACACA;";
                }
              }
            }
          }

          if (typeof this.firstDisable == "string") {
            let firstDisable = this.firstDisable.split("/");
            let dd = parseInt(firstDisable[0]);
            let mm = parseInt(firstDisable[1]);
            let yy = parseInt(firstDisable[2]) - 543;
            if (
              i - first_day.getDay() + 1 === parseInt(dd) &&
              year === parseInt(yy) &&
              month === parseInt(mm)
            ) {
              if (this.countClick == 0) {
                day.classList.add("rounded-circle");
                day.classList.add("bg-primary-600");
                day.classList.add("text-white");
                this.firstClick = day.id;
              }
            }
          } else {
            setTimeout(() => {
              // วันที่ปัจจุบัน
              if (this.Value == undefined || this.Value == "") {
                if (
                  i - first_day.getDay() + 1 === currDate.getDate() &&
                  year === currDate.getFullYear() &&
                  month === currDate.getMonth()
                ) {
                  if (this.countClick == 0) {
                    day.classList.add("rounded-circle");
                    day.classList.add("bg-primary-600");
                    day.classList.add("text-white");
                    // day.innerHTML=(i - first_day.getDay() + 1)+`<div"></div>`
                    this.firstClick = day.id;
                  }
                }
              } else {
                if (typeof this.Value == "string") {
                  let data = this.Value.split(" ");
                  if (
                    i - first_day.getDay() + 1 === parseInt(data[0]) &&
                    year === parseInt(data[2]) - 543 &&
                    month === this.convertMonth(data[1])
                  ) {
                    if (this.countClick == 0) {
                      day.classList.add("rounded-circle");
                      day.classList.add("bg-primary-600");
                      day.classList.add("text-white");
                      // day.innerHTML=(i - first_day.getDay() + 1)+`<div"></div>`
                      this.firstClick = day.id;
                    }
                  }
                }
              }
            }, 1000);
          }

          // วันปัจจุบัน
          if (
            i - first_day.getDay() + 1 === currDate.getDate() &&
            year === currDate.getFullYear() &&
            month === currDate.getMonth()
          ) {
            if (this.showFirstTime == true) {
              if (this.countClick == 0) {
                setTimeout(() => {
                  if (this.Value == undefined || this.Value == "") {
                    day.classList.add("rounded-circle");
                    day.classList.add("bg-primary-600");
                    day.classList.add("text-white");
                    // day.innerHTML=(i - first_day.getDay() + 1)+`<div"></div>`
                    this.firstClick = day.id;
                  }
                }, 1000);
              }
            }
          }
          // วันถัดไปจากปัจจุบัน
          if (
            i - first_day.getDay() === currDate.getDate() &&
            year === currDate.getFullYear() &&
            month === currDate.getMonth()
          ) {
            if (this.showSeconTime == true) {
              if (this.countClick == 0) {
                setTimeout(() => {
                  if (this.Value == undefined) {
                    day.classList.add("rounded-circle");
                    day.classList.add("bg-primary-600");
                    day.classList.add("text-white");
                    this.seconClick = day.id;
                    if (this.firstClick == null) {
                      this.firstClick = day.id - 1;
                    }
                    // createLine
                    this.Dummyall_day = this.all_day.slice(
                      this.firstClick - 1,
                      this.seconClick
                    );
                    this.bgDiv = [];
                    for (
                      let index = 0;
                      index < this.Dummyall_day.length;
                      index++
                    ) {
                      let bgDiv = document.createElement("div");
                      this.Dummyall_day[index].appendChild(bgDiv);
                      bgDiv.classList.add("bg-caledar-div");
                      bgDiv.classList.add("bg-primary-100");
                      if (this.firstClick != this.seconClick) {
                        // first
                        if (index == 0) {
                          bgDiv.style.cssText =
                            "width: 34px;height: 24px;position: relative;top: -24px;left: 10px;z-index: -1;";
                        }
                        //ระหว่าง
                        if (
                          index != 0 &&
                          index != this.Dummyall_day.length - 1
                        ) {
                          bgDiv.style.cssText =
                            "width: 74px;height: 24px;position: relative;top: -24px;left: -30px;z-index: -1;";
                        }
                        // last
                        if (index == this.Dummyall_day.length - 1) {
                          bgDiv.style.cssText =
                            "width: 24px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1;";
                        }
                      }

                      this.bgDiv.push(bgDiv);
                    }
                  }
                }, 1000);
              }
            }
          }

          setTimeout(() => {
            // ใส่ css ใส่เส้น ตามค่าที่รับมา
            if (this.Value != undefined) {
              if (this.showFirstTime == true && this.showSeconTime == true) {
                if (this.Value.length == 33) {
                  if (this.countClick == 0) {
                    let data = this.Value.split("/");
                    // วันเริ่มเผยแพร่
                    if (
                      i - first_day.getDay() + 1 === parseInt(data[0]) &&
                      year + 543 === parseInt(data[2]) &&
                      month === parseInt(data[1])
                    ) {
                      day.classList.add("rounded-circle");
                      day.classList.add("bg-primary-600");
                      day.classList.add("text-white");
                      this.firstClick = day.id;
                    }
                    // วันสิ้นสุด
                    if (
                      i - first_day.getDay() + 1 === parseInt(data[4]) &&
                      year + 543 === parseInt(data[6]) &&
                      month === parseInt(data[5])
                    ) {
                      day.classList.add("rounded-circle");
                      day.classList.add("bg-primary-600");
                      day.classList.add("text-white");
                      this.seconClick = day.id;
                      // // createLine
                      this.Dummyall_day = this.all_day.slice(
                        this.firstClick - 1,
                        this.seconClick
                      );
                      this.bgDiv = [];
                      for (
                        let index = 0;
                        index < this.Dummyall_day.length;
                        index++
                      ) {
                        let bgDiv = document.createElement("div");
                        this.Dummyall_day[index].appendChild(bgDiv);
                        bgDiv.classList.add("bg-caledar-div");
                        bgDiv.classList.add("bg-primary-100");
                        if (this.firstClick != this.seconClick) {
                          // first
                          if (index == 0) {
                            bgDiv.style.cssText =
                              "width: 34px;height: 24px;position: relative;top: -24px;left: 10px;z-index: -1;";
                          }
                          //ระหว่าง
                          if (
                            index != 0 &&
                            index != this.Dummyall_day.length - 1
                          ) {
                            bgDiv.style.cssText =
                              "width: 74px;height: 24px;position: relative;top: -24px;left: -30px;z-index: -1;";
                          }
                          // last
                          if (index == this.Dummyall_day.length - 1) {
                            bgDiv.style.cssText =
                              "width: 24px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1;";
                          }
                        }

                        this.bgDiv.push(bgDiv);
                      }
                    }
                  }
                } else {
                  if (this.countClick == 0) {
                    setTimeout(() => {
                      // วันเริ่มเผยแพร่
                      if (
                        i - first_day.getDay() + 1 ===
                          parseInt(this.publicDD) &&
                        year + 543 === parseInt(this.publicYYYY) &&
                        month === parseInt(this.publicMM)
                      ) {
                        day.classList.add("rounded-circle");
                        day.classList.add("bg-primary-600");
                        day.classList.add("text-white");
                        this.firstClick = day.id;
                      }
                      // วันสิ้นสุด
                      if (
                        i - first_day.getDay() + 1 === parseInt(this.endDD) &&
                        year + 543 === parseInt(this.endYYYY) &&
                        month === parseInt(this.endMM)
                      ) {
                        day.classList.add("rounded-circle");
                        day.classList.add("bg-primary-600");
                        day.classList.add("text-white");
                        this.seconClick = day.id;
                        // // createLine
                        this.Dummyall_day = this.all_day.slice(
                          this.firstClick - 1,
                          this.seconClick
                        );
                        this.bgDiv = [];
                        for (
                          let index = 0;
                          index < this.Dummyall_day.length;
                          index++
                        ) {
                          let bgDiv = document.createElement("div");
                          this.Dummyall_day[index].appendChild(bgDiv);
                          bgDiv.classList.add("bg-caledar-div");
                          bgDiv.classList.add("bg-primary-100");
                          if (this.firstClick != this.seconClick) {
                            // first
                            if (index == 0) {
                              bgDiv.style.cssText =
                                "width: 34px;height: 24px;position: relative;top: -24px;left: 10px;z-index: -1;";
                            }
                            //ระหว่าง
                            if (
                              index != 0 &&
                              index != this.Dummyall_day.length - 1
                            ) {
                              bgDiv.style.cssText =
                                "width: 74px;height: 24px;position: relative;top: -24px;left: -30px;z-index: -1;";
                            }
                            // last
                            if (index == this.Dummyall_day.length - 1) {
                              bgDiv.style.cssText =
                                "width: 24px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1;";
                            }
                          }

                          this.bgDiv.push(bgDiv);
                        }
                      }
                    }, 1000);
                  }
                }
              } else if (
                (this.showFirstTime == true && this.showSeconTime == false) ||
                (this.showFirstTime == false && this.showSeconTime == false)
              ) {
                if (this.Value.length == 33) {
                  if (this.countClick == 0) {
                    let data = this.Value.split("/");
                    // วันเริ่มเผยแพร่
                    if (
                      i - first_day.getDay() + 1 === parseInt(data[0]) &&
                      year + 543 === parseInt(data[2]) &&
                      month === parseInt(data[1])
                    ) {
                      day.classList.add("rounded-circle");
                      day.classList.add("bg-primary-600");
                      day.classList.add("text-white");
                      this.firstClick = day.id;
                    }
                  }
                } else {
                  if (this.countClick == 0) {
                    let data = this.Value.split("/");
                    // วันเริ่มเผยแพร่
                    if (
                      i - first_day.getDay() + 1 === parseInt(data[0]) &&
                      year + 543 === parseInt(data[2]) &&
                      month === parseInt(data[1])
                    ) {
                      day.classList.add("rounded-circle");
                      day.classList.add("bg-primary-600");
                      day.classList.add("text-white");
                      this.firstClick = day.id;
                    }
                  } else {
                    let data = this.Value.split("/");
                    if (
                      year + 543 === parseInt(data[2]) &&
                      month === parseInt(data[1])
                    ) {
                      day.classList.remove("rounded-circle");
                      day.classList.remove("bg-primary-600");
                      day.classList.remove("text-white");
                    }
                    // วันเริ่มเผยแพร่
                    if (
                      i - first_day.getDay() + 1 === parseInt(data[0]) &&
                      year + 543 === parseInt(data[2]) &&
                      month === parseInt(data[1])
                    ) {
                      day.classList.add("rounded-circle");
                      day.classList.add("bg-primary-600");
                      day.classList.add("text-white");
                      this.firstClick = day.id;
                    }
                  }
                }
              }
            }
          }, 1000);

          // เปลี่ยนเดือนแล้วกลับมาหน้าเดิม
          setTimeout(() => {
            // ว/ด/ป เผยแพร่
            if (
              i - first_day.getDay() + 1 == this.publicDD &&
              month == this.publicMM &&
              year + 543 == this.publicYYYY
            ) {
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              day.classList.add("rounded-circle");
              day.classList.add("bg-primary-600");
              day.classList.add("text-white");
              if (this.showSeconTime == true && this.countClick != 0) {
                this.createLine(i - first_day.getDay() + 1, month, year);
              }
            }
            // ระหว่างเดือน
            if (
              i - first_day.getDay() + 1 == 1 &&
              month > this.publicMM &&
              year + 543 == this.publicYYYY &&
              month < this.endMM &&
              year + 543 == this.endYYYY
            ) {
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              if (this.showSeconTime == true && this.countClick != 0) {
                this.createLine(i - first_day.getDay() + 1, month, year);
              }
            }
            // ว/ด/ป สิ้นสุด
            if (
              i - first_day.getDay() + 1 == this.endDD &&
              month == this.endMM &&
              year + 543 == this.endYYYY
            ) {
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              if (this.showSeconTime == true && this.countClick != 0) {
                day.classList.add("rounded-circle");
                day.classList.add("bg-primary-600");
                day.classList.add("text-white");
                this.createLine(i - first_day.getDay() + 1, month, year);
              }
            }
            // ปี = ปีสิ้นสุด เดือน < เดือนสิ้นสุด
            if (
              i - first_day.getDay() + 1 == 1 &&
              month < this.endMM &&
              year + 543 == this.endYYYY
            ) {
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              if (this.showSeconTime == true && this.countClick != 0) {
                this.createLine(i - first_day.getDay() + 1, month, year);
              }
            }
            // ปี = ปีเริ่มเผยแพร่ เดือน > เดือนสิ้นสุด
            if (
              i - first_day.getDay() + 1 == 1 &&
              month > this.publicMM &&
              year + 543 == this.publicYYYY
            ) {
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              if (this.showSeconTime == true && this.countClick != 0) {
                this.createLine(i - first_day.getDay() + 1, month, year);
              }
            }

            if (
              i - first_day.getDay() + 1 == 1 &&
              year + 543 > this.publicYYYY &&
              year + 543 < this.endYYYY
            ) {
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              if (this.showSeconTime == true && this.countClick != 0) {
                this.createLine(i - first_day.getDay() + 1, month, year);
              }
            }
          }, 300);
          // }
          //ใส่ค่าให้ auto ใรครั้งแรก
          if (
            (this.showFirstTime == true && this.showSeconTime == false) ||
            (this.showFirstTime == true && this.showSeconTime == true)
          ) {
            setTimeout(() => {
              if (this.Value == undefined || this.Value == "") {
                if (
                  this.publicDD == "" &&
                  this.publicMM == "" &&
                  this.publicYYYY == "" &&
                  this.publicDays == ""
                ) {
                  if (
                    typeof this.firstDisable == "string" &&
                    this.firstDisable != ""
                  ) {
                    let firstDisable = this.firstDisable.split("/");
                    let dd = parseInt(firstDisable[0]);
                    let mm = parseInt(firstDisable[1]);
                    let yy = parseInt(firstDisable[2]) - 543;
                    this.publicDD = dd;
                    this.publicMM = mm;
                    this.publicYYYY = parseInt(yy) + 543;
                    this.publicDays = currDate.getDay();
                  } else {
                    this.publicDD =
                      currDate.getDate().toString().length > 1
                        ? currDate.getDate()
                        : "0" + currDate.getDate().toString();
                    this.publicMM = currDate.getMonth();
                    this.publicYYYY = currDate.getFullYear() + 543;
                    this.publicDays = currDate.getDay();
                  }
                }

                if (
                  this.endDD == "" &&
                  this.endMM == "" &&
                  this.endYYYY == "" &&
                  this.endDays == ""
                ) {
                  if (
                    currDate.getDate() + 1 >
                    this.days_of_month[currDate.getMonth()]
                  ) {
                    this.endDD = "01";
                    this.endMM = currDate.getMonth() + 1;
                  } else {
                    this.endDD =
                      (currDate.getDate() + 1).toString().length > 1
                        ? currDate.getDate() + 1
                        : "0" + (currDate.getDate() + 1).toString();
                    this.endMM = currDate.getMonth();
                  }

                  this.endYYYY = currDate.getFullYear() + 543;
                  this.endDays = currDate.getDay() + 1;
                }
              } else if (this.Value != undefined) {
                if (this.showFirstTime == true && this.showSeconTime == false) {
                  if (this.Value.length == 33) {
                    let data = this.Value.split("/");
                    this.publicDD = data[0];
                    this.publicMM = parseInt(data[1]);
                    this.publicYYYY = data[2];
                    // this.endDD = data[4];
                    // this.endMM = parseInt(data[5]);
                    // this.endYYYY = data[6];
                  } else {
                    let data = this.Value.split(" ");
                    this.publicDD = data[0];
                    this.publicMM = this.convertMonth(data[1]);
                    this.publicYYYY = parseInt(data[2]);
                  }
                } else if (
                  this.showFirstTime == true &&
                  this.showSeconTime == true
                ) {
                  if (this.Value == "") {
                    // ค่าที่รับเข้ามาเป้ฯค่าว่าง จะDefault ว/ด/ป ปัจจุบัน
                    this.publicDD =
                      currDate.getDate().toString().length > 1
                        ? currDate.getDate()
                        : "0" + currDate.getDate().toString();
                    this.publicMM = currDate.getMonth();
                    this.publicYYYY = currDate.getFullYear() + 543;
                    this.publicDays = currDate.getDay();
                    if (
                      currDate.getDate() + 1 >
                      this.days_of_month[currDate.getMonth()]
                    ) {
                      this.endDD = "01";
                      this.endMM = currDate.getMonth() + 1;
                    } else {
                      this.endDD =
                        (currDate.getDate() + 1).toString().length > 1
                          ? currDate.getDate() + 1
                          : "0" + (currDate.getDate() + 1).toString();
                      this.endMM = currDate.getMonth();
                    }

                    this.endYYYY = currDate.getFullYear() + 543;
                    this.endDays = currDate.getDay() + 1;
                  } else {
                    let data = this.Value.split("/");
                    this.publicDD = data[0];
                    this.publicMM = parseInt(data[1]);
                    this.publicYYYY = data[2];
                    this.endDD = data[4];
                    this.endMM = parseInt(data[5]);
                    this.endYYYY = data[6];
                    // 20/02/2566/20:58/23/02/2566/23:59
                  }
                }
              }
            }, 500);
          }
        }

        calendar_days.appendChild(day);

        calendar_days.onclick = (e) => {
          // console.log(
          //   "id:" + e.target.id,
          //   parseInt(e.target.outerText),
          //   month + 1,
          //   year
          // );
          // เพิ่มคลาส
          if (e.target.outerText != "" && e.target.id != "") {
            // เคลียร์ class ออกก่อนทำการ select
            if (this.countClick == 0) {
              let clearOnSelec = this.all_day;
              for (let l = 0; l < clearOnSelec.length; l++) {
                clearOnSelec[l].classList.remove("bg-primary-600");
                clearOnSelec[l].classList.remove("rounded-circle");
                clearOnSelec[l].classList.remove("text-white");
              }
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
            }
            // ตรวจสอบตำแหน่งของ element ที่คลิก
            let pos = e.x;
            // console.log(pos);

            // จำนวนการคลิก
            ++this.countClick;
            // console.log("จำนวนการคลิก");
            if (this.showSeconTime == true) {
              if (this.countClick > 2) {
                for (let g = 0; g < this.bgDiv.length; g++) {
                  // console.log("Remove bg");
                  this.bgDiv[g].remove();
                }
                this.countClick = 1;

                let clearOnSelec = this.all_day;
                for (let l = 0; l < clearOnSelec.length; l++) {
                  // console.log("remove class");
                  clearOnSelec[l].classList.remove("bg-primary-600");
                  clearOnSelec[l].classList.remove("rounded-circle");
                  clearOnSelec[l].classList.remove("text-white");
                }
              }
            } else if (this.showSeconTime == false) {
              if (this.countClick > 1) {
                for (let g = 0; g < this.bgDiv.length; g++) {
                  this.bgDiv[g].remove();
                }
                this.countClick = 1;

                let clearOnSelec = this.all_day;
                for (let l = 0; l < clearOnSelec.length; l++) {
                  clearOnSelec[l].classList.remove("bg-primary-600");
                  clearOnSelec[l].classList.remove("rounded-circle");
                  clearOnSelec[l].classList.remove("text-white");
                }
              }
            }
            if (this.countClick == 1) {
              let dd =
                e.target.outerText.length > 1
                  ? e.target.outerText
                  : "0" + e.target.outerText;
              let mm = month + 1;
              let yyyy = year + 543;

              this.publicDay = dd + (mm.length > 1 ? mm : "0" + mm) + yyyy;
              this.publicDD = dd;
              this.publicMM = mm - 1;
              this.publicYYYY = yyyy;
              this.publicDays = this.dateToday(pos);

              this.firstClick = e.target.id;

              this.endDD = dd;
              this.endMM = mm - 1;
              this.endYYYY = yyyy;
              this.endDays = this.dateToday(pos);

              this.seconClick = parseInt(e.target.id);

              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              if (this.$refs.endTime != undefined) {
                this.$refs.endTime.checkValueHours(23);
                this.$refs.endTime.checkValueMinutes(59);
              }
            }
            if (this.countClick == 2) {
              let dd =
                e.target.outerText.length > 1
                  ? e.target.outerText
                  : "0" + e.target.outerText;
              let mm = month + 1;
              let yyyy = year + 543;
              this.endDay =
                (dd.length > 1 ? dd : "0" + dd) +
                (mm.length > 1 ? mm : "0" + mm) +
                yyyy;

              this.endDD = dd;
              this.endMM = mm - 1;
              this.endYYYY = yyyy;
              this.endDays = this.dateToday(pos);

              this.seconClick = e.target.id;

              // วันสอบวันเผยแพร่กับสันสิ้นสุด
              this.public_EndDay();
              for (let g = 0; g < this.bgDiv.length; g++) {
                this.bgDiv[g].remove();
              }
              this.createLine(i - first_day.getDay() + 1, month, year);
            }

            // เพิ่มคลาส
            // console.log("เพิ่มคลาส");
            e.target.classList.add("bg-primary-600");
            e.target.classList.add("rounded-circle");
            e.target.classList.add("text-white");
            let day;
            if (e.target.outerText.length < 2) {
              day = "0" + e.target.outerText;
            } else {
              day = e.target.outerText;
            }
            let strValue = year + "-" + (month + 1) + "-" + day;

            this.$emit("strValue", strValue);
            this.$emit("Close", false);
            this.$emit("dd", day);
            this.$emit("mm", month + 1);
            this.$emit("yyyy", year);
          }

          // เพิ่มคลาสให้วันปัจจุบันหลังจากเลือกวันอื่น
          for (
            let i = 0;
            i <= this.days_of_month[month] + first_day.getDay() - 1;
            i++
          ) {
            // console.log("เพิ่มคลาสให้วันปัจจุบันหลังจากเลือกวันอื่น");
            if (i >= first_day.getDay()) {
              if (
                this.Value == undefined ||
                this.Value == "" ||
                this.Value == null
              ) {
                if (
                  i - first_day.getDay() === currDate.getDate() &&
                  year === currDate.getFullYear() &&
                  month === currDate.getMonth()
                ) {
                  this.all_day[currDate.getDate() - 1].classList.add(
                    "text-primary-600"
                  );
                  this.all_day[currDate.getDate() - 1].classList.add(
                    "fnt-bold"
                  );
                }
                if (
                  e.target.outerText == currDate.getDate() &&
                  month == currDate.getMonth() &&
                  year == currDate.getFullYear()
                ) {
                  if (this.all_day[i] != undefined) {
                    this.all_day[i].classList.remove("fnt-bold");
                  }
                }
              }
            }
          }

          this.ValueCalenda();
          // console.log("end");
        };
      }
    },
    ValueCalenda() {
      // console.log("ValueCalenda");
      if (this.showSeconTime == true && this.showFirstTime == true) {
        // console.log(this.publicDD);
        // console.log(this.publicMM);
        // console.log(this.publicYYYY);
        // console.log(this.endDD);
        // console.log(this.endMM);
        // console.log(this.endYYYY);
        let publics =
          (this.publicDD.toString().length > 1
            ? this.publicDD.toString()
            : "0" + this.publicDD.toString()) +
          "/" +
          (this.publicMM.toString().length > 1
            ? this.publicMM.toString()
            : "0" + this.publicMM.toString()) +
          "/" +
          this.publicYYYY.toString() +
          "/" +
          this.intPublicTime;
        let ends =
          (this.endDD.toString().length > 1
            ? this.endDD.toString()
            : "0" + this.endDD.toString()) +
          "/" +
          (this.endMM.toString().length > 1
            ? this.endMM.toString()
            : "0" + this.endMM.toString()) +
          "/" +
          this.endYYYY.toString() +
          "/" +
          this.intEndTime;
        // if (publics == ends) {
        //   console.log("===");
        //   this.blnshowRepeat = true;
        // } else if (publics != ends) {
        //   this.blnshowRepeat = false;
        // }
        this.valueCalenda = publics + "/" + ends;
        if (this.valueCalenda.length == 33) {
          if (this.blnshowRepeat == false) {
            this.$emit("valueCalenda", this.valueCalenda);
          }
        }
        if (this.Value == "") {
          this.$emit("valueCalenda", this.valueCalenda);
        }
      } else if (this.showSeconTime == false && this.showFirstTime == true) {
        setTimeout(() => {
          this.valueCalenda =
            (this.publicDD.toString().length > 1
              ? this.publicDD.toString()
              : "0" + this.publicDD.toString()) +
            "/" +
            (this.publicMM.toString().length > 1
              ? this.publicMM.toString()
              : "0" + this.publicMM.toString()) +
            "/" +
            this.publicYYYY.toString() +
            "/" +
            this.intPublicTime;
          if (this.valueCalenda.length == 16) {
            if (this.blnShowAlertInp == true) {
              setTimeout(() => {
                if (this.blnshowRepeat == false) {
                  this.$emit("valueCalenda", this.valueCalenda);
                }
              }, 500);
            } else if (this.blnShowAlertInp == false) {
              this.$emit("valueCalenda", this.valueCalenda);
            }
          }
        }, 600);
      } else if (this.showSeconTime == false && this.showFirstTime == false) {
        this.valueCalenda =
          (this.publicDD.toString().length > 1
            ? this.publicDD.toString()
            : "0" + this.publicDD.toString()) +
          "/" +
          (this.publicMM.toString().length > 1
            ? this.publicMM.toString()
            : "0" + this.publicMM.toString()) +
          "/" +
          this.publicYYYY.toString();
        if (this.valueCalenda.length == 10) {
          if (this.blnShowAlertInp == true) {
            setTimeout(() => {
              if (this.blnshowRepeat == false) {
                this.$emit("valueCalenda", this.valueCalenda);
              }
            }, 500);
          } else if (this.blnShowAlertInp == false) {
            this.$emit("valueCalenda", this.valueCalenda);
          }
        }
      }
    },
    ValidRepeat() {
      // console.log("validRepeat");
      if (this.blnShowAlertInp == true) {
        if (this.listDataRepeat.length != 0) {
          // ตรวจสอบซ้ำกับในlist ที่มีอยู่
          for (let index = 0; index < this.listDataRepeat.length; index++) {
            // const element = this.listDataRepeat[index];
            // เช็คซ้ำแจ้งเตือนครั้งแรก
            let First_listDataRepeat = this.listDataRepeat[index];
            let First_listDataRepeat_yyyy = First_listDataRepeat.split(" ")[2];
            let First_listDataRepeat_mm = First_listDataRepeat.split(" ")[1];
            let First_listDataRepeat_dd = First_listDataRepeat.split(" ")[0];
            let First_listDataRepeat_time = First_listDataRepeat.split(" ")[3];
            let First_listDataRepeat_hours =
              First_listDataRepeat_time.split(":")[0];
            let First_listDataRepeat_minutes =
              First_listDataRepeat_time.split(":")[1];
            // ปี
            if (
              parseInt(this.publicYYYY) ==
                parseInt(First_listDataRepeat_yyyy) &&
              parseInt(this.publicMM) ==
                parseInt(First_listDataRepeat_mm) - 1 &&
              parseInt(this.publicDD) == parseInt(First_listDataRepeat_dd) &&
              parseInt(this.intPublicTime.split(":")[0]) ==
                parseInt(First_listDataRepeat_hours) &&
              parseInt(this.intPublicTime.split(":")[1]) ==
                parseInt(First_listDataRepeat_minutes)
            ) {
              this.alertMsgInput = "646";
              this.blnshowRepeat = true;
            } else {
              this.blnshowRepeat = false;
              // เช็คการแจ้งเตือนซ้ำให้อยู่ในช่วงเวลาที่เผยแพร่
              this.checkRepeat();
            }
          }
        } else {
          this.checkRepeat();
        }
      }
    },
    checkRepeat() {
      if (typeof this.Value != "string") {
        return;
      }
      let data = this.Value.split("/");
      let startDate = data[0];
      let startMM = data[1];
      let startYYYY = data[2];
      let startHours = data[3].split(":")[0];
      let startMinu = data[3].split(":")[1];

      let endDate = data[4];
      let endMM = data[5];
      let endYYYY = data[6];
      this.alertMsgInput = "กรุณาเลือกเวลาแจ้งเตือนซ้ำ ในช่วงเวลาที่เผยแพร่";
      // เช็คการแจ้งเตือนซ้ำให้อยู่ในช่วงเวลาที่เผยแพร่
      if (this.publicYYYY < startYYYY || this.publicYYYY > endYYYY) {
        this.blnshowRepeat = true;
      } else if (this.publicYYYY >= startYYYY && this.publicYYYY <= endYYYY) {
        this.blnshowRepeat = false;
        if (
          parseInt(this.publicMM) < this.convertMonth(startMM) ||
          parseInt(this.publicMM) > this.convertMonth(endMM)
        ) {
          this.blnshowRepeat = true;
        } else if (
          parseInt(this.publicMM) >= this.convertMonth(startMM) &&
          parseInt(this.publicMM) <= this.convertMonth(endMM)
        ) {
          this.blnshowRepeat = false;
          if (
            parseInt(this.publicDD) == startDate &&
            parseInt(this.publicDD) != endDate
          ) {
            // console.log("--------");
            this.blnshowRepeat = false;
            if (this.intPublicTime != null) {
              // อดีต
              if (
                parseInt(this.intPublicTime.split(":")[0]) <
                parseInt(startHours)
              ) {
                this.blnshowRepeat = true;
              } else if (
                parseInt(this.intPublicTime.split(":")[0]) >=
                parseInt(startHours)
              ) {
                this.blnshowRepeat = false;
                if (
                  parseInt(this.intPublicTime.split(":")[1]) <
                  parseInt(startMinu)
                ) {
                  this.blnshowRepeat = true;
                } else {
                  this.blnshowRepeat = false;
                }
              }
            }
          } else if (
            parseInt(this.publicDD) == endDate &&
            parseInt(this.publicDD) != startDate
          ) {
            // console.log("=!=");
            this.blnshowRepeat = false;
            if (this.intPublicTime != null && this.intEndTime != null) {
              // อนาคต
              if (
                parseInt(this.intPublicTime.split(":")[0]) >
                parseInt(this.intEndTime.split(":")[0])
              ) {
                this.blnshowRepeat = true;
              } else if (
                parseInt(this.intPublicTime.split(":")[0]) <=
                parseInt(this.intEndTime.split(":")[0])
              ) {
                this.blnshowRepeat = false;
                if (
                  parseInt(this.intPublicTime.split(":")[1]) >
                  parseInt(this.intEndTime.split(":")[1])
                ) {
                  this.blnshowRepeat = true;
                } else {
                  this.blnshowRepeat = false;
                }
              }
            }
          } else if (
            parseInt(this.publicDD) == startDate &&
            parseInt(this.publicDD) == endDate
          ) {
            // console.log("==");
            this.blnshowRepeat = false;
            if (this.intPublicTime != null && this.intEndTime != null) {
              if (
                parseInt(this.intPublicTime.split(":")[0]) <
                  parseInt(startHours) ||
                parseInt(this.intPublicTime.split(":")[0]) >
                  parseInt(this.intEndTime.split(":")[0])
              ) {
                this.blnshowRepeat = true;
              } else if (
                parseInt(this.intPublicTime.split(":")[0]) >=
                  parseInt(startHours) &&
                parseInt(this.intPublicTime.split(":")[0]) <=
                  parseInt(this.intEndTime.split(":")[0])
              ) {
                this.blnshowRepeat = false;
                if (
                  parseInt(this.intPublicTime.split(":")[1]) <
                    parseInt(startMinu) ||
                  parseInt(this.intPublicTime.split(":")[1]) >
                    parseInt(this.intEndTime.split(":")[1])
                ) {
                  this.blnshowRepeat = true;
                } else {
                  this.blnshowRepeat = false;
                }
              }
            }
          } else if (
            parseInt(this.publicDD) < startDate ||
            parseInt(this.publicDD) > endDate
          ) {
            this.blnshowRepeat = true;
          }
        }
      }
    },
    createLine(date, month, year) {
      let dummyfirstClick = this.firstClick;
      let dummyseconClick = this.seconClick;
      if (parseInt(this.firstClick) > parseInt(this.seconClick)) {
        this.firstClick = dummyseconClick;
        this.seconClick = dummyfirstClick;
      }
      // ภายในปีเดียวกัน
      if (this.publicYYYY == this.endYYYY) {
        if (this.publicMM == this.endMM) {
          if (month == this.endMM) {
            // ภานในเดือนเดียวกัน
            this.Dummyall_day = this.all_day.slice(
              this.firstClick - 1,
              this.seconClick
            );
          }
        } else {
          // ระหว่างเดือน
          if (month == this.endMM) {
            this.Dummyall_day = this.all_day.slice(0, this.endDD);
          } else if (month == this.publicMM) {
            this.Dummyall_day = this.all_day.slice(
              this.publicDD - 1,
              this.all_day.length
            );
          } else if (month > this.publicMM && month < this.endMM) {
            this.Dummyall_day = this.all_day;
          }
        }
      } else {
        // ต่างปี
        if (year + 543 == this.endYYYY) {
          // ระหว่างเดือน
          if (month == this.endMM) {
            this.Dummyall_day = this.all_day.slice(0, this.endDD);
          } else {
            this.Dummyall_day = this.all_day;
          }
        } else if (year + 543 == this.publicYYYY) {
          if (month == this.publicMM) {
            this.Dummyall_day = this.all_day.slice(
              this.publicDD - 1,
              this.all_day.length
            );
          } else {
            this.Dummyall_day = this.all_day;
          }
        } else if (year + 543 > this.publicYYYY && year + 543 < this.endYYYY) {
          this.Dummyall_day = this.all_day;
        }
      }

      // console.log(this.Dummyall_day);
      if (
        this.publicYYYY == this.endYYYY &&
        this.publicMM == this.endMM &&
        this.firstClick == this.seconClick
      ) {
        for (let g = 0; g < this.bgDiv.length; g++) {
          this.bgDiv[g].remove();
        }
      } else {
        for (let g = 0; g < this.bgDiv.length; g++) {
          this.bgDiv[g].remove();
        }
        this.bgDiv = [];
        for (let index = 0; index < this.Dummyall_day.length; index++) {
          let bgDiv = document.createElement("div");
          this.Dummyall_day[index].appendChild(bgDiv);
          bgDiv.classList.add("bg-caledar-div");
          bgDiv.classList.add("bg-primary-100");

          // const listPosition = [];
          // document.querySelectorAll(".bg-caledar-div").forEach((ele) => {
          //   listPosition.push(ele.getBoundingClientRect().x);
          // });
          // ตำแหน่งที่น้อยที่สุด
          // const min = Math.min(...listPosition) + 1;
          // ตำแหน่งที่มากที่สุด
          // const max = Math.max(...listPosition) + 1;

          if (index == 0) {
            bgDiv.style.cssText =
              "width: 34px;height: 24px;position: relative;top: -24px;left: 0px;z-index: -1; ";
          } else if (index == this.Dummyall_day.length - 1) {
            bgDiv.style.cssText =
              "width: 34px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1;";
          } else {
            bgDiv.style.cssText =
              "width: 44px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1;";
          }

          if (year + 543 == this.publicYYYY && month == this.publicMM) {
            // console.log(this.firstClick);
            if (index == 0) {
              bgDiv.style.cssText =
                "width: 34px;height: 24px;position: relative;top: -24px;left: 0px;z-index: -1;border-radius: 20px 0% 0% 20px;";
            } else if (index == this.Dummyall_day.length - 1) {
              bgDiv.style.cssText =
                "width: 34px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1; border-radius: 0% 20px 20px 0%;";
            }
          }
          if (
            year + 543 == this.publicYYYY &&
            month == this.publicMM &&
            date == this.publicDD
          ) {
            if (index == 0) {
              bgDiv.style.cssText =
                "width: 34px;height: 24px;position: relative;top: -24px;left: 10px;z-index: -1;";
            } else if (index == this.Dummyall_day.length - 1) {
              bgDiv.style.cssText =
                "width: 44px;height: 24px;position: relative;top: -24px;left: -10px;z-index: -1;";
            }
          }
          if (
            year + 543 == this.endYYYY &&
            month == this.endMM &&
            this.Dummyall_day.length == this.endDD
          ) {
            // console.log(date);
            if (index == this.Dummyall_day.length - 1) {
              bgDiv.style.cssText =
                "width: 34px;height: 24px;position: relative;top: -24px;left: -20px;z-index: -1;";
            }
          }

          this.bgDiv.push(bgDiv);
        }
      }
    },
    dateToday(posX) {
      // console.log(posX);
      if (posX <= 380) {
        // วันอาทิตย์
        return 0;
      } else if (posX > 380 && posX <= 420) {
        // วันจันทร์
        return 1;
      } else if (posX > 420 && posX <= 470) {
        // วันอังคาร
        return 2;
      } else if (posX > 470 && posX <= 510) {
        // วันพุธ
        return 3;
      } else if (posX > 510 && posX <= 550) {
        // วันพฤหัสบดี
        return 4;
      } else if (posX > 550 && posX <= 600) {
        // วันศุกร์
        return 5;
      } else if (posX > 600) {
        // วันเสาร์
        return 6;
      }
    },
    public_EndDay() {
      // day
      let dummyEndDay = this.endDays;
      let dummyPublicDay = this.publicDays;
      // date
      let dummyEndDD = this.endDD;
      let dummyPublicDD = this.publicDD;
      // month
      let dummyEndMM = this.endMM;
      let dummyPublicMM = this.publicMM;
      // year
      let dummyEndYYYY = this.endYYYY;
      let dummyPublicYYYY = this.publicYYYY;

      if (dummyEndYYYY > dummyPublicYYYY) {
        // console.log("year");
        // day
        dummyEndDay = this.publicDays;
        dummyPublicDay = this.endDays;
        // date
        dummyEndDD = this.endDD;
        dummyPublicDD = this.publicDD;
        // month
        dummyEndMM = this.publicMM;
        dummyPublicMM = this.endMM;
        // year
        dummyEndYYYY = this.publicYYYY;
        dummyPublicYYYY = this.endYYYY;

        // day
        this.publicDays = dummyEndDay;
        this.endDays = dummyPublicDay;
        // date
        this.publicDD = dummyPublicDD;
        this.endDD = dummyEndDD;
        // month
        this.publicMM = dummyEndMM;
        this.endMM = dummyPublicMM;
        // year
        this.publicYYYY = dummyEndYYYY;
        this.endYYYY = dummyPublicYYYY;

        // console.log(this.endDD);
        // console.log(this.publicDD);
        // console.log(this.publicMM);
        // console.log(this.endMM);
        // console.log(this.publicYYYY);
        // console.log(this.endYYYY);
      } else if (dummyEndYYYY == dummyPublicYYYY) {
        if (dummyEndMM < dummyPublicMM) {
          // console.log("month");
          // day
          dummyEndDay = this.publicDays;
          dummyPublicDay = this.endDays;
          // date
          dummyEndDD = this.publicDD;
          dummyPublicDD = this.endDD;
          // month
          dummyEndMM = this.publicMM;
          dummyPublicMM = this.endMM;

          // day
          this.publicDays = dummyPublicDay;
          this.endDays = dummyEndDay;
          // date
          this.publicDD = dummyPublicDD;
          this.endDD = dummyEndDD;
          // month
          this.publicMM = dummyPublicMM;
          this.endMM = dummyEndMM;
        } else if (dummyEndMM == dummyPublicMM) {
          if (dummyEndDD < dummyPublicDD) {
            // console.log("date");

            // day
            dummyEndDay = this.publicDays;
            dummyPublicDay = this.endDays;
            // date
            dummyEndDD = this.publicDD;
            dummyPublicDD = this.endDD;

            // day
            this.publicDays = dummyPublicDay;
            this.endDays = dummyEndDay;
            // date
            this.publicDD = dummyPublicDD;
            this.endDD = dummyEndDD;
          }
        }
      } else {
        // year
        dummyEndYYYY = this.publicYYYY;
        dummyPublicYYYY = this.endYYYY;
        // month
        dummyEndMM = this.publicMM;
        dummyPublicMM = this.endMM;
        // date
        dummyEndDD = this.publicDD;
        dummyPublicDD = this.endDD;

        this.publicYYYY = dummyPublicYYYY;
        this.endYYYY = dummyEndYYYY;
        this.publicMM = dummyPublicMM;
        this.endMM = dummyEndMM;
        this.publicDD = dummyPublicDD;
        this.endDD = dummyEndDD;
      }
    },
    convertMonth(data) {
      if (data == "ม.ค." || data == "Jan") {
        return 0;
      } else if (data == "ก.พ." || data == "Feb") {
        return 1;
      } else if (data == "มี.ค." || data == "Mar") {
        return 2;
      } else if (data == "เม.ย." || data == "Apr") {
        return 3;
      } else if (data == "พ.ค." || data == "May") {
        return 4;
      } else if (data == "ม.ย." || data == "Jun") {
        return 5;
      } else if (data == "ก.ค." || data == "Jul") {
        return 6;
      } else if (data == "ส.ค." || data == "Aug") {
        return 7;
      } else if (data == "ก.ย." || data == "Sep") {
        return 8;
      } else if (data == "ต.ค." || data == "Oct") {
        return 9;
      } else if (data == "พ.ย." || data == "Nov") {
        return 10;
      } else if (data == "ธ.ค." || data == "Dec") {
        return 11;
      }
    },
    UpdateData() {
      // console.log("Update data calendar");
      // console.log(this.Value);
      if (this.Value != null) {
        if (this.Value.length == 16) {
          let data = this.Value.split("/");
          this.publicDD = data[0];
          this.publicMM = parseInt(data[1]);
          this.publicYYYY = data[2];
          this.intPublicTime = data[3];

          this.endDD = data[0];
          this.endMM = parseInt(data[1]);
          this.endYYYY = data[2];
          this.intEndTime = data[3];
        }
        this.valueCalenda = this.Value;
      } else if (this.Value == null) {
        this.valueCalenda = "";
      }
      // console.log(this.valueCalenda);
      this.$emit("valueCalenda", this.valueCalenda);
    },
  },
};
</script>
  
<style lang="scss" scoped>
:root {
  --dark-body: #4d4c5a;
  --dark-main: #141529;
  --dark-second: #79788c;
  --dark-hover: #323048;
  --dark-text: #f8fbff;
  --light-body: #f3f8fe;
  --light-main: #fdfdfd;
  --light-second: #c3c2c8;
  --light-hover: #edf0f5;
  --light-text: #151426;
  --blue: #007497;
  --white: #fff;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --font-family: cursive;
}

.light {
  --bg-body: var(--light-body);
  --bg-main: var(--light-main);
  --bg-second: var(--light-second);
  --color-hover: var(--light-hover);
  --color-txt: var(--light-text);
}

.light {
  height: 100vh;
  display: grid;
  place-items: center;
  font-family: var(--font-family);
  background-color: var(--bg-body);
}

.calendar {
  // width: 320px;
  height: 325px;
  background-color: var(--bg-main);
  // position: relative;
  overflow: hidden;
  border-color: $black-300 !important;
  z-index: 2;
}

.grid-container {
  display: grid;
  /* creates a grid container */
  grid-template-columns: repeat(7, 1fr);
  /* creates 3 equal-width columns */
  place-items: center;
  // cursor: pointer;
  position: absolute;
  width: 300px;
  bottom: -165px;
}

.box-date {
  width: 24px;
  height: 24px;
}

.light .calendar {
  // box-shadow: var(--shadow);
}

.calendar-header {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: var(--color-txt);
  // padding: 10px;
}

// .calendar-body {
// padding: 10px;
// }
calendar-day-hover:hover {
  background-color: $black;
}

.calendar-week-day {
  height: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  // font-weight: 600;
}

.calendar-week-day div {
  display: grid;
  place-items: center;
  color: var(--bg-second);
}

.calendar-days {
  user-select: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  // gap: 17px;
  color: var(--color-txt);

  &:hover {
    cursor: pointer;
  }
}

.calendar-days div {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  cursor: pointer;
  animation: to-top 1s forwards;
}

.calendar-days-hover:hover {
  background-color: $primary-200;
}

.calendar-days div span {
  position: absolute;
}

.calendar-days div:hover span {
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}

.calendar-days div span:nth-child(1),
.calendar-days div span:nth-child(3) {
  width: 2px;
  height: 0;
  background-color: var(--color-txt);
}

.calendar-days div:hover span:nth-child(1),
.calendar-days div:hover span:nth-child(3) {
  height: 100%;
}

.calendar-days div span:nth-child(1) {
  bottom: 0;
  left: 0;
}

.calendar-days div span:nth-child(3) {
  top: 0;
  right: 0;
}

.calendar-days div span:nth-child(2),
.calendar-days div span:nth-child(4) {
  width: 0;
  height: 2px;
  background-color: var(--color-txt);
}

.calendar-days div:hover span:nth-child(2),
.calendar-days div:hover span:nth-child(4) {
  width: 100%;
}

.calendar-days div span:nth-child(2) {
  top: 0;
  left: 0;
}

.calendar-days div span:nth-child(4) {
  bottom: 0;
  right: 0;
}

.calendar-days div:hover span:nth-child(2) {
  transition-delay: 0.2s;
}

.calendar-days div:hover span:nth-child(3) {
  transition-delay: 0.4s;
}

.calendar-days div:hover span:nth-child(4) {
  transition-delay: 0.6s;
}

.current-date {
  background-color: $primary;
}

// .calendar-days div.curr-date,
// .calendar-days div.curr-date:hover {
//   background-color: var(--blue);
//   color: var(--white);
//   border-radius: 50%;
// }

// .calendar-days div.curr-date span {
//   display: none;
// }

.month-picker {
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.month-picker:hover {
  background-color: $black-200;
}

.year-picker {
  display: flex;
  align-items: center;
}

.year-change {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 0 10px;
  cursor: pointer;
}

.year-change:hover {
  background-color: $black-200;
}

.calendar-footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toggle {
  display: flex;
}

.toggle span {
  margin-right: 10px;
  color: var(--color-txt);
}

.month-list {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 3%;
  // background-color: var(--bg-main);
  // grid-template-columns: repeat(3, auto);
  // gap: 5px;
  // display: grid;
  transform: scale(1.5);
  visibility: hidden;
  pointer-events: none;

  // &:hover {
  //   .month {
  //     background-color: $black-200;
  //   }
  // }
  .month {
    &:hover {
      background-color: $black-200;
    }
  }

  .show {
  }
}

.month-list.show {
  transform: scale(1);
  visibility: visible;
  pointer-events: visible;
  transition: all 0.2s ease-in-out;
}

.month-list > div {
  // display: grid;
  // place-items: center;
}

.month-list > div > div {
  width: 100%;
  padding: 5px 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: var(--color-txt);
}

.label-text {
  font-size: 14px !important;
  padding: 0 4px !important;
}

.txt-inp {
  position: relative;
  width: 100%;

  p {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    color: $black-500;
    cursor: text;
    display: flex;
    user-select: none;
  }

  input {
    border: none;
    outline: 0;
    font-size: 16px;
    padding: 10px 17px !important;
    border: solid 1px $black-500;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    padding-top: 4px;

    &::placeholder {
      color: $white;
    }

    &:hover {
      border: solid 1px $primary;

      & + p {
        color: $black-500;
      }
    }

    &:focus {
      border: solid 1px $primary;

      & + p {
        top: -4%;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;

        .sub {
          display: none !important;
        }

        .require {
          display: flex !important;
        }
      }

      &::placeholder {
        color: $black-500 !important;
      }
    }

    &:valid + p {
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -4%;

      .sub {
        display: none !important;
      }

      .require {
        display: flex !important;
      }
    }

    &:valid {
      &:hover + p {
        color: $primary !important;
      }
    }
  }
}
</style>
  
<template>
  <div
    v-if="!image"
    :class="
      dropOver == true
        ? 'drop-over'
        : showTxtInp == true
        ? 'bg-black-200 ' + size
        : '' + size
    "
    class="dropFile d-flex justify-content-center align-items-center p-3"
    :style="style"
  >
    <div
      class="inputFile d-flex justify-content-center flex-column align-items-center"
      v-if="filetype == 'image/svg+xml'"
    >
      <AddOutline width="24" height="24" class="fill-black-500" />

      <p
        class="fnt-body m-0 fnt-light text-black-500"
        :class="
          Labelsize || (subname != '' || subname != null ? 'text-center' : '')
        "
      >
        <!-- {{ ValidNumber(name) ? name : $t(name) }} -->
        <!-- <br>  -->
        <span style="font-size: 10px !important" class="fnt-regular">{{
          $t(subname)
        }}</span>
      </p>
    </div>
    <div
      class="inputFile d-flex justify-content-center flex-column align-items-center"
      v-else
    >
      <!-- <images width="48" height="48" class="fill-black-500" /> -->
      <!-- <Icon
        icon="akar-icons:image"
        class="text-black-500"
        :class="classIcon"
        :width="widthicon"
        :height="heighticon"
      /> -->
      <svg
        :width="widthicon"
        :height="heighticon"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9399 4.5C10.9508 4.5 9.04316 5.29018 7.63664 6.6967C6.23012 8.10322 5.43994 10.0109 5.43994 12V36C5.43994 37.9891 6.23012 39.8968 7.63664 41.3033C8.99165 42.6583 10.8117 43.4413 12.7217 43.4968L29.622 24.9058L29.622 24.9057C30.0427 24.443 30.5553 24.0731 31.127 23.8195C31.6986 23.566 32.3169 23.4344 32.9423 23.4331C33.5677 23.4318 34.1864 23.5609 34.7591 23.8122C35.3318 24.0634 35.8459 24.4312 36.2685 24.8922L36.2685 24.8922L44.3285 33.6822L44.3292 33.683L44.3911 33.7507C44.4074 33.7685 44.4237 33.7863 44.4399 33.8041V12C44.4399 10.0109 43.6498 8.10322 42.2432 6.6967C40.8367 5.29018 38.9291 4.5 36.9399 4.5H12.9399ZM44.4399 36C44.4399 35.6064 44.3684 35.3719 44.2516 35.1666C44.1224 34.9395 43.9298 34.7286 43.6132 34.3819L43.5914 34.358L43.5907 34.3572L35.5314 25.568L35.5314 25.568C35.2027 25.2094 34.8029 24.9233 34.3574 24.7279C33.912 24.5325 33.4307 24.4321 32.9443 24.4331C32.4579 24.4341 31.977 24.5365 31.5324 24.7337C31.0878 24.9309 30.6891 25.2186 30.3619 25.5784L30.3619 25.5785L14.0703 43.5H36.9399C38.9291 43.5 40.8367 42.7098 42.2432 41.3033C43.6498 39.8968 44.4399 37.9892 44.4399 36ZM12.9294 44.5C12.9329 44.5 12.9364 44.5 12.9399 44.5H36.9399C39.1943 44.5 41.3563 43.6045 42.9503 42.0104C44.5444 40.4163 45.4399 38.2543 45.4399 36V12C45.4399 9.74566 44.5444 7.58365 42.9503 5.98959C41.3563 4.39553 39.1943 3.5 36.9399 3.5H12.9399C10.6856 3.5 8.52359 4.39553 6.92953 5.98959C5.33547 7.58365 4.43994 9.74566 4.43994 12V36C4.43994 38.2543 5.33547 40.4163 6.92953 42.0104C8.52112 43.602 10.6789 44.4972 12.9294 44.5ZM17.9399 14.5C15.4547 14.5 13.4399 16.5147 13.4399 19C13.4399 21.4853 15.4547 23.5 17.9399 23.5C20.4252 23.5 22.4399 21.4853 22.4399 19C22.4399 16.5147 20.4252 14.5 17.9399 14.5ZM12.4399 19C12.4399 15.9624 14.9024 13.5 17.9399 13.5C20.9775 13.5 23.4399 15.9624 23.4399 19C23.4399 22.0376 20.9775 24.5 17.9399 24.5C14.9024 24.5 12.4399 22.0376 12.4399 19Z"
          fill="#8E8E8E"
        />
      </svg>
      <p
        class="fnt-body m-0 fnt-light text-black-500"
        :class="
          Labelsize || (subname != '' || subname != null ? 'text-center' : '')
        "
      >
        <!-- {{ ValidNumber(name) ? name : $t(name) }} -->
        <!-- <br>  -->
        <span class="fnt-regular" :class="Labelsize">{{ $t(subname) }}</span>
      </p>
    </div>
    <input
      type="file"
      @change="onFileChange($event)"
      accept="image/*"
      :class="canClick == true ? 'notclick' : ''"
    />
  </div>

  <div
    v-else
    class="dropFile d-flex align-items-center justify-content-center p-2 position-relative"
    :class="size"
  >
    <img :src="image" alt="" />
    <div
      class="d-flex justify-content-center align-items-center overlay-action"
    >
      <div class="d-flex justify-content-center align-items-center action">
        <Button
          icon="OpenEye"
          :class="canClick == true ? '' : 'ms-2'"
          classBtnIcon="fill-black-200"
          @click="showImage()"
        />
        <Button
          icon="DeleteFill"
          @click="removeImage()"
          classBtnIcon="fill-black-200"
          class="me-2"
          :class="canClick == true ? 'notclick d-none' : ''"
        />
      </div>
    </div>
  </div>

  <div class="text-black fnt-body mt-2 mx-0 px-0">
    {{ ValidNumber(helper) ? helper : $t(helper) }}
  </div>
  <div
    class="text-danger mt-2 d-flex"
    :class="
      device == 'mobile'
        ? 'fnt-caption align-items-start'
        : 'fnt-body align-items-start'
    "
  >
    <Icon
      icon="carbon:warning"
      class="me-1"
      :class="device == 'mobile' ? 'm-1' : 'mt-1'"
      v-if="msgError != '' || null"
    />
    {{
      $t(msgError, {
        filesize: this.maxSize / 1000000,
        width: "4.2",
        height: "5.5",
      })
    }}
  </div>

  <div
    class="image-show d-flex align-items-center justify-content-center"
    @click="closeShowimg()"
    v-if="showimage"
  >
    <Button
      icon="CloseOutline"
      classBtnIcon="fill-white"
      widthIcon="40"
      heightIcon="40"
      class="close"
      classBtn="btn"
    />
    <img :src="image" alt="" />
  </div>
</template>

<script>
import { AddOutline } from "@/components/icon/index";
import { Icon } from "@iconify/vue";
import Button from "@/components/button/button.vue";
// import { images } from "@/components/icon/index";

export default {
  data() {
    return {
      dropOver: false,
      image: "",
      msgError: "",
      showimage: false,
      selectedImage: null,
    };
  },
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    alertMsg: { type: String },
    helper: { type: String },
    error: { type: String },
    maxSize: { type: Number },
    size: { type: String },
    widthicon: { type: String, default: "24" },
    heighticon: { type: String, default: "24" },
    subname: { type: String, default: "" },
    canClick: { type: Boolean, default: false },
    style: { type: String },
    Labelsize: { type: String, default: "fnt-caption" },
    filetype: { type: String, required: true },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    widthInp: { type: Number },
    heightInp: { type: Number },
  },
  components: {
    Button,
    Icon,
    AddOutline,
    // images,
  },
  mounted() {
    setTimeout(() => {
      if (this.value != null && this.value != "" && this.value != undefined) {
        this.image = this.value;
        // console.log(this.value);
        if (typeof this.value == "object") {
          this.image = this.value;
          this.createImage(this.image);
        }
      }
    }, 1000);
  },
  methods: {
    closeShowimg() {
      if (this.image !== "") {
        this.showimage = false;
      }
    },
    showImage() {
      if (this.image !== "") {
        this.showimage = true;
      } else {
        this.showimage = false;
      }
      // console.log(this.showimage);
    },
    ValidNumber(value) {
      return isNaN(value);
    },
    Valid() {
      return true;
    },
    alertRequest() {
      this.msgError = this.$t(this.alertMsg, {
        fieldname: this.$t(this.name).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    onFileChange(e) {
      let file = e.target.files || e.dataTransfer.files;
      // console.log(this.filetype);
      if (this.filetype == "image/svg+xml") {
        if (!file.length) return;
        if (file[0].type.indexOf("image/svg+xml") != 0) {
          this.msgError = "350";
        }

        if (file[0].size > this.maxSize) {
          // this.msgError = this.$t("220", { filesize: this.maxSize / 1000000 });
          this.msgError = "220";
        }

        if (
          file[0].type.indexOf("image/svg+xml") == 0 &&
          file[0].size < this.maxSize
        ) {
          this.alertMsgStatus = false;
          this.msgError = "";
          this.createImage(file[0]);
        }
      } else if (this.filetype == "image/jpeg") {
        if (!file.length) return;
        if (file[0].type.indexOf("image/jpeg") != 0) {
          this.msgError = "1028";
        } else if (file[0].size > this.maxSize) {
          // this.msgError = this.$t("220", { filesize: this.maxSize / 1000000 });
          this.msgError = "220";
        } else {
          this.createImage(file[0]);
          // clear alert
          this.alertMsgStatus = false;
          this.msgError = "";

          // if (this.widthInp != undefined && this.heightInp != undefined) {
          //   // ตรวจสอบขนาด กว้าง ยาว
          //     const fileInput = e.target;
          //     if (fileInput.files && fileInput.files.length > 0) {
          //       const file = fileInput.files[0];
          //       const reader = new FileReader();

          //       reader.onload = (e) => {
          //         const img = new Image();
          //         img.src = e.target.result;

          //         this.selectedImage = {
          //           name: file.name,
          //           url: e.target.result,
          //           width: img.width,
          //           height: img.height,
          //         };
          //         console.log(img.width, this.widthInp);
          //         console.log(img.height, this.heightInp);
          //         if (img.width != this.widthInp) {
          //           // ความกว้าง
          //           this.msgError = "229";
          //           this.removeImage()
          //         }else if(img.height != this.heightInp){
          //           // ความสูง
          //           this.msgError = "229";
          //           this.removeImage()
          //         }
          //         // width = 159px
          //         // height = 208px
          //       };

          //       reader.readAsDataURL(file);
          //     }
          // }
        }
      } else {
        if (!file.length) return;
        if (
          file[0].type.indexOf("image/jpeg") != 0 ||
          file[0].type.indexOf("image/png") != 0
        ) {
          this.msgError = "230";
        }

        if (file[0].size > this.maxSize) {
          // this.msgError = this.$t("220", { filesize: this.maxSize / 1000000 });
          this.msgError = "220";
        }

        if (
          (file[0].type.indexOf("image/jpeg") == 0 ||
            file[0].type.indexOf("image/png") == 0) &&
          file[0].size < this.maxSize
        ) {
          console.log(file[0]);
          this.alertMsgStatus = false;
          this.msgError = "";
          this.createImage(file[0]);
          // console.log(file[0]);
        }
      }
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;
      this.$emit("input", file);
      this.$emit("imgUrl", file);

      reader.onload = (e) => {
        vm.image = e.target.result;
        // const base64Data = reader.result.split(',')[1];

        // vm.$emit("base64", base64Data);
      };

      reader.readAsDataURL(file);
      this.convertBase64(file);
    },
    removeImage: function (e) {
      this.image = "";
      this.$emit("input", null);
      console.log(e);
    },
    convertBase64(file) {
      var reader = new FileReader();
      new Promise((resolve, reject) => {
        reader.readAsBinaryString(file);
        reader.onload = function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData);
          resolve(base64String);
        };
        reader.onerror = function () {
          reject("reader.onerror");
        };
      }).then((resolve) => {
        this.$emit("base64", { base64: resolve, filename: file.name });
        // console.log(resolve);
      });
    },
  },
};
</script>

<style lang="scss">
.image-show {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 4px;
  }
  .close {
    position: absolute;
    top: 50px;
    right: 10%;
  }
}
.overlay-action {
  background: rgba(142, 142, 142, 0.65);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 150ms;
}
.dropFile {
  width: 86px !important;
  height: 86px !important;
  border: dashed 1px $black-500 !important;
  border-radius: 4px !important;
  overflow: hidden;
  background: $white;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover {
    border-color: $primary !important;
    .overlay-action {
      opacity: 1;
      visibility: visible;
    }
  }
  &:active {
    background-color: $black-200;
    border-color: $primary !important;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
  }
  .iconUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    svg {
      font-size: 2rem;
    }
    span {
      font-size: 14px;
    }
  }
  .resize {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    svg {
      font-size: 18px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.drop-over {
  background-color: #cacaca !important;
  transition: 0.3s;
}
.drop-leave {
  background-color: #fff;
  transition: 0.3s;
}
.sizeLarge {
  width: 500px !important;
  height: 500px !important;
}
.sizeLarge-edit {
  width: 403px !important;
  height: 422px !important;
}
.sizeMedium {
  width: 99px !important;
  height: 114px !important;
}
.sizeSmall {
  max-width: 60px !important;
  max-height: 60px !important;
  border-radius: 2.5px !important;
  border: dashed 1px $black-500 !important;
  svg {
    width: 16px;
    height: 16px;
  }
  button {
    padding: 0 !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.sizeSmall-edit {
  max-width: 50px !important;
  max-height: 50px !important;
  border-radius: 2.5px !important;
  border: dashed 1px $black-500 !important;
  svg {
    width: 16px;
    height: 16px;
  }
  button {
    padding: 0 !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.label-vw {
  font-size: 0.9vw !important;
}
.fit-100 {
  width: 100% !important;
  height: 100% !important;
}
.drop-zone > div {
  width: 100%;
}
.notclick {
  pointer-events: none;
}
</style>

<template>
  <div>
    <div class="d-flex w-100 mb-3">
      <inputText
        ref="inpText"
        :label="label"
        :name="label"
        :labelSub="labelSub"
        :validate="Validate"
        :value="strValue"
        :alertMsg="alertMsgInput"
        :request="request"
        :showTxtInp="showTxtInp"
        @input="TextValue($event)"
        class="w-100"
      />
      <div
        class="d-flex align-items-start mt-1"
        :class="strValue == '' || strValue == undefined ? 'notclick' : ''"
      >
        <Button
          icon="AddFill"
          :class="alertMsgStatus == true ? 'mb-4' : ''"
          :classBtnIcon="
            strValue == '' || strValue == undefined || showTxtInp == true
              ? 'fill-black-500 notclick'
              : 'fill-secondary'
          "
          @input="Addlist()"
        />
      </div>
    </div>
    <div class="d-flex mb-3" v-for="(item, index) in listData" :key="index">
      <inputText
        :value="item"
        :validate="Validate"
        :label="label"
        :request="request"
        :labelSub="labelSub"
        :showTxtInp="showTxtInp"
        class="w-100"
      />
      <Button
        icon="RemoveFill"
        :class="showTxtInp == true ? 'pe-none' : ''"
        :classBtnIcon="
          showTxtInp == true
            ? 'fill-black-500 notclick pe-none'
            : 'fill-secondary'
        "
        @input="Removelist(index)"
      />
    </div>
  </div>
</template>

<script>
import inputText from "@/components/input/inputText.vue";
import Button from "@/components/button/button.vue";
export default {
  components: {
    inputText,
    Button,
  },
  data() {
    return {
      strValue: "",
      listData: [],
      alertMsgStatus: false,
      alertMsgInput: "202",
    };
  },
  props: {
    value: { type: String },
    ListData: { type: Array },
    Validate: { type: String },
    name: { type: String, default: "msg" },
    label: { type: String },
    labelSub: { type: String },
    request: { type: Boolean, default: false },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.strValue = this.value;
      if (this.ListData != undefined || this.ListData != null) {
        if (typeof this.ListData == "string") {
          this.listData.push(this.ListData);
        } else if (typeof this.ListData != "string") {
          this.listData = this.ListData.filter((e) => {
            return e != "";
          });
        }
      }
    }, 1000);
  },
  methods: {
    TextValue(e) {
      if (this.$refs.inpText.alertMsgStatus == false) {
        this.strValue = e.target.value;
        this.alertMsgStatus = false;
      }
    },
    Addlist() {
      let canPush = true;
      if (
        this.listData.length == 0 &&
        this.$refs.inpText.alertMsgStatus == false
      ) {
        this.listData.push(this.strValue);
        this.strValue = "";
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          const element = this.listData[i];
          if (this.strValue == element) {
            // Duplicate
            canPush = false;
            this.alertMsgStatus = true;
            setTimeout(() => {
              this.$refs.inpText.alertMsgStatus = true;
              this.$refs.inpText.alertMsgInput = this.$t("904", {
                fieldname: this.$t(this.label),
              });
            }, 100);
          }
        }
        if (canPush == true) {
          if (
            this.strValue != "" &&
            this.$refs.inpText.alertMsgStatus == false
          ) {
            this.listData.push(this.strValue);
            this.strValue = "";
          }
        }
      }

      this.$emit("Value", this.listData);
    },
    Removelist(index) {
      this.listData.splice(index, 1);
      this.$refs.inpText.alertMsgStatus = false;
      this.alertMsgStatus = false;
      if (this.listData.length == 0) {
        this.$emit("Value", null);
      } else {
        this.$emit("Value", this.listData);
      }
    },
    updateAlert() {
      // this.$refs.inpText.alertMsgStatus = this.alertMsgStatus;
    },
    Valid() {
      if (this.listData.length == 0) {
        this.$refs.inpText.Valid();
      }
    },
    alertRequest() {
      if (this.listData.length == 0) {
        // this.$refs.inpText.alertRequest();
        this.$refs.inpText.alertMsgInput = this.$t("202", {
          fieldname: this.$t(this.label).toLowerCase(),
        });
        this.$refs.inpText.alertMsgStatus = true;
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.notclick {
  pointer-events: none;
}
</style>
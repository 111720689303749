<template>
  <AltStatus
    :blnalert="blnAltunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t(strSMSGunsucess)"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltunsucess ? 'show' : ''"
  />
  <div class="">
    <div
      v-for="(group, groupindex) in data.fieldsGroup"
      :key="groupindex"
      class="w-100 mb-3"
    >
      <div
        v-if="!blnLoad && !blnLoading"
        :class="
          group.fieldGroupName == '949'
            ? device == 'mobile'
              ? 'fnt-medium fnt-subtitle text-dark mb-2'
              : 'fnt-medium fnt-subtitle text-dark mb-3'
            : device == 'mobile'
            ? 'fnt-medium fnt-subtitle text-dark mb-2'
            : 'fnt-medium fnt-subtitle text-dark mb-3'
        "
      >
        {{ $t(group.fieldGroupName)
        }}{{
          group.fieldGroupName == 920
            ? " (" +
              $t("374") +
              ") " +
              (intUserTypeID == 3 || intUserTypeID == 4 ? "" : "*")
            : ""
        }}{{ group.fieldGroupName == "917" ? " *" : "" }}
      </div>
      <!-- {{ Action }} -->
      <!-- {{ userData[groupindex] }} -->

      <div :class="group.fieldGroupName == '949' && !blnDisable ? '' : ''">
        <div
          class="w-100 d-flex justify-content-end"
          v-if="group.blnCanAdd && !blnDisable"
        >
          <Button
            icon="AddOutline"
            :class="blnPreview == true ? 'pe-none' : ''"
            :classBtnIcon="
              blnPreview == true
                ? 'fill-black-500'
                : blnEducationFilled
                ? 'fill-secondary '
                : 'fill-black-500'
            "
            :name="$t(group.fieldGroupName == '949' ? '952' : '1003')"
            :className="
              blnPreview == true
                ? 'text-black-500'
                : blnEducationFilled
                ? 'text-secondary'
                : 'text-black-500'
            "
            @click="blnEducationFilled ? addInnerGroup(groupindex) : null"
          />
        </div>
        <div
          v-for="(innerGroup, innerGroupindex) in group.fieldList"
          :key="innerGroupindex"
        >
          <div
            class="row"
            v-for="(row, rowindex) in innerGroup"
            :key="rowindex"
          >
            <div
              :class="
                item &&
                item.strFieldName &&
                (item.strFieldName.toLowerCase().includes('prefix') ||
                  item.strFieldName.toLowerCase().includes('middlename'))
                  ? device == 'mobile'
                    ? 'col-sm-12 col-md-2 mb-3'
                    : 'col-sm-12 col-md-2 mb-3 gx-3 '
                  : device == 'mobile'
                  ? 'col-sm-12 col-md mb-3'
                  : 'col-sm-12 col-md mb-3 gx-3 '
              "
              v-for="(item, index) in row"
              :key="index"
            >
              <!-- {{ item.strID }} -->
              <!-- {{ innerGroupindex }} -->
              <!-- {{ listControlFields[item.strID] }} -->
              <!-- {{ item.strRelation }} -->
              <!-- {{ item.listDestination }} -->
              <!-- {{ item.strLabelEN }} -->
              <!-- {{
                userData[groupindex]?.[innerGroupindex]?.[item.strID]?.userInfo
                  ?.data
              }} -->
              <!-- {{ listControlFields }} -->
              <Checkbox
                :strId="groupindex"
                v-if="!blnDisable && item.intDisplayType == 5"
                :checkboxLabel="labelCheckbox(item, groupindex)"
                :checked="
                  valueCheckBox(
                    userData[groupindex]?.[innerGroupindex]?.[item.strID]
                      .userInfo?.data
                  )
                "
                :blnLookup="true"
                fontSize="fnt-body"
                :canChange="
                  blnPreview == true
                    ? true
                    : listBlnGroupFilled[item.listSource]
                "
                @select="
                  onSelectRef(
                    item.listSource,
                    item.listDestination,
                    groupindex,
                    $event,
                    item.strID,
                    innerGroupindex
                  ),
                    updateUserdata(
                      groupindex,
                      innerGroupindex,
                      item.strID,
                      $event == true
                        ? JSON.stringify({
                            value: {
                              id: null,
                              lang: { langEN: '1', langTH: '1' },
                            },
                          })
                        : JSON.stringify({
                            value: {
                              id: null,
                              lang: { langEN: '0', langTH: '0' },
                            },
                          })
                    )
                "
                :ref="'checkbox' + groupindex"
              />
              <!-- <div
                v-else-if="
                  item.strLabelTH == null ||
                  blnLoad ||
                  blnLoading ||
                  (item.intFieldType == 1 &&
                    (this.listControlFields[item.strID] == undefined ||
                      !Array.isArray(this.listControlFields[item.strID])))
                "
              >
                <div
                  v-if="item.strID != null"
                  class="rounded-2 skeleton-item bg-black-200"
                  style="height: 48px"
                ></div>
              </div> -->

              <div v-else>
                <inputComponentType
                  v-if="
                    blnDisable == true &&
                    item.strID != undefined &&
                    item.strLabelTH != null
                  "
                  :intFieldtype="item.intFieldType"
                  :label="item.strFieldName"
                />
                <div
                  v-else
                  :class="
                    item.strID == 1 || item.strID == 145 || item.strID == 205
                      ? 'd-flex'
                      : ''
                  "
                >
                  <Toggle
                    v-if="item.strID == 222"
                    :open="$t('89')"
                    :close="$t('90')"
                    @input="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        $event.value == true ? '1' : '0'
                      )
                    "
                    :value="
                      userData[groupindex]?.[innerGroupindex]?.[item.strID]
                        ?.userInfo.data
                    "
                    :ref="item.strID.toString()"
                    :disabled="blnPreview == true ? true : false"
                    :blnUserinfo="true"
                  />
                  <InputAddlist
                    v-else-if="
                      (item.strID == 24 || item.strID == 227) &&
                      item.strLabelTH != null &&
                      item.strID != null
                    "
                    :label="
                      this.$i18n.locale == 'th'
                        ? item.strLabelTH
                        : item.strLabelEN
                    "
                    labelSub=": name@gmail.com"
                    :request="true"
                    style="margin-right: -50px"
                    :class="device == 'mobile' ? 'w-100' : ''"
                    Validate="email"
                    :ref="item.strID.toString()"
                    @Value="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        JSON.stringify($event)
                      )
                    "
                    :ListData="
                      userData[groupindex][innerGroupindex][item.strID]
                        ?.userInfo?.data != '' &&
                      userData[groupindex][innerGroupindex][item.strID]
                        ?.userInfo?.data != undefined // []
                        ? JSON.parse(
                            // JSON.stringify(
                            userData[groupindex][innerGroupindex][item.strID]
                              ?.userInfo?.data
                            // )
                          )
                        : []
                    "
                    :showTxtInp="blnPreview"
                  />
                  <InputDigitcode
                    v-else-if="item.strID == 2"
                    :digitbox="13"
                    type="tel"
                    :showHypens="[0, 4, 9, 11]"
                    size="d-flex justify-content-start"
                    :ref="item.strID.toString()"
                    @input="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        $event
                      )
                    "
                    :value="
                      userData[groupindex][innerGroupindex][item.strID]
                        ?.userInfo.data
                    "
                    :showTxtInp="
                      blnPreview == true
                        ? true
                        : Action == 'Insert'
                        ? blnDisableAll
                        : blnPreview
                    "
                  />
                  <!--  -->
                  <InputFixImage
                    v-else-if="
                      item.strID == 19 || item.strID == 163 || item.strID == 216
                    "
                    :alertMsg="'202'"
                    :value="
                      item.strID == 19 || item.strID == 163
                        ? $i18n.locale == 'th'
                          ? require('@/assets/img/Student(TH).png')
                          : require('@/assets/img/Student(EN).png')
                        : $i18n.locale == 'th'
                        ? require('@/assets/img/Techer(TH).png')
                        : require('@/assets/img/Techer(EN).png')
                    "
                    :valueInput="
                      userData[groupindex][innerGroupindex][item.strID]
                        ?.userInfo.strDataImage !=
                      'https://cesfile.wu.ac.th/files'
                        ? userData[groupindex][innerGroupindex][item.strID]
                            ?.userInfo.strDataImage
                        : null
                    "
                    :size="device == 'mobile' ? 'full-size' : 'sizeLarge-edit'"
                    :filetype="'image/jpeg'"
                    @input="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        $event
                      )
                    "
                    :blnBase64="true"
                    :widthInp="159"
                    :heightInp="208"
                    :canClick="blnPreview"
                    :showTxtInp="
                      blnPreview == true
                        ? true
                        : Action == 'Insert'
                        ? blnDisableAll
                        : false
                    "
                    :ref="item.strID.toString()"
                  />

                  <!-- <div v-if="item.intDisplayType == 6"> -->
                  <div
                    v-else-if="item.intDisplayType == 6"
                    class="mt-2"
                    :id="item.strID"
                  >
                    <div class="fnt-medium fnt-subtitle mb-2">
                      {{
                        this.$i18n.locale == "th" &&
                        item.strID !== 233 &&
                        item.strID !== 234
                          ? item.strLabelTH
                          : this.$i18n.locale == "en" &&
                            item.strID !== 233 &&
                            item.strID !== 234
                          ? item.strLabelEN
                          : ""
                      }}
                      {{ item.intNotnull == 1 ? "*" : "" }}
                    </div>
                    <div class="d-flex">
                      <div
                        class="me-4 fnt-body"
                        v-for="(lookupValue, index) in JSON.parse(
                          item.strLookupValue
                        )"
                        :key="index"
                      >
                        <!-- {{ listBlnGroupFilled[item.listSource[index]] }} -->
                        <Radio
                          :canChange="
                            blnPreview == true
                              ? true
                              : item.listSource?.[index] != undefined
                              ? listBlnGroupFilled?.[item.listSource?.[index]]
                              : false
                          "
                          :name="item.strFieldName + lookupValue.nameTH"
                          :labelname="
                            this.$i18n.locale == 'th'
                              ? lookupValue.nameTH
                              : lookupValue.nameEN
                          "
                          :inputname="item.strFieldName"
                          :usechecked="false"
                          :value="
                            userData[groupindex]?.[innerGroupindex]?.[
                              item.strID
                            ].userInfo?.data == '' ||
                            userData[groupindex]?.[innerGroupindex]?.[
                              item.strID
                            ].userInfo?.data == undefined
                              ? false
                              : JSON.parse(
                                  userData[groupindex]?.[innerGroupindex]?.[
                                    item.strID
                                  ].userInfo?.data
                                )?.nameEN == lookupValue.nameEN
                          "
                          @change="
                            updateUserdata(
                              groupindex,
                              innerGroupindex,
                              item.strID,
                              JSON.stringify(lookupValue)
                            ),
                              onSelectRef(
                                item.listSource,
                                item.listDestination,
                                groupindex,
                                index,
                                item.strID,
                                innerGroupindex
                              )
                          "
                          :ref="'radio' + item.strID.toString() + index"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular d-none"
                      :ref="item.strID.toString()"
                    >
                      <Icon icon="carbon:warning" class="me-1" />
                      {{ $t("284") }}
                    </div>
                  </div>
                  <InputCalendaSelecDateLength
                    v-else-if="item.strDatatype == '4'"
                    :label="
                      this.$i18n.locale == 'th'
                        ? item.strLabelTH
                        : item.strLabelEN
                    "
                    :request="item.intNotnull == 1"
                    :alertMsg="'202'"
                    :showSeconTime="false"
                    :showFirstTime="false"
                    :showTxtInp="
                      blnPreview == true
                        ? true
                        : Action == 'Update_special'
                        ? false
                        : Action == 'Insert'
                        ? blnDisableAll
                        : item.strID == 46
                        ? true
                        : false
                    "
                    :blnRemove="item.strID == 221 ? true : false"
                    :numberYearPrev="item.strID == 11 ? 17 : ''"
                    :secondDisable="
                      item.strID == 11
                        ? '31/11/' + (new Date().getFullYear() + 526).toString()
                        : ''
                    "
                    :ref="item.strID.toString()"
                    @input="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        $event
                      )
                    "
                    :valueCalenda="
                      userData[groupindex]?.[innerGroupindex]?.[item.strID]
                        ?.userInfo.data
                    "
                  />
                  <Select
                    v-model="userData"
                    v-else-if="item.intFieldType == 4 || item.intFieldType == 1"
                    :alertMsg="'202'"
                    :strName="
                      this.$i18n.locale == 'th'
                        ? item.strLabelTH
                        : item.strLabelEN
                    "
                    :strGroupID="innerGroupindex?.toString()"
                    :List="true"
                    :Tree="false"
                    :blnAddress="true"
                    :blnChangelang="
                      // item.strRelation == 'ads_country' ||
                      // item.strRelation == 'ads_province' ||
                      // item.strRelation == 'ads_district' ||
                      // item.strRelation == 'ads_subdistrict' ||
                      // item.strRelation == 'ads_postcode' ||
                      // item.strRelation == 'ads_educationlevel' ||
                      // item.strRelation == 'ads_academicorg' ||
                      // item.strRelation == 'ads_campus'
                      item.strRelation != 'ads_prefix' ? true : false
                    "
                    :blnSystemword="false"
                    :DropdownItem="
                      item.intFieldType == 1
                        ? this.listControlFields[item.strID]
                        : sortOption(
                            false,
                            item.strLookupValue == ''
                              ? []
                              : JSON.parse(item.strLookupValue),
                            item.strID
                          )
                    "
                    :selectedValue="
                      userData[groupindex]?.[innerGroupindex]?.[item.strID]
                        ?.userInfo?.data != undefined &&
                      userData[groupindex]?.[innerGroupindex]?.[item.strID]
                        ?.userInfo?.data != ''
                        ? item.intFieldType == 1 //&& // item.strRelation != 'ads_country'
                          ? userData[groupindex]?.[innerGroupindex]?.[
                              item.strID
                            ]?.userInfo?.data != undefined
                            ? userData[groupindex]?.[innerGroupindex]?.[
                                item.strID
                              ]?.userInfo?.data
                            : ''
                          : ''
                        : ''
                    "
                    :selectedLookup="
                      userData[groupindex]?.[innerGroupindex]?.[item.strID]
                        ?.userInfo?.data != undefined &&
                      userData[groupindex]?.[innerGroupindex]?.[item.strID]
                        ?.userInfo?.data != ''
                        ? item.intFieldType != 1
                          ? JSON.parse(
                              userData[groupindex]?.[innerGroupindex]?.[
                                item.strID
                              ]?.userInfo?.data
                            ).value
                          : ''
                        : ''
                    "
                    :star="item.intNotnull == 1 ? ' *' : ''"
                    :style="item"
                    :blnselected="item.intFieldType == 1"
                    :default-lang="
                      item.strFieldName.includes('EN')
                        ? 'en'
                        : item.strFieldName.includes('TH')
                        ? 'th'
                        : ''
                    "
                    :showTxtInp="
                      blnPreview == true
                        ? true
                        : Action == 'Update_special'
                        ? blnPreview == true
                          ? true
                          : item.strFieldName?.includes('Prefix')
                          ? listBlnDisabledGroup[groupindex] == true
                            ? true
                            : item.strFieldName.includes('EN') &&
                              item.strID != 182 &&
                              item.strID != 196 &&
                              ($i18n.locale == 'th' || $i18n.locale == 'TH')
                            ? true
                            : item.strFieldName.includes('TH') &&
                              item.strID != 182 &&
                              item.strID != 196 &&
                              ($i18n.locale == 'en' || $i18n.locale == 'EN')
                          : listBlnDisabledGroup[groupindex] ||
                            enableCheck(
                              item.strID,
                              innerGroupindex,
                              groupindex,
                              item.strRelation
                              // item.strFieldName
                            )
                        : Action == 'Insert'
                        ? blnDisableAll == true
                          ? true
                          : item.strFieldName?.includes('Prefix')
                          ? item.strFieldName.includes('EN') &&
                            item.strID != 182 &&
                            item.strID != 196 &&
                            ($i18n.locale == 'th' || $i18n.locale == 'TH')
                            ? true
                            : item.strFieldName.includes('TH') &&
                              item.strID != 182 &&
                              item.strID != 196 &&
                              ($i18n.locale == 'en' || $i18n.locale == 'EN')
                          : listBlnDisabledGroup[groupindex] ||
                            enableCheck(
                              //reletion
                              item.strID,
                              innerGroupindex,
                              groupindex,
                              item.strRelation
                              // item.strFieldName
                            ) ||
                            item.strID == 166 ||
                            item.strID == 189
                        : (item.strID >= 39 && item.strID <= 44) ||
                          item.strID == 166 ||
                          item.strID == 189
                        ? true
                        : item.strFieldName.includes('EN') &&
                          item.strID != 182 &&
                          item.strID != 196 &&
                          ($i18n.locale == 'th' || $i18n.locale == 'TH')
                        ? true
                        : item.strFieldName.includes('TH') &&
                          item.strID != 182 &&
                          item.strID != 196 &&
                          ($i18n.locale == 'en' || $i18n.locale == 'EN')
                        ? true
                        : blnPreview ||
                          listBlnDisabledGroup[groupindex] ||
                          enableCheck(
                            item.strID,
                            innerGroupindex,
                            groupindex,
                            item.strRelation
                            // item.strFieldName
                          )
                    "
                    @input="
                      onSelectDropdown(
                        $event,
                        item.intFieldType == 1,
                        item,
                        groupindex,
                        innerGroupindex,
                        rowindex,
                        index
                      )
                    "
                    @click="onClickDropdown(item.strID)"
                    :ref="item.strID.toString()"
                  />
                  <InputNumber
                    v-else-if="
                      item.strDatatype == '2' &&
                      !(item.intFieldType == 5 || item.intFieldType == 6)
                    "
                    :label="
                      this.$i18n.locale == 'th'
                        ? item.strLabelTH
                        : item.strLabelEN
                    "
                    :require="item.intNotnull == 1"
                    :alertMsg="'202'"
                    :ref="item.strID.toString()"
                    @input="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        $event.target.value
                      )
                    "
                    :value="
                      userData[groupindex][innerGroupindex]?.[item.strID]
                        ?.userInfo.data
                    "
                    :showTxtInp="!blnPreview"
                  />
                  <InputLongText
                    :value="
                      userData[groupindex][innerGroupindex]?.[item.strID]
                        ?.userInfo.data ?? ''
                    "
                    :label="
                      this.$i18n.locale == 'th'
                        ? item.strLabelTH
                        : item.strLabelEN
                    "
                    v-else-if="item.strID == 181 || item.strID == 204"
                    :name="item.strFieldName"
                    :alertMsg="'202'"
                    :validate="''"
                    :maxlength="200"
                    :require="'*'"
                    :showTxtInp="blnPreview"
                    @input="
                      updateUserdata(
                        groupindex,
                        innerGroupindex,
                        item.strID,
                        $event.target.value
                      )
                    "
                    :ref="item.strID.toString()"
                  />
                  <div
                    v-else-if="
                      (item.strLabelTH != null && item.strID != null) ||
                      item.intFieldType == 6
                    "
                    :class="
                      blncheckMemberComplete == true &&
                      (item.strID == 1 ||
                        item.strID == 145 ||
                        item.strID == 205)
                        ? 'd-flex flex-column'
                        : ''
                    "
                    class="w-100"
                  >
                    <InputText
                      :validate="
                        item.strID == 1 ||
                        item.strID == 145 ||
                        item.strID == 205
                          ? 'number'
                          : item.strID == 52
                          ? 'grade'
                          : item.strDatatype == '7'
                          ? 'email'
                          : item.strDatatype == '9'
                          ? 'phonenumber'
                          : item.strFieldName.includes('TH')
                          ? 'letterTH'
                          : item.strFieldName.includes('EN')
                          ? 'letterEN'
                          : ''
                      "
                      :request="item.intNotnull == 1 ? true : false"
                      :alertMsg="'202'"
                      :label="
                        this.$i18n.locale == 'th'
                          ? item.strLabelTH
                          : item.strLabelEN
                      "
                      :value="
                        userData[groupindex][innerGroupindex]?.[item.strID]
                          ?.userInfo?.data != undefined &&
                        userData[groupindex][innerGroupindex]?.[item.strID]
                          ?.userInfo?.data != ''
                          ? item.strID == 45
                            ? JSON.parse(
                                JSON.stringify(
                                  userData[groupindex][innerGroupindex]?.[
                                    item.strID
                                  ]?.userInfo.data
                                )
                              ).value == undefined
                              ? userData[groupindex][innerGroupindex]?.[
                                  item.strID
                                ]?.userInfo.data
                              : $i18n.locale == 'th'
                              ? JSON.parse(
                                  JSON.stringify(
                                    userData[groupindex][innerGroupindex]?.[
                                      item.strID
                                    ]?.userInfo.data
                                  )
                                ).value.lang.langTH
                              : JSON.parse(
                                  JSON.stringify(
                                    userData[groupindex][innerGroupindex]?.[
                                      item.strID
                                    ]?.userInfo.data
                                  )
                                ).value.lang.langEN
                            : userData[groupindex][innerGroupindex]?.[
                                item.strID
                              ]?.userInfo.data
                          : ''
                      "
                      :labelSub="
                        item.strID == 23 || item.strID == 226
                          ? ': name@mail.wu.ac.th'
                          : item.strID == 22 || item.strID == 225
                          ? ': https://www.facebook/ex'
                          : ''
                      "
                      :showTxtInp="
                        blnPreview == true
                          ? true
                          : Action == 'Update_special'
                          ? false ||
                            listBlnDisabledGroup[groupindex] ||
                            item.strID == 1 ||
                            item.strID == 145 ||
                            item.strID == 205
                          : listBlnDisabledGroup[groupindex]
                          ? true
                          : Action == 'Insert'
                          ? item.strID == 1 ||
                            item.strID == 145 ||
                            item.strID == 205
                            ? blncheckMemberComplete == true
                              ? true
                              : false
                            : blnDisableAll
                          : (item.intFieldType == 5 ||
                              item.intFieldType == 6) &&
                            item.strID != 52
                      "
                      @input="
                        updateUserdata(
                          groupindex,
                          innerGroupindex,
                          item.strID,
                          $event.target.value
                        )
                      "
                      :maxlength="
                        item.strDatatype == '9'
                          ? 10
                          : item.strID == 1 || item.strID == 145
                          ? 8
                          : item.strID == 205
                          ? 10
                          : ''
                      "
                      class="w-100"
                      :class="
                        item.strID == 1 ||
                        item.strID == 145 ||
                        item.strID == 205
                          ? 'pe-1'
                          : ''
                      "
                      :ref="item.strID.toString()"
                    />
                    <div
                      v-if="
                        Action == 'Insert' &&
                        blnPreview == false &&
                        (item.strID == 1 ||
                          item.strID == 145 ||
                          item.strID == 205) &&
                        blncheckMemberComplete == true
                      "
                    >
                      <CorrectFill
                        width="16"
                        height="16"
                        class="fill-success me-1"
                      />
                      <span class="text-success fnt-regular fnt-body">
                        {{
                          $i18n.locale == "th"
                            ? "ตรวจสอบสำเร็จ"
                            : "Verification successful"
                        }}
                      </span>
                    </div>
                  </div>

                  <Button
                    v-if="
                      Action == 'Insert' &&
                      blnPreview == false &&
                      (item.strID == 1 ||
                        item.strID == 145 ||
                        item.strID == 205)
                    "
                    style="height: 46px"
                    :classBtn="strClassCheckMember"
                    :className="device == 'mobile' ? 'fnt-caption' : ''"
                    :name="$i18n.locale == 'th' ? 'ตรวจสอบ' : 'Verify'"
                    @click="
                      checkMemberStatus(groupindex, innerGroupindex, item.strID)
                    "
                  />

                  <!-- : item.intFieldType == 5 || item.intFieldType == 6
                        ? this.$i18n.locale == 'th'
                          ? item.intNotnull == 1
                            ? item.strLabelTH + ' *'
                            : item.strLabelTH
                          : item.intNotnull == 1
                          ? item.strLabelEN + ' *'
                          : item.strLabelEN -->
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="group.blnCanAdd && !blnDisable"
            class="w-100 d-flex justify-content-end"
          >
            <Button
              icon="DeleteFill"
              :classBtnIcon="
                blnPreview == true
                  ? 'fill-black-500'
                  : userData[groupindex] != undefined
                  ? userData[groupindex].length > 1
                    ? 'fill-secondary'
                    : 'fill-black-500'
                  : 'fill-black-500'
              "
              :name="$t('')"
              @click="
                userData[groupindex] != undefined
                  ? userData[groupindex].length > 1
                    ? removeGroup(groupindex, innerGroupindex)
                    : null
                  : null
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "../button/button.vue";
import InputText from "./inputText.vue";
import inputComponentType from "./inputComponentType.vue";
import urlapi from "@/typescript/urlapi/url";
import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import Checkbox from "./checkbox.vue";
import InputNumber from "./inputNumber.vue";
// import InputDateCalendar from "./inputDateCalendar.vue";
import Select from "./dropdown/select.vue";
import InputDigitcode from "./InputDigitcode.vue";
import InputFixImage from "./inputFixImage.vue";
import InputAddlist from "./inputAddlist.vue";
import Radio from "./radio.vue";
import InputLongText from "./inputLongText.vue";
import Toggle from "./toggle/toggleForm.vue";
import InputCalendaSelecDateLength from "./inputCalendaSelecDateLength.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import customfilter from "@/typescript/filter";
import { Icon } from "@iconify/vue";
import { CorrectFill } from "@/components/icon/index";

export default {
  props: {
    formData: {
      type: Object,
      required: true,
      validate() {
        return {
          strFormName: "",
          listFieldsGroup: [
            {
              strFieldGroupName: "",
              listUserfield: [
                [
                  {
                    strID: "",
                  },
                ],
              ],
            },
          ],
        };
      },
    },
    userInfo: { type: Object },
    blnDisable: { type: Boolean, default: false },
    blnPreview: { type: Boolean, default: false },
    blnLoading: { type: Boolean, default: false },
    blnAddData: { type: Boolean, default: false },
    indexMenu: { type: String, default: "0" },
    Action: { type: String },
    strMenuID: { type: String },
    intUserTypeID: { type: Number },
  },
  data() {
    return {
      lookupdata:
        '{"value":{"lang":{"langTH":"ไม่ระบุ","langEN":"Not specified"},"confirm":"false"}}',
      // "\"{\\\"value\\\":{\\\"lang\\\":{\\\"langTH\\\":\\\"ไทย\\\",\\\"langEN\\\":\\\"Thai\\\"},\\\"confirm\\\":false}}\"",      id: "",
      locales: null,
      blnLoad: true,
      data: {},
      listControlFields: [],
      listDropdownID: [],
      blnEducationFilled: false,
      userData: [],
      listBlnDisabledGroup: [],
      listBlnGroupFilled: [],
      listintEmptyField: [],
      listDisableCheckbox: [],
      longTextMother: {},
      longTextFather: {},
      blnAltunsucess: false,
      strSMSGunsucess: "",
      blnDisableAll: true,
      strClassCheckMember:
        "btn-fix bg-black-500 disabled text-white pe-none p-0",
      blncheckMemberComplete: false,
    };
  },
  emits: ["userInfoChange"],
  watch: {
    // form เปลี่ยน
    // จัด userData model ใหม่
    // map
    formData: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log(newValue);
        // console.log(oldValue);
        if (newValue != oldValue) {
          // console.log("form Change", this.userInfo);
          this.blnLoad = true;
          this.data = JSON.parse(JSON.stringify(this.formData));
          this.userData = [];
          this.listBlnDisabledGroup = [];
          this.listBlnGroupFilled = [];
          for (let i = 0; i < this.data.fieldsGroup?.length; i++) {
            this.listBlnDisabledGroup.push(false);
            this.listBlnGroupFilled.push(false);
            this.userData[i] = [];
            this.addInnerGroup(i);
            // console.log("in loop this.data.fieldsGroup");
          }
          // console.log(this.userData);
          let userinfo = JSON.parse(JSON.stringify(this.userData));
          // console.log("loop this.userData");
          if (this.userInfo != []) {
            this.userData?.forEach((group, intGroup) => {
              group.forEach((innerGroup, intInnerGroup) => {
                // console.log(this.userInfo);
                for (let field in innerGroup) {
                  if (field !== undefined) {
                    // console.log(this.userInfo);
                    this.userInfo?.forEach((userInfoData) => {
                      this.$nextTick(() => {
                        if (
                          userInfoData.intUserFieldID === field &&
                          userInfoData !== undefined
                        ) {
                          userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                            userInfoData;
                          //console.log(userinfo);
                        }
                      });
                    });
                  }
                }
              });
            });
          }
          this.userData = userinfo;

          // console.log(this.userData);
        }
        this.blnLoad = false;
      },
    },
    userInfo: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log(newValue);
        // console.log(oldValue);
        // this.data = JSON.parse(JSON.stringify(this.formData));
        // this.getControlFieldData(this.data);
        if (newValue != oldValue) {
          this.blnLoad = true;
          this.data = JSON.parse(JSON.stringify(this.formData));
          this.userData = [];
          // this.getControlFieldData(this.data);
          this.listBlnDisabledGroup = [];
          this.listBlnGroupFilled = [];
          for (let i = 0; i < this.data.fieldsGroup?.length; i++) {
            this.listBlnDisabledGroup.push(false);
            this.listBlnGroupFilled.push(false);
            this.userData[i] = [];
            this.addInnerGroup(i);
          }
          let userinfo = JSON.parse(JSON.stringify(this.userData));
          if (newValue != []) {
            this.userData?.forEach((group, intGroup) => {
              group.forEach((innerGroup, intInnerGroup) => {
                // console.log(this.userInfo);
                for (let field in innerGroup) {
                  if (field != undefined) {
                    newValue?.forEach((userInfoData) => {
                      // console.log(userInfoData.intUserFieldID, field);
                      if (userInfoData?.intUserFieldID.toString() == field) {
                        // console.log(userInfoData);
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          userInfoData;
                      }
                    });
                  }
                }
              });
            });
          }
          this.userData = userinfo;

          // console.log(this.userData);
        }
        this.blnLoad = false;
      },
    },
    // เปลี่ยนภาษาแล้ว loading
    locales() {
      this.blnLoad = true;
      for (
        let intGroupIndex = 0;
        intGroupIndex < this.userData.length;
        intGroupIndex++
      ) {
        for (
          let intInnerGroupIndex = 0;
          intInnerGroupIndex < this.userData[intGroupIndex].length;
          intInnerGroupIndex++
        ) {
          for (const field in this.userData[intGroupIndex][
            intInnerGroupIndex
          ]) {
            if (this.userInfo !== undefined) {
              // Update the condition to use strict inequality operator
              this.userInfo.forEach((userinfo) => {
                if (userinfo.intUserFieldID === field) {
                  // Update the comparison operator to use strict equality
                  this.userData[intGroupIndex][intInnerGroupIndex][field] =
                    userinfo;
                }
              });
            }
          }
        }
      }
      this.blnLoad = false;
    },
    // userData: {
    //   deep: true,
    //   handler() {
    //     console.log("userData Change");
    //     if (!this.blnLoad) {
    //       this.data.fieldsGroup.forEach((group, intGroupIndex) => {
    //         group.fieldList.forEach((innerGroupindex) => {
    //           innerGroupindex.forEach((row) => {
    //             console.log(row);
    //             row.forEach((field) => {
    //               if (field.intDataGroupIndex != undefined) {
    //                 this.listBlnGroupFilled[intGroupIndex] =
    //                   this.checkFilledAll(field.intDataGroupIndex);
    //               }
    //             });
    //           });
    //         });
    //         if (this.$refs["checkbox" + intGroupIndex]?.[0]?.check) {
    //           this.$refs["checkbox" + intGroupIndex]?.[0]?.onSelect();
    //         }
    //         if (this.$refs["checkbox" + intGroupIndex]?.[1]?.check) {
    //           this.$refs["checkbox" + intGroupIndex]?.[1]?.onSelect();
    //         }
    //       });
    //     }
    //   },
    // },
  },
  beforeMount() {
    //เรียกข้อมูลจาก local
    this.locales = this.$i18n.locale;
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
  },
  mounted() {
    // console.log("mounted");
    this.blnLoad = true;
    //map userInfo to UserData
    this.listBlnGroupFilled = [];
    this.data = JSON.parse(JSON.stringify(this.formData));
    this.getControlFieldData(this.data);

    // console.log(this.userInfo, userinfo);
    // console.log("loop this.data.fieldsGroup");

    for (let i = 0; i < this.data.fieldsGroup?.length; i++) {
      this.listBlnDisabledGroup.push(false);
      // if (this.Action == "Insert" && i == 1) {
      //   this.listBlnGroupFilled.push(true);
      // } else {
      this.listBlnGroupFilled.push(false);
      // }

      this.userData[i] = [];
      this.addInnerGroup(i);
    }

    // console.log(this.userData);
    let userinfo = JSON.parse(JSON.stringify(this.userData));
    let userinfoRepeat = 0;
    // console.log("loop this.userData");
    if (this.Action == "Insert") {
      if (localStorage.getItem("userinfoInsert") == "true") {
        this.blnDisableAll = false;
      } else {
        this.blnDisableAll = true;
      }
    }
    if (this.userInfo != null) {
      try {
        this.userData.forEach((group, intGroup) => {
          // console.log(this.userData, group);
          group.forEach((innerGroup, intInnerGroup) => {
            // console.log(innerGroup);
            for (let field in innerGroup) {
              if (field != undefined) {
                // console.log(field);
                this.userInfo.forEach((userInfoData) => {
                  if (userInfoData.intUserFieldID == field) {
                    const grouped = {};
                    for (const item of this.userInfo) {
                      if (!grouped[item.intUserFieldID]) {
                        grouped[item.intUserFieldID] = [];
                      }
                      grouped[item.intUserFieldID].push(item);
                    }
                    // let group = Object.values(grouped).filter((e) => {
                    //   return e.length > 1;
                    // });
                    // if (group.length != 0) {
                    //   if (userInfoData.intUserFieldID == 49) {
                    //     // เพิ่ม group
                    //     userinfoRepeat++;
                    //     userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                    //       group[0][0];
                    //   } else if (userInfoData.intUserFieldID == 50) {
                    //     userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                    //       group[1][0];
                    //   } else if (userInfoData.intUserFieldID == 51) {
                    //     userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                    //       group[2][0];
                    //   } else if (userInfoData.intUserFieldID == 52) {
                    //     userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                    //       group[3][0];
                    //   }
                    // }

                    if (Object.values(grouped).length > 2) {
                      if (userInfoData.intUserFieldID == 217) {
                        // เพิ่ม group
                        userinfoRepeat++;
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          Object.values(grouped)[0][0];
                      } else if (userInfoData.intUserFieldID == 218) {
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          Object.values(grouped)[1][0];
                      } else if (userInfoData.intUserFieldID == 219) {
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          Object.values(grouped)[2][0];
                      } else if (userInfoData.intUserFieldID == 220) {
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          Object.values(grouped)[3][0];
                      } else if (userInfoData.intUserFieldID == 221) {
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          Object.values(grouped)[4][0];
                      } else if (userInfoData.intUserFieldID == 222) {
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          Object.values(grouped)[5][0];
                      }
                      if (userInfoData.intUserFieldID == 49) {
                        if (Object.values(grouped).length > 1) {
                          // console.log(Object.values(grouped))
                        }
                        // เพิ่ม group
                        // userinfoRepeat++;
                        // userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                        //   Object.values(grouped)[0][0];
                      }
                      // else if (userInfoData.intUserFieldID == 50) {
                      //   userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                      //     Object.values(grouped)[1][0];
                      // } else if (userInfoData.intUserFieldID == 51) {
                      //   userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                      //     Object.values(grouped)[2][0];
                      // } else if (userInfoData.intUserFieldID == 52) {
                      //   userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                      //     Object.values(grouped)[3][0];
                      // }
                      if (
                        userInfoData.intUserFieldID != 217 &&
                        userInfoData.intUserFieldID != 218 &&
                        userInfoData.intUserFieldID != 219 &&
                        userInfoData.intUserFieldID != 220 &&
                        userInfoData.intUserFieldID != 221 &&
                        userInfoData.intUserFieldID != 222
                        // &&
                        // userInfoData.intUserFieldID != 49 &&
                        // userInfoData.intUserFieldID != 50 &&
                        // userInfoData.intUserFieldID != 51 &&
                        // userInfoData.intUserFieldID != 52
                      ) {
                        // console.log(userInfoData,intGroup)
                        userinfo[intGroup][intInnerGroup][field]["userInfo"] =
                          userInfoData;
                      }
                    } else {
                      Object.values(grouped).forEach((groupedID) => {
                        if (
                          groupedID[0].intUserFieldID ==
                          userInfoData.intUserFieldID
                        ) {
                          // console.log(groupedID[0]);
                          if (userInfoData.intUserFieldID == 217) {
                            // เพิ่ม group
                            userinfoRepeat++;
                            userinfo[intGroup][intInnerGroup][field][
                              "userInfo"
                            ] = groupedID[0];
                          } else if (
                            userInfoData.intUserFieldID == 218 ||
                            userInfoData.intUserFieldID == 219 ||
                            userInfoData.intUserFieldID == 220 ||
                            userInfoData.intUserFieldID == 221 ||
                            userInfoData.intUserFieldID == 222
                          ) {
                            userinfo[intGroup][intInnerGroup][field][
                              "userInfo"
                            ] = groupedID[0];
                          } else {
                            userinfo[intGroup][intInnerGroup][field][
                              "userInfo"
                            ] = userInfoData;
                          }
                        }
                      });
                    }
                  }
                  if (
                    userInfoData.intUserFieldID == 1 ||
                    userInfoData.intUserFieldID == 145 ||
                    userInfoData.intUserFieldID == 205
                  ) {
                    this.$emit("keywordfromUpdate", userInfoData.data);
                  }
                });
              }
            }
          });
        });
        this.data.fieldsGroup.forEach((group, gIndex) => {
          group.fieldList.forEach((innerGroup, iIndex) => {
            innerGroup.forEach((row) => {
              row.forEach((field) => {
                if (
                  field.intDisplayType == 6 &&
                  userinfo[gIndex][iIndex][field.strID]["userInfo"] != 0
                ) {
                  // console.log(gIndex);
                  this.listBlnDisabledGroup[gIndex] = true;
                }
              });
            });
          });
        });
      } catch (error) {
        console.log(error);
        this.strSMSGunsucess = "223";
        this.blnAltunsucess = true;
        setTimeout(() => {
          this.blnAltunsucess = false;
          // window.location.reload();
        }, 2000);
      }
    }

    this.blnLoad = false;
    this.userData = userinfo;

    // Add Group from Data API
    try {
      // console.log(userinfoRepeat);
      this.userData.forEach((_, intGroup) => {
        for (let i = 0; i < userinfoRepeat - 1; i++) {
          if (userinfoRepeat > 1) {
            if (this.userData[intGroup][0] != null) {
              this.rendererAddGroup(intGroup);
              // console.log(intGroup);
              let obj = {};
              this.data.fieldsGroup[intGroup].fieldList.forEach(
                (innerGroup) => {
                  innerGroup.forEach((row) => {
                    row.forEach((field) => {
                      if (field.strID != undefined) {
                        const grouped = {};
                        for (const item of this.userInfo) {
                          if (!grouped[item.intUserFieldID]) {
                            grouped[item.intUserFieldID] = [];
                          }
                          grouped[item.intUserFieldID].push(item);
                        }
                        let group = Object.values(grouped).filter((e) => {
                          return e.length > 1;
                        });
                        Object.values(group).forEach((groupID) => {
                          if (groupID[0].intUserFieldID == field.strID) {
                            obj[field.strID] = {
                              strFieldLabel: field.strLabelEN,
                            };
                            let objData = {
                              data: groupID[i + 1].data,
                              intOrder: groupID[i + 1].intOrder,
                              // this.userData[intGroup].length,
                              intUserFieldID: field.strID,
                              intUserInfoID: 0,
                              // groupID[i].intUserInfoID
                              strDataImage: "",
                              intDataGroupType: 0,
                              intGroupLink: groupID[i + 1].intGroupLink,
                            };

                            obj[field.strID]["userInfo"] = objData;
                            // if (field.strID == 222) {
                            //   obj[field.strID]["userInfo"].data = false;
                            // }
                          }
                        });
                      }
                    });
                  });
                }
              );
              // console.log(obj);
              this.userData[intGroup].push(obj);
              this.checkEducationFilled(intGroup);
              // console.log("end addgroup");
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
      this.strSMSGunsucess = "223";
      this.blnAltunsucess = true;
      setTimeout(() => {
        this.blnAltunsucess = false;
        // window.location.reload();
      }, 2000);
    }

    // console.log(this.userData);
    // console.log(this.userData);
    this.AddGroupfromData();
    this.$emit("disbleSubmit", this.convertModelForApi());

    if (this.blnPreview == true) {
      setTimeout(() => {
        // ข้อมูลติดต่อ นักศึกษาไทย
        for (let i = 32; i < 42; i++) {
          if (this.$refs[i] != undefined) {
            this.$refs[i][0].inactive = true;
          }
        }

        // ข้อมูลติดต่อ นักศึกษาต่างชาติ
        for (let i = 173; i < 180; i++) {
          if (
            this.$refs[i] != undefined &&
            this.$refs[i][0].inactive != undefined
          ) {
            this.$refs[i][0].inactive = true;
          }
        }
      }, 4500);
      setTimeout(() => {
        // ข้อมูลครอบครัว นักศึกษาไทย
        for (let i = 74; i < 81; i++) {
          if (this.$refs[i] != undefined) {
            this.$refs[i][0].inactive = true;
          }
        }
        for (let i = 82; i < 128; i++) {
          if (this.$refs[i] != undefined) {
            this.$refs[i][0].inactive = true;
          }
        }
      }, 6000);
    }
  },
  updated() {
    this.locales = this.$i18n.locale;
  },
  components: {
    InputText,
    Button,
    // Dropdownapidata,
    inputComponentType,
    Checkbox,
    // DateTimePicker,
    InputNumber,
    // InputDateCalendar,
    Select,
    InputDigitcode,
    InputFixImage,
    InputAddlist,
    Radio,
    InputLongText,
    Toggle,
    InputCalendaSelecDateLength,
    AltStatus,
    Icon,
    CorrectFill,
  },
  methods: {
    getBlnGroupFilled(intGroup) {
      // console.log("getBlnGroupFilled", intGroup);
      let intNotSet = 0;
      // console.log(this.formData.fieldsGroup[intGroup].fieldList);
      for (const innerGroup of this.formData.fieldsGroup[intGroup].fieldList) {
        // console.log(innerGroup);
        for (const row of innerGroup) {
          for (const field of row) {
            // console.log(field);
            // console.log(this.getUserInfo(field.strID));

            if (
              this.getUserInfo(field.strID) == "" ||
              this.getUserInfo(field.strID) == null
            ) {
              intNotSet++;
            }
          }
        }
      }
      // console.log(intNotSet == 0);
      return intNotSet == 0;
    },
    getUserInfo(strFieldID) {
      let field = this.userInfo.filter((ele) => {
        // console.log(ele);
        return ele.intUserFieldID == strFieldID;
      });
      // console.log(field, strFieldID);
      if (strFieldID == 45) {
        return field[0]?.data != undefined
          ? field[0]?.data.lang[
              customfilter.filterLang(field[0]?.data.lang, this.$i18n.locale)
            ]
          : field[0]?.userInfo.data.lang[
              customfilter.filterLang(field[0]?.data.lang, this.$i18n.locale)
            ];
      }
      return field[0]?.data != undefined
        ? field[0]?.data
        : field[0]?.userInfo.data;
    },
    checkEducationFilled(groupindex) {
      if (this.blnAddData == true) {
        return;
      }
      setTimeout(() => {
        let intEmptyField = 0;
        let listUFdata = [];
        this.listintEmptyField = [];
        this.data.fieldsGroup.forEach((group) => {
          group.fieldList.forEach((innerGroup) => {
            innerGroup.forEach((row) => {
              row.forEach((field) => {
                listUFdata.push(field);
              });
            });
          });
        });
        // console.log(this.userData[groupindex]);
        // for (let i = 0; i < this.userData[groupindex].length; i++) {
        //   const element = this.userData[groupindex][i];
        //   console.log(element[164])
        // for (let l = 0; l < element[i].length; l++) {
        //   const ele= element[l];
        //   console.log(ele)
        // }

        // }
        for (const element of this.userData[groupindex]) {
          // console.log(element);
          for (let strID in element) {
            if (
              strID == 49 ||
              strID == 50 ||
              strID == 51 ||
              strID == 52 ||
              strID == 164 ||
              strID == 165
            ) {
              // console.log(strID, element[strID].userInfo.data);
              if (
                element[strID]?.userInfo?.data == null ||
                element[strID]?.userInfo?.data == "" ||
                element[strID]?.userInfo?.data == undefined
              ) {
                intEmptyField++;
              }
            }
          }
        }
        setTimeout(() => {
          this.listintEmptyField.push(intEmptyField);
          let intMax = this.listintEmptyField.filter((e) => {
            if (e > 0) {
              return e;
            }
          });
          // console.log(intMax);
          if (intMax.length != 0) {
            this.blnEducationFilled = false;
          } else if (intMax.length == 0) {
            this.blnEducationFilled = true;
          }
          // this.blnEducationFilled = intEmptyField == 0;
        }, 500);
      }, 500);
    },
    checkFilledAll(listRequiredGroupIndex) {
      // console.log(listRequiredGroupIndex);
      let blnRequireGroupFilled = false;
      if (!this.blnLoad && Array.isArray(listRequiredGroupIndex)) {
        listRequiredGroupIndex.forEach((requiredGroupIndex) => {
          // console.log(requiredGroupIndex);
          // console.log(this.userData);
          for (let i = 0; i < this.userData.length; i++) {
            let intEmptyField = 0;
            // console.log(this.userData[i]);
            if (requiredGroupIndex == i) {
              for (let strID in this.userData[i][0]) {
                // console.log(strID);
                if (
                  (this.userData[i][0][strID] == "" ||
                    this.userData[i][0][strID] == null) &&
                  strID != 222
                ) {
                  // console.log("intEmptyField++");
                  intEmptyField++;
                }
              }
            }
            blnRequireGroupFilled = blnRequireGroupFilled || intEmptyField != 0;
          }
        });
      }

      return blnRequireGroupFilled;
    },
    async getControlFieldData(newValue) {
      // console.log(newValue);
      this.listDropdownID = [];
      this.listControlFields = [];
      await newValue.fieldsGroup.forEach((group) => {
        group.fieldList[0].forEach((row) => {
          row.forEach((field) => {
            // console.log(field.intFieldType);
            if (field.intFieldType == 1) {
              if (field.strID == 40) {
                this.fetchControlFieldData(
                  field.strID,
                  field.strFieldName,
                  field.strRelation,
                  {},
                  {
                    intTypeID: 3,
                    strID: "",
                    intParentStatus: 1,
                    strLevel: "",
                  }
                );
              } else {
                this.fetchControlFieldData(
                  field.strID,
                  field.strFieldName,
                  field.strRelation,
                  {}
                );
              }
            }

            if (field.intFieldType == 1 || field.intFieldType == 4) {
              this.listDropdownID.push(field.strID);
            }
          });
        });
      });
    },
    sortOption(blnControl, listOption, strID) {
      if (listOption == []) {
        return [];
      }
      try {
        if (blnControl) {
          if (this.locales == "th" && listOption != undefined) {
            this.listControlFields[strID] = listOption.sort((a, b) =>
              a.strNameTH.localeCompare(b.strNameTH)
            );
          } else if (this.locales == "en" && listOption != undefined) {
            this.listControlFields[strID] = listOption.sort((a, b) =>
              a.strNameEN.localeCompare(b.strNameEN)
            );
          }
        } else if (!(strID == 64 || strID == 91 || strID == 118)) {
          if (this.locales == "th") {
            return listOption.sort((a, b) => a.nameTH.localeCompare(b.nameTH));
          } else {
            return listOption.sort((a, b) => a.nameEN.localeCompare(b.nameEN));
          }
        } else {
          return listOption;
        }
      } catch (e) {
        console.error(e, listOption, strID);
        // console.log("error ")
      }
    },
    async fetchControlFieldData(
      strID,
      strFieldName,
      strRelation,
      params,
      bodyAcademic,
      bodySubAcademic
    ) {
      // console.log(strID, strRelation, params, bodyAcademic, bodySubAcademic);
      if (this.blnAddData == true || params?.PositionID?.length > 1) {
        return;
      }
      if (strRelation != "" && strRelation != null) {
        try {
          // console.log(urlapi + apitemplate.getControlFieldData + strRelation);
          let res;

          if (strID == 40 && Object.keys(bodyAcademic).length != 0) {
            // สำนักวิชา
            res = await axios.post(
              urlapi + apitemplate.getControlFieldData + strRelation,
              bodyAcademic
            );

            // disable หลักสูตร
            if (this.$refs["40"][0].strResult == "") {
              this.$refs["41"][0].inactive = true;
            }
          } else if (
            strID == 41 &&
            bodySubAcademic != undefined &&
            Object.keys(bodySubAcademic).length != 0
          ) {
            // หลักสูตร
            // console.log("load controlfield");
            res = await axios.post(
              urlapi + apitemplate.getControlFieldData + strRelation,
              bodySubAcademic
            );
          } else if (Object.keys(params).length == 0) {
            // control field ที่ไม่ต้องพ่วงกับอันอื่น
            res = await axios.post(
              urlapi + apitemplate.getControlFieldData + strRelation,
              { params }
            );
          } else {
            // control field ที่ต้องพ่วงกับอันอื่น
            const queryString = Object.keys(params)
              .map((key) => `${key}=${params[key]}`)
              .join("&");
            // console.log(queryString);
            res = await axios.post(
              urlapi +
                apitemplate.getControlFieldData +
                strRelation +
                "?" +
                queryString,
              bodyAcademic == null
                ? {
                    intTypeID: 0,
                    strID: "string",
                    intParentStatus: 0,
                    strLevel: "string",
                  }
                : bodyAcademic
            );
          }

          // console.log(urlapi + apitemplate.getControlFieldData + strRelation,
          // { params })
          // console.log(strID, res);
          const listOption = res.data.data;
          if (!strFieldName.includes("Prefix")) {
            this.sortOption(true, listOption, strID);
          } else {
            this.listControlFields[strID] = listOption;
          }
        } catch (e) {
          console.log(strID, strRelation, params, e);
        }
      }
    },
    rendererAddGroup(intGroup) {
      this.data.fieldsGroup[intGroup].fieldList.push(
        JSON.parse(
          JSON.stringify(this.formData.fieldsGroup[intGroup].fieldList[0])
        )
      );
    },
    addInnerGroup(intGroup) {
      if (this.userData[intGroup][0] != null) this.rendererAddGroup(intGroup);
      let obj = {};
      this.data.fieldsGroup[intGroup].fieldList.forEach((innerGroup) => {
        innerGroup.forEach((row) => {
          row.forEach((field) => {
            if (field.strID != undefined) {
              obj[field.strID] = { strFieldLabel: field.strLabelEN };
              let objData = {
                data: null,
                intOrder: this.userData[intGroup].length,
                intUserFieldID: field.strID,
                intUserInfoID: "",
                strDataImage: "",
                intDataGroupType: 0,
              };
              obj[field.strID]["userInfo"] = objData;

              if (field.strRelation == "ads_country") {
                if (
                  this.$refs[field.strID] !== undefined &&
                  this.$refs[field.strID][0] !== undefined &&
                  this.$refs[field.strID][0].strResult !== undefined
                ) {
                  this.$refs[field.strID][0].strResult = "837";
                  obj[field.strID]["userInfo"].data = {
                    id: null,
                    lang: { langEN: "Thailand", langTH: "ไทย" },
                  };
                }
              } else {
                obj[field.strID]["userInfo"].data =
                  field.strID == 222 ? 0 : null;
              }
            }
          });
        });
      });

      // console.log(obj);
      this.userData[intGroup].push(obj);
      this.checkEducationFilled(intGroup);
      // console.log("end addgroup");
    },
    removeGroup(intGroup, intInnerGroup) {
      // console.log(intGroup, intInnerGroup);
      // console.log(this.data.fieldsGroup);

      this.data.fieldsGroup[intGroup].fieldList.splice(intInnerGroup, 1);

      for (const field of Object.keys(this.userData[intGroup][intInnerGroup])) {
        // console.log(this.$refs[field]);
        this.$refs[field].splice(intInnerGroup, 1);
        // console.log(this.$refs[field][intInnerGroup]);

        // Ref calendar
        if (field == "220" || field == "221") {
          if (this.$refs[field][intInnerGroup] != undefined) {
            // console.log(field);
            this.$refs[field][
              intInnerGroup
            ].$refs.calendar.blnUpdateData = true;
            this.$refs[field][intInnerGroup].UpdateData();
            setTimeout(() => {
              this.$refs[field][
                intInnerGroup
              ].$refs.calendar.blnUpdateData = true;
              this.$refs[field][intInnerGroup].UpdateData();
            }, 100);
          }
        }
      }
      if (this.userData[intGroup][intInnerGroup] != undefined) {
        this.userData[intGroup].splice(intInnerGroup, 1);
      }
      this.listintEmptyField = [];
      this.checkEducationFilled(intGroup, intInnerGroup);
    },
    enableCheck(
      strID,
      intInnerGroup,
      intGroupIndex,
      strRelation
      // strFieldName
    ) {
      // console.log(
      //   strID,
      //   intInnerGroup,
      //   intGroupIndex,
      //   strRelation
      //   // strFieldName
      // );
      this.$nextTick(() => {
        if (strRelation == "ads_district") {
          let blnDisable = false;
          if (this.$refs["checkbox" + intGroupIndex] != undefined) {
            if (this.$refs["checkbox" + intGroupIndex][0].check == true) {
              blnDisable = true;
            } else if (
              this.$refs["checkbox" + intGroupIndex][0].check == false
            ) {
              blnDisable =
                this.$refs[strID - 1][intInnerGroup]?.strResult == ""
                  ? (this.$refs[strID][intInnerGroup].inactive = true)
                  : (this.$refs[strID][intInnerGroup].inactive = false);
              blnDisable
                ? (this.$refs[strID][intInnerGroup].strResult = "")
                : null;
            }
          } else {
            blnDisable =
              this.$refs[strID - 1][intInnerGroup]?.strResult == ""
                ? (this.$refs[strID][intInnerGroup].inactive = true)
                : (this.$refs[strID][intInnerGroup].inactive = false);
            blnDisable
              ? (this.$refs[strID][intInnerGroup].strResult = "")
              : null;
          }
          return blnDisable;
        }

        if (strRelation == "ads_subdistrict") {
          let blnDisable = false;
          if (this.$refs["checkbox" + intGroupIndex] != undefined) {
            if (this.$refs["checkbox" + intGroupIndex][0].check == true) {
              blnDisable = true;
            } else if (
              this.$refs["checkbox" + intGroupIndex][0].check == false
            ) {
              blnDisable =
                this.$refs[strID - 1][intInnerGroup]?.strResult == ""
                  ? (this.$refs[strID][intInnerGroup].inactive = true)
                  : (this.$refs[strID][intInnerGroup].inactive = false);
              blnDisable
                ? (this.$refs[strID][intInnerGroup].strResult = "")
                : null;
            }
          } else {
            blnDisable =
              this.$refs[strID - 1][intInnerGroup]?.strResult == ""
                ? (this.$refs[strID][intInnerGroup].inactive = true)
                : (this.$refs[strID][intInnerGroup].inactive = false);
            blnDisable
              ? (this.$refs[strID][intInnerGroup].strResult = "")
              : null;
          }
          return blnDisable;
        }
        if (strRelation == "ads_postcode") {
          let blnDisable = false;
          if (this.$refs["checkbox" + intGroupIndex] != undefined) {
            if (this.$refs["checkbox" + intGroupIndex][0].check == true) {
              blnDisable = true;
            } else {
              blnDisable =
                this.$refs[strID - 1][intInnerGroup]?.strResult == ""
                  ? (this.$refs[strID][intInnerGroup].inactive = true)
                  : (this.$refs[strID][intInnerGroup].inactive = false);
              blnDisable
                ? (this.$refs[strID][intInnerGroup].strResult = "")
                : null;
            }
          } else {
            blnDisable =
              this.$refs[strID - 1][intInnerGroup]?.strResult == ""
                ? (this.$refs[strID][intInnerGroup].inactive = true)
                : (this.$refs[strID][intInnerGroup].inactive = false);
            blnDisable
              ? (this.$refs[strID][intInnerGroup].strResult = "")
              : null;
          }
          return blnDisable;
        }
        if (strRelation == "ads_country") {
          if (
            strID == 27 ||
            strID == 34 ||
            strID == 69 ||
            strID == 76 ||
            // strID == 96 ||
            strID == 103 ||
            // strID == 123 ||
            strID == 130 ||
            strID == 166 ||
            strID == 175 ||
            strID == 189
          ) {
            this.$refs[strID][intInnerGroup].inactive = true;
          }
        }
        // if (strRelation == "ads_academicorg") {
        // console.log(strID);
        // }

        // if (
        //   strRelation == "ads_country" &&
        //   !(strID == 180 || strID == 203 || strID == 27)
        // ) {
        //   // this.$refs[strID][intInnerGroup].strResult = "837";
        //   this.$refs[strID][intInnerGroup].inactive = true;
        //   // if (this.$refs[strID][intInnerGroup].strResult == "")
        //   //   this.userData[intGroupIndex].fieldList[intInnerGroup][strID] = {
        //   //     id: null,
        //   //     lang: { langEN: "Thailand", langTH: "ไทย" },
        //   //   };
        //   return true;
        // }
        if (strRelation == "ads_position") {
          const blnDisable =
            this.$refs[strID - 1][intInnerGroup].strResult == ""
              ? (this.$refs[strID][intInnerGroup].inactive = true)
              : (this.$refs[strID][intInnerGroup].inactive = false);
          blnDisable ? (this.$refs[strID][intInnerGroup].strResult = "") : null;
          return blnDisable;
        }
        if (strRelation == "ads_agency") {
          const blnDisable =
            this.$refs[strID - 1][intInnerGroup].strResult == ""
              ? (this.$refs[strID][intInnerGroup].inactive = true)
              : (this.$refs[strID][intInnerGroup].inactive = false);
          blnDisable ? (this.$refs[strID][intInnerGroup].strResult = "") : null;
          return blnDisable;
        }
        if (strRelation == "ads_institute") {
          const blnDisable =
            this.$refs[50][intInnerGroup].strResult == "" ||
            this.$refs[51][intInnerGroup].strResult == ""
              ? (this.$refs[strID][intInnerGroup].inactive = true)
              : (this.$refs[strID][intInnerGroup].inactive = false);
          blnDisable ? (this.$refs[strID][intInnerGroup].strResult = "") : null;
          return blnDisable;
        }
      });
    },
    async getLongTextAddress() {
      const res = await axios.post(urlapi + apitemplate.getUserFields, [
        {
          listUserFielID: [239, 240, 246, 247, 248, 249, 250, 251],
        },
      ]);
      // console.log(res);
      return res.data.dataFields;
    },
    async onSelectDropdown(
      event,
      blnControl,
      item,
      group,
      intInnerGroup,
      row,
      index
    ) {
      // console.log(item.strID, event);
      if (blnControl) {
        this.updateUserdata(group, intInnerGroup, item.strID, event.value);
        if (item.strID == 96) {
          this.$refs["radio2332"][0].blncanChang = event.value.id != 214;
          if (event.value.id != 214) {
            // console.log("not Thailand");
            if (this.$refs["checkbox" + group] != undefined) {
              this.$refs["checkbox" + group][0].blnChange = true;
            }
            // console.log(this.$refs["radio2330"]);
            if (this.$refs["radio2330"] != undefined) {
              this.$refs["radio2330"][0].check = true;
              this.$refs["radio2331"][0].check = false;
              this.$refs["radio2332"][0].check = false;
            }
            this.listBlnDisabledGroup[group + 1] = false;

            this.userData[group][intInnerGroup][239] = {
              strFieldLabel: item.strLabelEN,
            };
            let objData = {
              data: null,
              intOrder: this.userData[group].length,
              intUserFieldID: 239,
              intUserInfoID: "",
              strDataImage: "",
              intDataGroupType: 0,
            };
            this.userData[group][intInnerGroup][239]["userInfo"] = objData;
            for (let fieldID in this.userData[group][intInnerGroup]) {
              if (fieldID != 96 && fieldID != 242 && fieldID != 239) {
                delete this.userData[group][intInnerGroup][fieldID];
              }
            }
            // console.log(this.data.fieldsGroup[group].fieldList[intInnerGroup]);
            this.data.fieldsGroup[group].fieldList[intInnerGroup].splice(2);

            if (
              this.data.fieldsGroup[group].fieldList[intInnerGroup].length == 2
            ) {
              const newField = await this.getLongTextAddress();
              this.data.fieldsGroup[group].fieldList[intInnerGroup][2] = [
                newField[0],
              ];
            }
          } else if (
            this.data.fieldsGroup[group].fieldList[intInnerGroup].length <= 3
          ) {
            if (this.$refs["checkbox" + group] != undefined) {
              this.$refs["checkbox" + group][0].blnChange = false;
            }
            const newField = [94, 95, 97, 98, 99, 100];
            const newFieldLabel = [
              "House No. or building name",
              "Street, Lane, Village No.",
              "Province",
              "District",
              "Sub district",
              "Postal code",
            ];
            newField.forEach((field, index) => {
              this.userData[group][intInnerGroup][field] = {
                strFieldLabel: newFieldLabel[index],
              };
              let objData = {
                data: null,
                intOrder: this.userData[group].length,
                intUserFieldID: field,
                intUserInfoID: "",
                strDataImage: "",
                intDataGroupType: 0,
              };
              this.userData[group][intInnerGroup][field]["userInfo"] = objData;
            });
            this.data.fieldsGroup[group].fieldList[intInnerGroup] = JSON.parse(
              JSON.stringify(
                this.formData.fieldsGroup[group].fieldList[intInnerGroup]
              )
            );
          }
        }
        if (item.strID == 123) {
          this.$refs["radio2342"][0].blncanChang = event.value.id != 214;
          if (event.value.id != 214) {
            // console.log("not Thailand");
            if (this.$refs["checkbox" + group] != undefined) {
              this.$refs["checkbox" + group][0].blnChange = true;
            }
            if (this.$refs["radio2340"] != undefined) {
              this.$refs["radio2340"][0].check = true;
              this.$refs["radio2341"][0].check = false;
              this.$refs["radio2342"][0].check = false;
            }
            this.listBlnDisabledGroup[group + 1] = false;

            this.userData[group][intInnerGroup][240] = {
              strFieldLabel: item.strLabelEN,
            };
            let objData = {
              data: null,
              intOrder: this.userData[group].length,
              intUserFieldID: 240,
              intUserInfoID: "",
              strDataImage: "",
              intDataGroupType: 0,
            };
            this.userData[group][intInnerGroup][240]["userInfo"] = objData;
            for (let fieldID in this.userData[group][intInnerGroup]) {
              if (fieldID != 123 && fieldID != 244 && fieldID != 240) {
                delete this.userData[group][intInnerGroup][fieldID];
              }
            }
            this.data.fieldsGroup[group].fieldList[intInnerGroup].splice(2);

            if (
              this.data.fieldsGroup[group].fieldList[intInnerGroup].length == 2
            ) {
              const newField = await this.getLongTextAddress();
              this.data.fieldsGroup[group].fieldList[intInnerGroup][2] = [
                newField[1],
              ];
            }
          } else if (
            this.data.fieldsGroup[group].fieldList[intInnerGroup].length <= 3
          ) {
            if (this.$refs["checkbox" + group] != undefined) {
              this.$refs["checkbox" + group][0].blnChange = false;
            }
            const newField = [121, 122, 124, 125, 126, 127];
            const newFieldLabel = [
              "House No. or building name",
              "Street, Lane, Village No.",
              "Province",
              "District",
              "Sub district",
              "Postal code",
            ];
            newField.forEach((field, index) => {
              this.userData[group][intInnerGroup][field] = {
                strFieldLabel: newFieldLabel[index],
              };
              let objData = {
                data: null,
                intOrder: this.userData[group].length,
                intUserFieldID: field,
                intUserInfoID: "",
                strDataImage: "",
                intDataGroupType: 0,
              };
              this.userData[group][intInnerGroup][field]["userInfo"] = objData;
            });
            this.data.fieldsGroup[group].fieldList[intInnerGroup] = JSON.parse(
              JSON.stringify(
                this.formData.fieldsGroup[group].fieldList[intInnerGroup]
              )
            );
          }
        }
        if (item.strID == 203) {
          if (event.value.id == 214) {
            // console.log("Thailand");
            if (this.$refs["checkbox" + group] != undefined) {
              this.$refs["checkbox" + group][0].blnChange = false;
            }
            this.data.fieldsGroup[group].fieldList[intInnerGroup].splice(4);

            const newField = [246, 247, 248, 249, 250, 251];
            newField.forEach((field) => {
              this.userData[group][intInnerGroup][field] = {
                strFieldLabel: item.strLabelEN,
              };
              this.userInfo.forEach((userInfoData) => {
                if (userInfoData.intUserFieldID == field) {
                  let objData = {
                    data: userInfoData.data,
                    intOrder: this.userData[group].length,
                    intUserFieldID: field,
                    intUserInfoID: "",
                    strDataImage: "",
                    intDataGroupType: 0,
                  };
                  this.userData[group][intInnerGroup][field]["userInfo"] =
                    objData;
                }
              });
            });
            // clear ข้อมูล ของ 204(รายละเอียดที่อยู่)
            this.userData[group][intInnerGroup][204].userInfo.data = "";

            if (
              this.data.fieldsGroup[group].fieldList[intInnerGroup].length == 4
            ) {
              const newFields = await this.getLongTextAddress();

              this.data.fieldsGroup[group].fieldList[intInnerGroup][4] = [
                newFields[2],
                newFields[3],
              ];
              this.data.fieldsGroup[group].fieldList[intInnerGroup][5] = [
                newFields[4],
                newFields[5],
              ];
              this.data.fieldsGroup[group].fieldList[intInnerGroup][6] = [
                newFields[6],
                newFields[7],
              ];
              this.getControlFieldData(this.data);
              // this.listBlnDisabledGroup[group] = true
              // this.enableCheck(247, intInnerGroup, group, item.strRelation);
              // if (this.$refs["248"] != undefined) {
              //   this.$refs["248"][0].inactive = true;
              //   this.$refs["249"][0].inactive = true;
              //   this.$refs["250"][0].inactive = true;
              // }
            }
          } else if (event.value.id != 214) {
            if (
              this.data.fieldsGroup[group].fieldList[intInnerGroup].length >= 4
            ) {
              // ลบข้อมูล userinfo
              const newField = [246, 247, 248, 249, 250, 251];
              newField.forEach((field) => {
                this.userInfo.forEach((userInfoData) => {
                  if (userInfoData.intUserFieldID == field) {
                    userInfoData.data = "";
                  }
                  if (userInfoData.intUserFieldID == 204) {
                    userInfoData.data =
                      this.userData[group][intInnerGroup][204].userInfo.data;
                  }
                });
                // console.log(this.userInfo);
              });
            }
            this.data.fieldsGroup[group].fieldList[intInnerGroup].splice(4);
            this.userData[group][intInnerGroup][181] = {
              strFieldLabel: item.strLabelEN,
            };
            let objData = {
              data: null,
              intOrder: this.userData[group].length,
              intUserFieldID: 181,
              intUserInfoID: "",
              strDataImage: "",
              intDataGroupType: 0,
            };
            this.userData[group][intInnerGroup][181]["userInfo"] = objData;
            this.data.fieldsGroup[group].fieldList[intInnerGroup] = JSON.parse(
              JSON.stringify(
                this.formData.fieldsGroup[group].fieldList[intInnerGroup]
              )
            );
          }
        }
        if (item.strID == 40) {
          // if (this.$refs[item.strID] != undefined) {
          // this.$refs[item.strID][0].strResult =
          // this.$refs[item.strID][0]?.strResult?.split(",")[0];
          // this.$refs['41'][0].removeSelect()
          // }
          if (this.$refs["41"] != undefined) {
            if (this.$refs["41"][0].strResult != "") {
              this.$refs["41"][0].removeSelect();
              this.$refs["41"][0].blnDropdown = false;
              if (this.blnPreview == true) {
                this.$refs["41"][0].inactive = true;
              }
            }
          }
        }
        if (item.strID == 41) {
          if (this.blnPreview == true) {
            this.$refs["41"][0].inactive = true;
          }
        }
        // else if (item.strID == 41) {
        //   if (this.$refs[item.strID] != undefined) {
        //     this.$refs[item.strID][0].strResult =
        //       this.$refs[item.strID][0]?.strResult?.split(",")[1];
        //   }
        // }
        // console.log(item.strID, event, item);
        if (item.strRelation != "") {
          this.relationDropdown(event, group, intInnerGroup, row, index, item);
        }
      } else {
        // console.log(event);
        let obj = {
          value: event.value,
        };
        // console.log(JSON.stringify(obj));
        const data = JSON.stringify(obj);
        this.updateUserdata(group, intInnerGroup, item.strID, data);
      }
    },
    relationDropdown(event, group, intInnerGroup, row, index, item) {
      if (this.blnAddData == true) {
        return;
      }
      // console.log(item.strID, event);
      let params = {};
      let strFieldID = "";
      let strRef = "";
      let bodyAcademic = {};
      let bodySubAcademic = {};
      if (item.strFieldName.toLowerCase().includes("prefixth")) {
        let strNextFieldID = null;
        if (item.strID == 147) {
          strNextFieldID =
            this.data.fieldsGroup[group].fieldList[0][row - 1][index].strID;
        } else {
          strNextFieldID =
            this.data.fieldsGroup[group].fieldList[0][row + 1][index].strID;
        }
        if (this.userData[group][0][strNextFieldID] != undefined) {
          this.userData[group][0][strNextFieldID]["userInfo"].data =
            event?.value?.data != undefined ? event?.value?.data : event?.value;
          strFieldID = item.strID + 1;
        }
      } else if (item.strFieldName.toLowerCase().includes("prefixen")) {
        let strNextFieldID = null;
        if (item.strID == 151) {
          strNextFieldID =
            this.data.fieldsGroup[group].fieldList[0][row + 1][index].strID;
        } else {
          strNextFieldID =
            this.data?.fieldsGroup?.[group]?.fieldList?.[0]?.[row - 1]?.[index]
              ?.strID;
        }
        if (this.userData[group][0][strNextFieldID] != undefined) {
          this.userData[group][0][strNextFieldID]["userInfo"].data =
            event?.value?.data != undefined ? event?.value?.data : event?.value;
          strFieldID = item.strID + 1;
        }
      } else if (item.strID == 51 || item.strID == 50) {
        // console.log("ads_institute");
        // console.log(this.$refs[49][intInnerGroup]);
        if (this.$refs[49][intInnerGroup] != undefined) {
          this.$refs[49][intInnerGroup].strResult = "";
        }
        strRef = "ads_institute";
        try {
          params = {
            InstdegreeID:
              item.strID == 50
                ? event.value.id
                : this.userData[group][intInnerGroup][50].userInfo.data.id,
            ProvinceID:
              item.strID == 51
                ? event.value.id
                : this.userData[group][intInnerGroup][51].userInfo.data.id,
          };
        } catch (e) {
          params = {};
          this.listControlFields[49] = [];
          this.$refs[49][intInnerGroup].strResult = "";
          this.$refs[49][intInnerGroup].inactive = true;
        }
        if (
          params.InstdegreeID == undefined ||
          params.ProvinceID == undefined
        ) {
          params = {};
          this.listControlFields[49] = [];
          if (this.$refs[49][intInnerGroup] != undefined) {
            this.$refs[49][intInnerGroup].strResult = "";
          }
        }
        this.listControlFields[49] = [];
        // console.log(strRef, params);
        strFieldID = 49;
      } else if (
        item.strRelation == "ads_province" &&
        item.strID != 51 &&
        item.strID != 143 &&
        item.strID != 17
      ) {
        strRef = "ads_district";
        params = { ProvinceID: event.value?.id };
        this.listControlFields[item.strID + 1] = [];

        this.$refs[
          this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
            index + 1
          ].strID
        ][0].strResult = "";
        setTimeout(() => {
          if (
            this.$refs[
              this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
                index + 1
              ].strID
            ][0]?.strResult == ""
          ) {
            this.userData[group][intInnerGroup][item.strID + 1].userInfo.data =
              undefined;
            this.userData[group][intInnerGroup][item.strID + 2].userInfo.data =
              undefined;
            this.userData[group][intInnerGroup][item.strID + 3].userInfo.data =
              undefined;
            // disable checkbox
            this.listBlnGroupFilled[group + 1] = true;
          }
        }, 1000);

        this.$refs[
          this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
            index
          ].strID
        ][0].strResult = "";

        this.$refs[
          this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
            index + 1
          ].strID
        ][0].strResult = "";
        strFieldID = item.strID + 1;
      } else if (item.strRelation == "ads_district") {
        strRef = "ads_subdistrict";
        const intProvinceID =
          this.$refs[
            this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
              index - 1
            ].strID
          ][0].intId.id;
        params = { ProvinceID: intProvinceID, DistrictID: event.value.id };
        this.listControlFields[item.strID + 1] = [];
        this.$refs[
          this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
            index - 1
          ].strID
        ][0].strResult = "";
        this.$refs[
          this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
            index
          ].strID
        ][0].strResult = "";
        // setTimeout(() => {
        //   if (
        //     this.$refs[
        //       this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
        //         index
        //       ].strID
        //     ][0]?.strResult == ""
        //   ) {
        //     this.userData[group][intInnerGroup][item.strID + 1].userInfo.data =
        //       undefined;
        //     this.userData[group][intInnerGroup][item.strID + 2].userInfo.data =
        //       undefined;
        //     // disable checkbox
        //     this.listBlnGroupFilled[group + 1] = true;
        //   }
        // }, 2000);

        strFieldID = item.strID + 1;
      } else if (item.strRelation == "ads_subdistrict") {
        strRef = "ads_postcode";
        const intProvinceID =
          this.$refs[
            this.data.fieldsGroup[group].fieldList[intInnerGroup][row - 1][
              index
            ].strID
          ][0].intId.id;
        const intDistrictID =
          this.$refs[
            this.data.fieldsGroup[group].fieldList[intInnerGroup][row - 1][
              index + 1
            ].strID
          ][0].intId.id;
        // console.log("intProvinceID" + intProvinceID);
        // console.log("intDistrictID" + intDistrictID);
        params = {
          ProvinceID: intProvinceID,
          DistrictID: intDistrictID,
          SubdistrictID: event.value?.id,
        };
        strFieldID = item.strID + 1;

        this.$refs[
          this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
            index + 1
          ].strID
        ][0].strResult = "";
      } else if (item.strRelation == "ads_worktype") {
        // console.log(event);

        strRef = "ads_position";
        params = {
          WorktypeID: event?.value?.id,
        };
        strFieldID = item.strID + 1;

        // clear value position&agency ----------------------------------------
        try {
          this.listControlFields[item.strID + 1] = [];
          this.$refs[
            this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
              index + 1
            ].strID
          ][intInnerGroup].strResult = "";

          setTimeout(() => {
            if (
              this.$refs[
                this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
                  index + 1
                ].strID
              ][intInnerGroup] != undefined &&
              this.$refs[
                this.data.fieldsGroup[group].fieldList[intInnerGroup][row][
                  index + 1
                ].strID
              ][intInnerGroup].strResult == ""
            ) {
              this.userData[group][intInnerGroup][
                item.strID + 1
              ].userInfo.data = undefined;
              this.userData[group][intInnerGroup][
                item.strID + 2
              ].userInfo.data = undefined;
            }
          }, 1000);
        } catch (error) {
          console.log(error);
        }
      } else if (item.strRelation == "ads_position") {
        strRef = "ads_agency";
        params = {
          PositionID: event.value.id,
        };
        strFieldID = item.strID + 1;

        // clear value agency ---------------------------------------------------
        try {
          this.listControlFields[item.strID + 1] = [];
          this.$refs[
            this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
              index - 1
            ].strID
          ][intInnerGroup].strResult = "";

          setTimeout(() => {
            if (
              this.$refs[
                this.data.fieldsGroup[group].fieldList[intInnerGroup][row + 1][
                  index - 1
                ].strID
              ][intInnerGroup].strResult == ""
            ) {
              this.userData[group][intInnerGroup][
                item.strID + 1
              ].userInfo.data = undefined;

              // ใส่ค่าตามuserInfo หลังจากอัพเดท
              setTimeout(() => {
                const grouped = {};
                for (const items of this.userInfo) {
                  if (!grouped[items.intUserFieldID]) {
                    grouped[items.intUserFieldID] = [];
                  }
                  grouped[items.intUserFieldID].push(items);
                }
                // console.log(grouped);
                if (grouped[219] != undefined) {
                  let dataReverse = Object.values(grouped[219]).reverse();
                  // console.log(dataReverse);
                  this.userData[group][intInnerGroup][
                    item.strID + 1
                  ].userInfo.data = dataReverse[intInnerGroup].data;
                }
              }, 1000);
            }
          }, 500);
        } catch (error) {
          console.log(error);
        }
      } else if (item.strRelation == "ads_agency") {
        strRef = "ads_agency";
        params = {
          PositionID: event.value.id,
        };
        strFieldID = item.strID + 1;
      } else if (item.strRelation == "ads_academicorg") {
        if (item.strID == 40) {
          bodyAcademic = {
            intTypeID: 3,
            strID: "",
            intParentStatus: 1,
            strLevel: "",
          };
          // disable หลักสูตร
          if (this.$refs["40"][0].strResult == "") {
            this.$refs["41"][0].inactive = true;
          } else {
            this.$refs["41"][0].inactive = false;
            bodySubAcademic = {
              intTypeID: 3,
              strID: event.value.id.toString(),
              intParentStatus: 7,
              strLevel: "3",
              // intParentStatus: 5,
              // strLevel: "2",
              // ข้อมูลนำเขาใช้ 40 = LV 1
              // ข้อมูลนำเขาใช้ 41 = LV 3
            };
            strFieldID = item.strID + 1;
            strRef = "ads_academicorg";
          }
        }
      }

      if (params != {}) {
        this.fetchControlFieldData(
          strFieldID,
          item.strFieldName,
          strRef,
          params,
          bodyAcademic,
          bodySubAcademic
        );
      }
    },
    onSelectRef(listSource, listDestination, intGroupIndex, event, strID) {
      if (event === true) {
        // console.log("true");
        // console.log("data", listSource, listDestination);
        if (listDestination != undefined) {
          listDestination.forEach((intDestination) => {
            let i = 0;
            for (let field in this.userData[intDestination][0]) {
              // console.log(this.userData[intDestination][0][field]["userInfo"]);
              // console.log(
              //   this.userData[listSource][0][
              //     Object.keys(this.userData[listSource][0])[i]
              //   ]["userInfo"]
              // );
              // data
              this.userData[intDestination][0][field]["userInfo"].data =
                this.userData[listSource][0][
                  Object.keys(this.userData[listSource][0])[i]
                ]["userInfo"].data;
              //Userinfo ID
              // this.userData[intDestination][0][field]["userInfo"].intUserInfoID =
              //   this.userData[listSource][0][
              //     Object.keys(this.userData[listSource][0])[i]
              //   ]["userInfo"].intUserInfoID;
              i++;
              // this.userData[intGroupIndex+index][0] = this.userData[intDataGroupIndex][0]
              this.listBlnDisabledGroup[listDestination] =
                this.$refs["checkbox" + listDestination]?.[0]?.check;
            }
          });
        } else {
          // let i = 0;
          for (let field in this.userData[intGroupIndex][0]) {
            // console.log(this.userData[listSource][0]);
            for (let item in this.userData[listSource][0]) {
              if (
                this.userData[listSource][0][item].strFieldLabel ==
                this.userData[intGroupIndex][0][field]["strFieldLabel"]
              ) {
                // console.log(
                //   field,
                //   this.userData[intGroupIndex][0][field]["strFieldLabel"],
                //   this.userData[listSource][0][item].strFieldLabel
                // );
                this.listBlnDisabledGroup[intGroupIndex] = true;

                // data
                this.userData[intGroupIndex][0][field]["userInfo"].data =
                  this.userData[listSource][0][item]["userInfo"].data;
              }
            }

            // i++;
            // this.userData[intGroupIndex+index][0] = this.userData[intDataGroupIndex][0]
            // this.listBlnDisabledGroup[intGroupIndex] =
            //   listSource == 1 ? false : true;
          }
          this.listBlnDisabledGroup[intGroupIndex] =
            this.$refs["checkbox" + intGroupIndex]?.[0]?.check;
        }
      } else if (event === false) {
        // console.log("false");
        if (listDestination != undefined) {
          let i = 0;
          for (let field in this.userData[listDestination][0]) {
            // console.log(this.userData[listDestination][0][field]["userInfo"]);
            this.userData[listDestination][0][field]["userInfo"].data =
              JSON.parse(
                JSON.stringify(
                  this.userData[listSource][0][
                    Object.keys(this.userData[listSource][0])[i]
                  ]["userInfo"].data
                )
              );
            i++;
            // this.userData[intGroupIndex+index][0] = this.userData[intDataGroupIndex][0]
            this.$refs[field][0].inactive = false;
          }
        } else {
          this.listBlnDisabledGroup[intGroupIndex] =
            this.$refs["checkbox" + intGroupIndex]?.[0]?.check;
          // console.log(this.userData[intGroupIndex][0].data);
          if (this.userData[intGroupIndex][0].data != undefined) {
            this.userData[intGroupIndex][0].data = JSON.parse(
              JSON.stringify(this.userData[intGroupIndex][0].data)
            );
          }

          for (let field in this.userData[intGroupIndex][0]) {
            if (
              this.$refs[field] != undefined &&
              field != "81" &&
              field != "85" &&
              field != "108" &&
              field != "112"
            ) {
              if (this.$refs[field][0] != undefined) {
                this.$refs[field][0].inactive = false;
              }
              // if(this.userData[intGroupIndex][0][field].strFieldName.toLowerCase().includes("prefixth")&& this.locales=='en'|| (this.userData[intGroupIndex][0][field].strFieldName.toLowerCase().includes("prefixen")&& this.locales=='th')){
              //   this.$refs[field][0].inactive = true;
              // }
            }
          }
        }
      }
      // radio
      // label for compare to duplicates
      // userData must have the label
      else if (Array.isArray(listSource)) {
        // console.log("radio");
        if (listSource[event] != null) {
          let i = 0;
          for (let field in this.userData[intGroupIndex][0]) {
            if (field != "233" && field != "234" && field != "data") {
              this.userData[intGroupIndex][0][field]["userInfo"].data =
                this.userData[listSource[event]][0][
                  Object.keys(this.userData[listSource[event]][0])[i]
                ]["userInfo"]?.data ?? false;
              i++;
            }

            // this.userData[intGroupIndex+index][0] = this.userData[intDataGroupIndex][0]
          }

          // console.log(this.data);
          // ทำงานตอนเลือก radio แล้ว form รายละเอียดที่อยู่ต่างประเทศเปลี่ยน
          if (this.strMenuID == 4) {
            if (this.$refs["96"] != undefined) {
              if (this.$refs["96"][0].strResult != "") {
                if (
                  this.$refs["96"][0].intId.id != 214 &&
                  this.$refs["96"][0].intId.id != ""
                ) {
                  let dataNotThai = [
                    [
                      {
                        strID: 242,
                        listSource: 2,
                        strFieldName: "RefuserIDcardFather",
                        intFieldType: 4,
                        strRelation: "",
                        strDatatype: "1",
                        strValue:
                          '[{"nameTH":"ใช้ที่อยู่ผู้ปกครอง","nameEN":"Same as guardian address"},{"nameTH":"ไม่ใช้ข้อมูลของผู้ปกครอง","nameEN":"Not same as guardian address"}]',
                        strImageValue: "",
                        intRequire: 0,
                        intIdentifier: 0,
                        intRepeatable: 0,
                        intNotnull: 0,
                        strFieldnameth:
                          "ใช้ข้อมูลผู้ปกครอง ที่อยู่ตามบัตรประชาชน บิดา",
                        intSearchflag: 0,
                        strLookupValue:
                          '[{"nameTH":"ใช้ที่อยู่ผู้ปกครอง","nameEN":"Same as guardian address"},{"nameTH":"ไม่ใช้ข้อมูลของผู้ปกครอง","nameEN":"Not same as guardian address"}]',
                        strIcon: "",
                        strIconName: "",
                        intLangcontrolst: 0,
                        intTemplateDifinest: 0,
                        listImageLookup: [],
                        strLabelTH:
                          "ใช้ข้อมูลผู้ปกครอง ที่อยู่ตามบัตรประชาชน บิดา",
                        strLabelEN: "RefuserIDcardFather",
                        intDisplayType: 5,
                      },
                    ],
                    [
                      {
                        strID: 96,
                        strFieldName: "CountryIDCard",
                        intFieldType: 1,
                        strRelation: "ads_country",
                        strDatatype: "1",
                        strValue: "",
                        strImageValue: "",
                        intRequire: 0,
                        intIdentifier: 0,
                        intRepeatable: 0,
                        intNotnull: 0,
                        strFieldnameth: "ประเทศ ที่อยู่ตามบัตรประชาชน บิดา",
                        intSearchflag: 0,
                        strLookupValue: "",
                        strIcon: "",
                        strIconName: "",
                        intLangcontrolst: 0,
                        intTemplateDifinest: 0,
                        listImageLookup: [],
                        strLabelTH: "ประเทศ",
                        strLabelEN: "Country",
                        intDisplayType: 0,
                      },
                      {},
                    ],
                    [
                      {
                        strID: 239,
                        strFieldName: "AddressMother",
                        intFieldType: 3,
                        strRelation: "",
                        strDatatype: "1",
                        strValue: "",
                        strImageValue: "",
                        intRequire: 0,
                        intIdentifier: 0,
                        intRepeatable: 0,
                        intNotnull: 0,
                        strFieldnameth: "ที่อยู่มารดา",
                        intSearchflag: 0,
                        strLookupValue: "",
                        strIcon: "",
                        strIconName: "",
                        intLangcontrolst: 0,
                        intTemplateDifinest: 0,
                        listImageLookup: [],
                        strLabelTH: "รายละเอียดที่อยู่",
                        strLabelEN: "Address detail",
                        intDisplayType: 0,
                      },
                    ],
                  ];
                  setTimeout(() => {
                    this.data.fieldsGroup[5].fieldList[0] = dataNotThai;
                    // console.log(this.data);
                  }, 200);
                }
              }
            }
            if (this.$refs["123"] != undefined) {
              if (this.$refs["123"][0].strResult != "") {
                if (
                  this.$refs["123"][0].intId.id != 214 &&
                  this.$refs["123"][0].intId.id != ""
                ) {
                  let dataNotThai = [
                    [
                      {
                        strID: 244,
                        listSource: 2,
                        strFieldName: "RefuserIDcardMother",
                        intFieldType: 4,
                        strRelation: "",
                        strDatatype: "1",
                        strValue:
                          '[{"nameTH":"ใช้ที่อยู่ผู้ปกครอง","nameEN":"Same as guardian address"},{"nameTH":"ไม่ใช้ข้อมูลของผู้ปกครอง","nameEN":"Not same as guardian address"}]',
                        strImageValue: "",
                        intRequire: 0,
                        intIdentifier: 0,
                        intRepeatable: 0,
                        intNotnull: 0,
                        strFieldnameth:
                          "ใช้ข้อมูลผู้ปกครอง ที่อยู่ตามบัตรประชาชน มารดา",
                        intSearchflag: 0,
                        strLookupValue:
                          '[{"nameTH":"ใช้ที่อยู่ผู้ปกครอง","nameEN":"Same as guardian address"},{"nameTH":"ไม่ใช้ข้อมูลของผู้ปกครอง","nameEN":"Not same as guardian address"}]',
                        strIcon: "",
                        strIconName: "",
                        intLangcontrolst: 0,
                        intTemplateDifinest: 0,
                        listImageLookup: [],
                        strLabelTH:
                          "ใช้ข้อมูลผู้ปกครอง ที่อยู่ตามบัตรประชาชน มารดา",
                        strLabelEN: "RefuserIDcardMother",
                        intDisplayType: 5,
                      },
                    ],
                    [
                      {
                        strID: 123,
                        strFieldName: "CountryIDCard",
                        intFieldType: 1,
                        strRelation: "ads_country",
                        strDatatype: "1",
                        strValue: "",
                        strImageValue: "",
                        intRequire: 0,
                        intIdentifier: 0,
                        intRepeatable: 0,
                        intNotnull: 0,
                        strFieldnameth: "ประเทศ ที่อยู่ตามบัตรประชาชน มารดา",
                        intSearchflag: 0,
                        strLookupValue: "",
                        strIcon: "",
                        strIconName: "",
                        intLangcontrolst: 0,
                        intTemplateDifinest: 0,
                        listImageLookup: [],
                        strLabelTH: "ประเทศ",
                        strLabelEN: "Country",
                        intDisplayType: 0,
                      },
                      {},
                    ],
                    [
                      {
                        strID: 240,
                        strFieldName: "AddressFather",
                        intFieldType: 3,
                        strRelation: "",
                        strDatatype: "1",
                        strValue: "",
                        strImageValue: "",
                        intRequire: 0,
                        intIdentifier: 0,
                        intRepeatable: 0,
                        intNotnull: 0,
                        strFieldnameth: "ที่อยู่บิดา",
                        intSearchflag: 0,
                        strLookupValue: "",
                        strIcon: "",
                        strIconName: "",
                        intLangcontrolst: 0,
                        intTemplateDifinest: 0,
                        listImageLookup: [],
                        strLabelTH: "รายละเอียดที่อยู่",
                        strLabelEN: "Address detail",
                        intDisplayType: 0,
                      },
                    ],
                  ];
                  setTimeout(() => {
                    this.data.fieldsGroup[8].fieldList[0] = dataNotThai;
                    // console.log(this.data);
                  }, 200);
                }
              }
            }
          }
          this.listBlnDisabledGroup[intGroupIndex] = true;
        } else {
          // console.log("เพิ่มที่อยู่จัดส่ง");
          this.listBlnDisabledGroup[intGroupIndex] = false;
          this.userData[intGroupIndex][0].data = JSON.parse(
            JSON.stringify(this.userData[intGroupIndex][0]?.data || false)
          );
        }
      } else {
        let i = 0;
        for (let field in this.userData[intGroupIndex][0]) {
          // console.log(this.userData[intDestination][0][field]["userInfo"]);
          // console.log(
          //   this.userData[listSource][0][
          //     Object.keys(this.userData[listSource][0])[i]
          //   ]["userInfo"]
          // );
          // data
          if (this.userData[listSource] != undefined) {
            this.userData[intGroupIndex][0][field]["userInfo"].data =
              this.userData[listSource][0][
                Object.keys(this.userData[listSource][0])[i]
              ]["userInfo"].data;
          }

          //Userinfo ID
          // this.userData[intDestination][0][field]["userInfo"].intUserInfoID =
          //   this.userData[listSource][0][
          //     Object.keys(this.userData[listSource][0])[i]
          //   ]["userInfo"].intUserInfoID;
          i++;
          // this.userData[intGroupIndex+index][0] = this.userData[intDataGroupIndex][0]
          this.listBlnDisabledGroup[listDestination] = true;
        }

        if (strID == 138 || strID == 139 || strID == 140 || strID == 141) {
          // alert radio ของข้อมูลสำรวจ
          this.$refs[strID][0].classList.add("d-none");
        }
      }
      // console.log(strID);
      // else if (!Array.isArray(listSource)) {
      //   //radio เมนูข้อมูลแบบสำรวจ
      //   if (strID == 138 && event == 1) {
      //     if (this.$refs["radio" + (strID + 1)] != undefined) {
      //       if (
      //         this.$refs["radio" + (strID + 1)][0].$refs.radio.checked ==
      //           true ||
      //         this.$refs["radio" + (strID + 1)][1].$refs.radio.checked == true
      //       ) {
      //         this.$refs["radio" + (strID + 1)][0].check = false;
      //         this.$refs["radio" + (strID + 1)][1].check = true;

      //         this.$refs["radio" + (strID + 1)][0].$refs.radio.checked = false;
      //         this.$refs["radio" + (strID + 1)][1].$refs.radio.checked = true;
      //       }
      //     }
      //   }
      // }
    },

    onClickDropdown(strID) {
      this.$nextTick(() => {
        this.listDropdownID.forEach((id) => {
          // console.log(this.$refs[id][0])
          if (id != strID) {
            if (this.$refs[id] != undefined) {
              if (this.$refs[id][0] != undefined) {
                this.$refs[id][0].blnDropdown = false;
              }
            }
          }
        });
      });
    },
    updateUserdata(intGroupIndex, intInnerGroupindex, strID, event) {
      // console.log(strID, event, this.userInfo);
      if (this.blnAddData == true) {
        return;
      }
      // console.log(strID, event);
      this.listDisableCheckbox = [];
      if (intGroupIndex in this.userData) {
        if (intInnerGroupindex in this.userData[intGroupIndex]) {
          // ข้อมูลตำแหน่ง เพิ่ม intGroupLink ไปด้วย
          if (
            strID == 217 ||
            strID == 218 ||
            strID == 219 ||
            strID == 220 ||
            strID == 221 ||
            strID == 222
          ) {
            if (
              this.userData[intGroupIndex][intInnerGroupindex][strID] ==
              undefined
            ) {
              return
            }
            this.userData[intGroupIndex][intInnerGroupindex][strID][
                "userInfo"
              ].data = event;
            if (
              this.userData[intGroupIndex][intInnerGroupindex][strID][
                "userInfo"
              ]["intGroupLink"] == undefined
            ) {
              for (let ID = 217; ID < 223; ID++) {
                this.userData[intGroupIndex][intInnerGroupindex][ID][
                  "userInfo"
                ]["intGroupLink"] = 0;
                this.userData[intGroupIndex][intInnerGroupindex][ID][
                  "userInfo"
                ]["intGroupLink"] = intInnerGroupindex + 1;
              }
            }

            if (strID == 218) {
              // ถ้าเป็นข้อมูลตำแหน่ง
              if (
                event?.id == 17
                // ||
                // event.id == 34 ||
                // event.id == 36 ||
                // event.id == 37 ||
                // event.id == 38 ||
                // event.id == 41 ||
                // event.id == 46
              ) {
                //ส่งค่า intUserTypeID = 3 เพราะเป็นอาจารย์สำหรับไปอัพเดทข้อมูล
                this.$emit("changUserType", 3);
                // console.log("changUsertype");
              } else if (event?.id != 17) {
                //ส่งค่า ntUserTypeID = 4 เพราะไม่เป็นอาจารย์สำหรับไปอัพเดทข้อมูล
                this.$emit("changUserType", 4);
              }
            }
          }
          if (strID == 19 || strID == 163 || strID == 216) {
            this.userData[intGroupIndex][intInnerGroupindex][strID][
              "userInfo"
            ].data = event == null ? null : event.filename;
            this.userData[intGroupIndex][intInnerGroupindex][strID][
              "userInfo"
            ].strDataImage = event == null ? null : event.base64;
          } else {
            // setTimeout(() => {
            this.userData[intGroupIndex][intInnerGroupindex][strID][
              "userInfo"
            ].data = event;
            // }, 1000);

            // disable checkMember
            if (
              this.Action == "Insert" &&
              (strID == 1 || strID == 145 || strID == 205)
            ) {
              if (
                this.userData[intGroupIndex][intInnerGroupindex][strID][
                  "userInfo"
                ]?.data == null ||
                this.userData[intGroupIndex][intInnerGroupindex][strID][
                  "userInfo"
                ]?.data == ""
              ) {
                // ถ้ารหัสไม่มีข้อมูล
                this.blnDisableAll = true;
                this.strClassCheckMember =
                  "btn-fix bg-black-500 disabled text-white pe-none p-0";
              } else if (
                (strID == 1 || strID == 145) &&
                this.userData[intGroupIndex][intInnerGroupindex][strID][
                  "userInfo"
                ]?.data.length < 8
              ) {
                // ถ้ารหัสนักศึกษามีข้อมูลน้อยกว่า 8
                this.blnDisableAll = true;
                this.strClassCheckMember =
                  "btn-fix bg-black-500 disabled text-white pe-none p-0";
              } else if (
                strID == 205 &&
                this.userData[intGroupIndex][intInnerGroupindex][strID][
                  "userInfo"
                ]?.data.length < 10
              ) {
                // ถ้ารหัสพนักงานมีข้อมูลน้อยกว่า 10
                this.blnDisableAll = true;
                this.strClassCheckMember =
                  "btn-fix bg-black-500 disabled text-white pe-none p-0";
              } else {
                this.strClassCheckMember = "btn-fix btn-primary p-0";
              }
            }
            // console.log(strID, this.userData[intGroupIndex]);
          }

          // ที่อยู่ตามบัตรประชาชน
          // ถ้าข้อมูลบัตรประชาชนกรอกไม่ครบจะ disable checkbox ใช้ที่อยู่ตามบัตรปชช.
          if (intGroupIndex == 2 || intGroupIndex == 1) {
            if (
              intGroupIndex == 2 &&
              (this.indexMenu == "3" || this.indexMenu == 3)
            ) {
              // เมนูข้อมูลครอบครัว
              let listEmty = 0;
              if (strID >= 70 && strID <= 73) {
                if (
                  this.userData[intGroupIndex][intInnerGroupindex][strID][
                    "userInfo"
                  ].data == undefined
                ) {
                  for (let i = strID + 1; i < 74; i++) {
                    // console.log(i);
                    this.userData[intGroupIndex][intInnerGroupindex][i][
                      "userInfo"
                    ].data = undefined;
                  }
                }
              }

              Object.values(
                this.userData[intGroupIndex][intInnerGroupindex]
              ).forEach((element) => {
                if (
                  (element.userInfo.data == "" ||
                    element.userInfo.data == undefined) &&
                  element.userInfo.intUserFieldID != 231
                ) {
                  // console.log(element);
                  listEmty++;
                  this.listDisableCheckbox.push(listEmty);
                }
              });
              let intMax = this.listDisableCheckbox.filter((e) => {
                if (e > 0) {
                  return e;
                }
              });
              // console.log(intMax);
              if (intMax.length != 0) {
                for (let i = 0; i < this.listBlnGroupFilled.length; i++) {
                  this.listBlnGroupFilled[i] = true;
                }
              } else if (intMax.length == 0) {
                for (let i = 0; i < this.listBlnGroupFilled.length; i++) {
                  this.listBlnGroupFilled[i] = false;
                }
              }
            } else if (
              intGroupIndex == 1 &&
              (this.indexMenu == "1" || this.indexMenu == 1)
            ) {
              // เมนูข้อมูลติดต่อ
              let listEmty = 0;
              if (strID >= 25 && strID <= 31) {
                if (
                  this.userData[intGroupIndex][intInnerGroupindex][strID][
                    "userInfo"
                  ].data == undefined
                ) {
                  for (let i = strID + 1; i < 32; i++) {
                    this.userData[intGroupIndex][intInnerGroupindex][i][
                      "userInfo"
                    ].data = undefined;
                  }
                }
              }

              Object.values(
                this.userData[intGroupIndex][intInnerGroupindex]
              ).forEach((element) => {
                if (
                  (element.userInfo.data == "" ||
                    element.userInfo.data == undefined) &&
                  element.userInfo.intUserFieldID != 231
                ) {
                  // console.log(element);
                  listEmty++;
                  this.listDisableCheckbox.push(listEmty);
                }
              });
              let intMax = this.listDisableCheckbox.filter((e) => {
                if (e > 0) {
                  return e;
                }
              });
              // console.log(intMax);
              if (intMax.length != 0) {
                for (let i = 0; i < this.listBlnGroupFilled.length; i++) {
                  this.listBlnGroupFilled[i] = true;
                }
              } else if (intMax.length == 0) {
                for (let i = 0; i < this.listBlnGroupFilled.length; i++) {
                  this.listBlnGroupFilled[i] = false;
                }
              }
            } else if (
              intGroupIndex == 1 &&
              (this.indexMenu == "2" || this.indexMenu == 2)
            ) {
              // เมนูข้อมูลติดต่อต่างชาติ
              let listEmty = 0;
              if (strID >= 167 && strID <= 172) {
                if (
                  this.userData[intGroupIndex][intInnerGroupindex][strID][
                    "userInfo"
                  ].data == undefined
                ) {
                  for (let i = strID + 1; i < 173; i++) {
                    this.userData[intGroupIndex][intInnerGroupindex][i][
                      "userInfo"
                    ].data = undefined;
                  }
                }
              }

              Object.values(
                this.userData[intGroupIndex][intInnerGroupindex]
              ).forEach((element) => {
                if (
                  (element.userInfo.data == "" ||
                    element.userInfo.data == undefined) &&
                  element.userInfo.intUserFieldID != 231
                ) {
                  // console.log(element);
                  listEmty++;
                  this.listDisableCheckbox.push(listEmty);
                }
              });
              let intMax = this.listDisableCheckbox.filter((e) => {
                if (e > 0) {
                  return e;
                }
              });
              // console.log(intMax);
              if (intMax.length != 0) {
                for (let i = 0; i < this.listBlnGroupFilled.length; i++) {
                  this.listBlnGroupFilled[i] = true;
                }
              } else if (intMax.length == 0) {
                for (let i = 0; i < this.listBlnGroupFilled.length; i++) {
                  this.listBlnGroupFilled[i] = false;
                }
              }
            }
          }
        }
      }
      this.checkEducationFilled(intGroupIndex, intInnerGroupindex);
      this.$emit("disbleSubmit", this.convertModelForApi());
    },

    convertModelForApi() {
      let userinfo = [];
      try {
        this.userData?.forEach((group) => {
          group?.forEach((innerGroup) => {
            // console.log(innerGroup);
            for (let field in innerGroup) {
              // console.log(field, innerGroup[field]);
              if (innerGroup[field].userInfo != undefined) {
                userinfo.push(innerGroup[field]);
              }
            }
          });
        });
      } catch (e) {
        console.error(e);
        return userinfo;
      }
      // console.log(userinfo);
      return userinfo;
    },
    validate(strUserfieldID) {
      // console.log(strUserfieldID);
      if (
        strUserfieldID != 138 &&
        strUserfieldID != 139 &&
        strUserfieldID != 140 &&
        strUserfieldID != 141
      ) {
        if (this.$refs[strUserfieldID] !== undefined) {
          if (this.$refs[strUserfieldID][0] !== undefined) {
            // console.log(strUserfieldID, this.$refs[strUserfieldID][0]);
            try {
              // console.log(this.$refs[strUserfieldID][0].Valid())
              // if (this.$refs[strUserfieldID][0].Valid() != undefined) {
              this.$refs[strUserfieldID][0].Valid();
              return this.$refs[strUserfieldID][0].alertMsgStatus;
              // }
            } catch (error) {
              console.log(error);
              return false;
            }
          }
        }
      }
    },
    async alertRequest(strUserfieldID, intInnerGroup) {
      // console.log(this.$refs)
      if (this.$refs[strUserfieldID] !== undefined) {
        if (
          strUserfieldID != 138 &&
          strUserfieldID != 139 &&
          strUserfieldID != 140 &&
          strUserfieldID != 141
        ) {
          let mymethod = this.$refs[strUserfieldID][intInnerGroup];

          if (mymethod != null) {
            setTimeout(() => {
              try {
                this.$refs[strUserfieldID][intInnerGroup].alertRequest();
              } catch (error) {
                console.log(error);
              }
            }, 200);
          }
        } else {
          // alert radio ของข้อมูลสำรวจ
          this.$refs[strUserfieldID][0].classList.remove("d-none");
        }
      }
    },
    async detectDatainField() {
      var count = 0;
      this.data.fieldsGroup.forEach((group, intGroup) => {
        group.fieldList.forEach((innerGroup, intInnerGroup) => {
          innerGroup.forEach((row) => {
            row.forEach((field) => {
              // console.log(field);
              if (field.intNotnull == 1) {
                var validate = this.validate(field.strID);
                // console.log(field.strFieldName, validate);

                if (field.strID == 19) {
                  if (
                    validate == true ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].strDataImage == undefined ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].strDataImage == null ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].strDataImage == ""
                  ) {
                    // console.log(field.strID);
                    this.alertRequest(field.strID, intInnerGroup);
                    count += 1;
                  } else if (validate == true) {
                    // console.log(field.strID);
                    this.alertRequest(field.strID, intInnerGroup);
                    count += 1;
                  }
                } else if (field.strID == 2) {
                  // console.log(
                  //   this.userData[intGroup][intInnerGroup][field.strID][
                  //     "userInfo"
                  //   ].data
                  // );
                  if (
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == undefined ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == null ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == "" ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data.length < 13
                  ) {
                    // console.log(field.strID);
                    this.alertRequest(field.strID, intInnerGroup);
                    count += 1;
                  } else if (validate == true) {
                    // console.log(field.strID);
                    this.alertRequest(field.strID, intInnerGroup);
                    count += 1;
                  }
                } else {
                  if (
                    validate == true ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == undefined ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == null ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == "" ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data.length == 0 ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == "null" ||
                    this.userData[intGroup][intInnerGroup][field.strID][
                      "userInfo"
                    ].data == "[]"
                  ) {
                    // console.log(field.strID);
                    this.alertRequest(field.strID, intInnerGroup);
                    count += 1;
                  } else if (validate == true) {
                    // console.log(field.strID);
                    this.alertRequest(field.strID, intInnerGroup);
                    count += 1;
                  }
                }
              }
            });
          });
        });
      });

      // console.log("Count  :" + count);
      if (count > 0) {
        return false;
      } else if (count == 0) {
        this.$emit("userInfoChange", this.convertModelForApi());
        return true;
      }
    },
    oncancel() {
      this.$emit("userInfoChange", this.convertModelForApi());
    },
    labelCheckbox(item) {
      // console.log(intGroupindex);
      // return label checkbox from strLookupValue
      if (item.strLookupValue != "" || item.strLookupValue != undefined) {
        // if (this.$refs["checkbox" + intGroupindex]?.[0]?.check == false) {
        return this.$i18n.locale == "en"
          ? JSON.parse(item.strLookupValue)[0].nameEN
          : JSON.parse(item.strLookupValue)[0].nameTH;
        // console.log(JSON.parse(item.strLookupValue)[1]);
        // } else if (this.$refs["checkbox" + intGroupindex]?.[0]?.check == true) {
        // return this.$i18n.locale == "en"
        //   ? JSON.parse(item.strLookupValue)[1].nameEN
        //   : JSON.parse(item.strLookupValue)[1].nameTH;
        // console.log(JSON.parse(item.strLookupValue)[0]);
        // }
      }
    },
    customSort(arr) {
      arr.sort((a, b) => {
        return a.intGroupLink - b.intGroupLink;
      });
    },
    AddGroupfromData() {
      // console.log(this.userData);
      // console.log(this.userInfo);
      // console.log(this.indexMenu);
      // console.log(this.strMenuID);
      setTimeout(() => {
        if (
          this.strMenuID == 3
          // || this.strMenuID == 9
        ) {
          const grouped = {};
          for (const item of this.userInfo) {
            if (!grouped[item.intUserFieldID]) {
              grouped[item.intUserFieldID] = [];
            }
            grouped[item.intUserFieldID].push(item);
          }
          let duplicateID;
          let GroupuserData;
          if (this.strMenuID == 3) {
            duplicateID = 49;
            GroupuserData = 2;
          } else {
            duplicateID = 217;
            GroupuserData = 0;
          }
          // console.log(duplicateID, GroupuserData);
          // console.log(grouped);
          // for (var key in grouped) {
          //   if (Object.prototype.hasOwnProperty.call(grouped, key)) {
          //     this.customSort(grouped[key]);
          //     console.log(this.customSort(grouped[key]))
          //   }
          // }
          // console.log(grouped);
          // this.userData.forEach((_, intGroup) => {
          for (let i = 0; i < grouped[duplicateID]?.length - 1; i++) {
            if (this.userData[GroupuserData][0] != null) {
              this.rendererAddGroup(GroupuserData);
              let obj = {};
              this.data.fieldsGroup[GroupuserData].fieldList.forEach(
                (innerGroup) => {
                  innerGroup.forEach((row) => {
                    row.forEach((field) => {
                      if (field.strID != undefined) {
                        let group = Object.values(grouped).filter((e) => {
                          return e.length > 1;
                        });
                        Object.values(group).forEach((groupID) => {
                          if (groupID[0].intUserFieldID == field.strID) {
                            obj[field.strID] = {
                              strFieldLabel: field.strLabelEN,
                            };
                            let objData = {
                              data: groupID[i].data,
                              intOrder: groupID[i].intOrder,
                              intUserFieldID: field.strID,
                              intUserInfoID: 0,
                              // groupID[i].intUserInfoID,
                              strDataImage: "",
                              intDataGroupType: 0,
                              intGroupLink: groupID[i].intGroupLink,
                            };
                            obj[field.strID]["userInfo"] = objData;
                          }
                        });
                      }
                    });
                  });
                }
              );
              // console.log(obj);
              this.userData[GroupuserData].push(obj);
              this.checkEducationFilled(GroupuserData);
              // console.log("end addgroup");
            }
          }

          // });
        }
      }, 2500);
    },
    async checkMemberStatus(intGroupIndex, intInnerGroupindex, strID) {
      // console.log(intGroupIndex, intInnerGroupindex, strID);
      try {
        this.blncheckMemberComplete = false;
        let strStudentCode =
          this.userData[intGroupIndex][intInnerGroupindex][strID]["userInfo"]
            ?.data;
        var res = await axios.get(
          urlapi + apiuserinfo.GetCheckUserInfo + strStudentCode
        );
        // console.log(res.data.data);
        if (res.data.data.strCheckMemberStatus == "0") {
          // ไม่มี Member และ ไม่มี userInfo
          this.blnDisableAll = false;

          localStorage.setItem("userinfoInsert", true);
          // console.log(localStorage.getItem("userinfoInsert"));
          this.strClassCheckMember =
            "btn-fix bg-black-500 disabled text-white pe-none p-0";
          this.blncheckMemberComplete = true;
          this.$emit("checkMember", res.data.data);
        } else if (res.data.data.strCheckMemberStatus == "1") {
          this.blncheckMemberComplete = true;
          // มี Member แต่ ไม่มี userinfo
          this.$emit("checkMember", res.data.data);
        } else if (res.data.data.strCheckMemberStatus == "2") {
          this.blncheckMemberComplete = true;
          //มี Member และ มี userinfo
          this.$emit("checkMember", res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // return value ของ checkbox
    valueCheckBox(data) {
      let blnCheck = false;
      if (data != "" && data != null && data != undefined) {
        if (JSON.parse(data)?.value?.lang?.langEN == "1") {
          blnCheck = true;
        } else {
          blnCheck = false;
        }
      }
      return blnCheck;
    },
  },
};
</script>

<template>
  <div>
    <label
      class="txt-area"
      :class="inactive == true ? 'txt-area-disable': ''"
    >
      <textarea
        required
        type="text"
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value"
        v-on:keypress="isLetter($event)"
        @keyup.delete="deleteLetter()"
        @change="Valid()"
        :class="alertMsgStatus == true ? 'invalid' : '' || 
          alertLength == true ? 'invalid' : ''"
      ></textarea>
      <p :class="device == 'mobile' ? 'fnt-caption' : ''" class="label-text">
        {{ $t(label) }} {{ require }}
      </p>
      <!-- จำนวนตัวที่พิมพ์ -->
      <p class="number-text mb-2" :class="(value == undefined || value == null || value == 0) ? 'd-none':''">
        <span class="m-2"
          ><span :class="alertLength == true?'text-danger':'' || inactive==true ? 'bg-transparent':'bg-white'">{{ value == undefined ? 0 : value.length }}/{{ maxlength }}</span></span
        >
      </p>

      <!-- <span v-if="require == true"> *</span> -->
      <div
        v-if="alertMsgStatus == true"
        class="d-flex alertInput align-items-center p-0 mt-1"
      >
        <Icon icon="carbon:warning" class="me-1" />
        {{ $t(alertMsgInput) }}
      </div>
    </label>
    <!-- <p class="length">{{pLength.length}} / 160</p> -->
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Validation from "../../typescript/validator/validator";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      pLength: 0,
      inactive: false,
      alertLength: false,
      alertLengthMsg: "",
    };
  },
  props: {
    name: { type: String, required: true },
    lang: { type: String, required: true, default: "EN" },
    label: { type: String, required: false },
    value: { type: String },
    validate: { type: String },
    maxlength: { type: String, required: false },
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    require: { type: String, default: "" },
    showTxtInp: { type: Boolean, default: false }
  },
  components: {
    Icon,
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (this.inactive !== true) {
      this.inactive = this.showTxtInp;
    }
  },
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        if (this.alertMsgStatus == true) {
          this.Valid();
        }
      }
    },
    alertRequest() {
      this.alertMsgInput = this.$t(this.alertMsg, {
        fieldname: this.$t(this.label).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    deleteLetter() {
      this.alertMsgStatus = false;
      if (this.value != undefined) {
        if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
    },
    Valid() {
      this.alertMsgStatus = false;
      this.alertLength = false;
      if (this.value === "" || this.value === undefined) {
        return;
      } else {
        if (this.validate == "email") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "57";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "number") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "40";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "currency") {
          return Validation[this.validate](this.value);
        } else if (this.validate == "PhoneNumberTH") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else if (this.validate == "PhoneNumberEN") {
          this.alertMsgStatus = !Validation[this.validate](this.value);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.value);
          }
        } else {
          if (this.validate != "") {
            this.alertMsgStatus = !Validation[this.validate](this.value);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = this.$t("202", {
                fieldname: this.$t(this.label).toLowerCase(),
              });
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](this.value);
            }
          }
        }
      }
    },
    isLetter(e) {
      this.alertMsgStatus = false;
      // console.log(this.maxlength);
      if (this.value != undefined && this.maxlength != undefined) {
        // console.log("this.value.length = " + this.value.length);
        if (this.value.length + 1 > this.maxlength) {
          this.alertLength = true;
          // console.log("เกิน");
          // this.alertLengthMsg = "427";
          // setTimeout(() => {
          //   this.alertLength = false;
          // }, 3000);
        } else if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }

      if (this.validate != null) {
        let char = String.fromCharCode(e.keyCode);
        if (this.validate == "letterEN" || this.validate == "letterTH") {
          if (this.validate == "letterEN") {
            this.alertMsgStatus = !Validation[this.validate](char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "46";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](char);
            }
          } else if (this.validate == "letterTH") {
            this.alertMsgStatus =
              !/^[~/\]/`/!@#$%^&*()_+={}/[|;:",.<>?ก-๏0-9- ]+$/.test(char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "45";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](char);
            }
          }
        } else if (this.validate == "InitialEN") {
          if (/^[A-Za-z.]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "231";
            e.preventDefault();
          }
        } else if (this.validate == "InitialTH") {
          if (/^[ก-๏.]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "232";
            e.preventDefault();
          }
        } else if (this.validate == "email") {
          if (/^[A-Za-z\s\d@.]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "57";
            e.preventDefault();
          }
        } else if (this.validate == "number") {
          if (/^[0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "40";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "currency") {
          if (/^[0-9.,]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "Please enter numbers only (0-9)";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.validate == "PhoneNumberTH") {
          this.alertMsgStatus = !Validation[this.validate](char);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            e.preventDefault();
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](char);
          }
        } else if (this.validate == "PhoneNumberEN") {
          this.alertMsgStatus = !Validation[this.validate](char);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            e.preventDefault();
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](char);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.txt-area {
  position: relative;
}
.txt-area {
  .invalid {
    border: solid 1px $danger !important;

    &:focus {
      & + p {
        color: $danger !important;
      }
    }

    &:valid + p {
      color: $danger !important;
    }
  }
}
.txt-area {
  height: fit-content;
  position: relative;
  width: 100%;
  .label-text {
    position: absolute;
    left: 8px;
    top: 10%;
    transform: translateY(-10%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    cursor: text;
    color: $black-500;
    user-select: none;
  }

  textarea {
    height: 90px;
    outline: 0;
    font-size: 16px;
    // padding-top: 16px;
    padding-left: 16px;
    border: solid 1px $black-500;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    padding-top: 12px;
    &:hover {
      border: solid 1px $primary;

      & + p {
        color: $primary;
      }
    }
    &:focus {
      border: solid 1px $primary;
      & + p {
        top: -8%;
        transition: all 0.2s;
        color: $primary !important;
        background-color: #fff;
        font-size: 14px;
      }
    }

    &:valid + p {
      color: $black-500;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -8%;
    }
  }
  .number-text{
    position: absolute;
    bottom: 0;
    right: 0;
    color: $black-500;
  }
}
.txt-area-disable {
  height: fit-content;
  position: relative;
  width: 100%;
  pointer-events: none;
  .label-text {
    position: absolute;
    left: 8px;
    top: 10%;
    transform: translateY(-10%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    cursor: text;
    color: $black-500;
    user-select: none;
  }

  textarea {
    height: 90px;
    outline: 0;
    font-size: 16px;
    // padding-top: 16px;
    padding-left: 16px;
    border: solid 1px $black-400;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    padding-top: 12px;
    background-color: $black-100;
    &:hover {
      border: solid 1px $black-500;

      & + p {
        color: $black-500;
      }
    }
    &:focus {
      border: solid 1px $black-500;
      & + p {
        top: -8%;
        transition: all 0.2s;
        color: $black-500 !important;
        background-color: $black-200;
        font-size: 14px;
      }
    }

    &:valid + p {
      color: $black-500;
      transition: all 0.2s;
      background-color: $black-200;
      border-radius: 4px;
      font-size: 14px;
      top: -8%;
    }
  }

  .txt-area-error {
    textarea {
      border-color: $danger !important;
    }
  }
}
</style>

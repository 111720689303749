<template>
  <div class="p-2 d-none">
    <Search :hint="$t('447', { fieldname: $t(hint).toLowerCase() })" />
  </div>
  <div class="list-items">
    <div
      v-for="(item, index) in listCheckboxDropdown"
      :key="index"
      class="item position-relative"
    >
      <!-- <Checkbox
      :checkboxLabel="item.lang"
      fontSize="fnt-regular fnt-subtitle"
      @select="selected($event)"
      @change="selection(item, index)"
    /> -->
      <input
        type="checkbox"
        class="me-2 form-check-input"
        :id="blnSystemword == true ? item.lang : item[fillterLang(item)]"
        @change="selected(item)"
        :value="blnSystemword == true ? item.lang : item[fillterLang(item)]"
        v-model="checkDrd"
        :checked="check"
      />
      <label
        :for="blnSystemword == true ? item.lang : item[fillterLang(item)]"
        >{{
          blnSystemword == true ? item.lang : item[fillterLang(item)]
        }}</label
      >
    </div>
  </div>
  <!-- {{ selection() }} -->
</template>

<script>
import Search from "@/components/input/inputSearch.vue";
import customfilter from "@/typescript/filter";
// import Checkbox from "@/components/input/checkbox.vue";
export default {
  data() {
    return {
      strResult: [],
      checkDrd: [],
      locales: "en",
    };
  },
  emits: ["checkshow", "getID"],

  mounted() {
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
    setTimeout(() => {
      this.items = [...this.listCheckboxDropdown];
      this.resetData()
    }, 1000);
  },
  watch: {
    selectedValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.resetData();
          // console.log(this.selectedValue);
        }
      },
    },
    selectedCheckbox: {
      deep: true,
      handler(newValue) {
        console.log(newValue);
        this.resetData();
      },
    },
  },
  methods: {
    resetData() {
      Object.values(this.items).forEach((e) => {
        Object.values(this.selectedCheckbox).forEach((ele) => {
          if (e.id == ele) {
            // console.log(e);
            let test = e.lang
            this.checkDrd.push(test)
            this.selected(e)
          }
        });
      });
    },
    selected(event) {
      this.strResult = "";
      for (var i in this.checkDrd) {
        this.strResult += this.checkDrd[i];
        if (i < this.checkDrd.length - 1) {
          this.strResult += ", ";
        }
      }
      
      this.$emit("checkshow", this.strResult);
      this.$emit("getID", event.id);
    },

    fillterLang(items) {
      if (items !== undefined) {
        return customfilter.fillterLang(items, this.locales);
      }
    },
    // },
  },
  components: {
    // Checkbox,
    Search,
  },
  props: {
    listCheckboxDropdown: { type: Object },
    blnSystemword: { type: Boolean, default: true },
    blnselected: { type: Boolean, default: false },
    selectedValue: { type: String },
    hint: { type: String },
    selectedCheckbox: { type: Array },
    // checkShow: { type: Object },
    check: null,
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  max-height: calc(44px * 7);
  min-height: fit-content;
  overflow-y: scroll;
  .item {
    height: 44px;
    background-color: $white;
    padding: 12px 16px;
    cursor: pointer;
    transition: 150ms;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $black-50;
    }
    &:active {
      background-color: $black-300;
    }
  }
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 2px !important;
    &:hover,
    &:focus {
      border: solid 1px $primary;
    }
  }
}
</style>

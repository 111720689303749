<template>
  <div v-if="progress != 100">
    <Loading1 />
  </div>
  <div v-if="progress == 100" class="d-flex w-100 m-0">
    <div
      v-if="device != 'mobile'"
      class="bg-white ps-5 pe-2 py-4 shadow-sm rounded left-menu me-3 w-25"
      style="height: fit-content"
    >
      <p class="text-primary fnt-medium mb-4">{{ $t("910") }}</p>
      <div
        class="mb-4 menu d-flex"
        v-for="(item, index) in listMenu"
        :key="index"
        @click="selectMenu(item.intGroupID, index)"
      >
        <span
          class="fnt-light mx-2"
          :class="
            intGroupID == item.intGroupID ? 'text-primary fnt-medium' : ''
          "
          >{{ $t(item.strGroupName) }}</span
        >
      </div>
    </div>

    <div class="rounded right-menu w-100">
      <div class="w-100" v-if="this.strUserTypeGroup != 'Officer'">
        <Cards
          :listuserinfoData="listUserInfo"
          :blnShowIndex="false"
          :blnHidEdit="true"
          :blnHidPreview="true"
          :strAction="action"
        />
      </div>
      <div
        class="bg-white rounded shadow-sm my-2 w-100"
        v-if="device == 'mobile'"
      >
        <Select
          :listItem="listSelect"
          :SelectedValue="1"
          background="bg-white border"
          size="w-100 medium"
          @input="onSelect($event)"
        />
      </div>
      <div class="bg-white shadow-sm rounded" v-if="blnLoading">
        <Loading1 />
      </div>
      <div class="bg-white shadow-sm rounded" v-if="!blnLoading">
        <div
          class="fnt-medium fnt-title bg-white fnt-bold text-dark w-100 shadow-sm rounded-top border-bottom"
          :class="device == 'mobile' ? 'px-4 py-3' : 'px-5 py-4'"
        >
          <!-- name -->
          <div v-if="!blnLoading">
            {{
              $t(listUserFields[intUserTypeID == 4 ? 2 :intUserTypeID - 1][intindexOfGroupID].formName)
            }}
            <span class="">
              <CorrectFill
                width="16"
                :class="blnCorrect == false ? 'fill-black-500' : 'fill-success'"
              />
            </span>
          </div>
        </div>
        <div
          class="bg-white rounded-bottom shadow-sm"
          :class="device == 'mobile' ? 'p-3' : 'p-6'"
        >
          <InputAddGroupList
            :formData="listUserFields[intUserTypeID == 4 ? 2 :intUserTypeID - 1][intindexOfGroupID]"
            :blnDisable="false"
            :userInfo="userInfo[this.intGroupID]"
            :blnPreview="true"
            :blnLoading="blnLoading"
            @userInfoChange="updateData($event)"
            :indexMenu="intindexOfGroupID"
            :ref="'UserInfoForm'"
          />
          <!-- <Button
              class="btn btn-primary mt-2"
              :name="$t('929')"
              @input="addData()"
            /> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Cards from "@/components/cards/userFix.vue";
import Loading1 from "@/components/Skeleton.vue";
import { listUserFieldsID, listUserFields } from "@/typescript/templateData.ts";
import InputAddGroupList from "@/components/input/InputAddGroupList.vue";
import Select from "@/components/input/Select/Select.vue";

import axios from "axios";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import urlapi from "@/typescript/urlapi/url";
export default {
  components: {
    Cards,
    Loading1,
    InputAddGroupList,
    Select,
  },
  props: {
    // listuserinfo: { type: Array },
    memberID: { type: Number },
  },
  data() {
    return {
      listUserInfo: [],
      listMenu: [
        // phase 1 เอาข้อมูลบัณฑิต ออกไปก่อน
        // { intGroupID: 6, strGroupName: "916", status: false },
      ],
      intGroupID: 1,
      progress: 0,
      action: "Update",
      strUserTypeGroup: "",
      listUserFields: listUserFields,
      listUserFieldsID: listUserFieldsID,
      intUserTypeID: 1,
      intindexOfGroupID: 0,
      userInfo: {},
      listSelect: [],
      listUserfieldIDcard: [
        {
          intUserfieldID: [
            1, 3, 4, 5, 6, 7, 8, 9, 10, 19, 20, 23, 39, 40, 41, 145, 147, 148,
            149, 150, 151, 152, 153, 154, 161, 163, 205, 206, 207, 208, 209,
            210, 211, 212, 213, 214, 215, 216, 218, 219, 221, 222, 226, 228,
          ],
        },
      ],
      strCodeID: "",
      strFirstName: "",
      blnLoading: false,
    };
  },
  mounted() {
    if (this.$route.query.intUserTypeID == 1) {
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911", status: false },
        { intGroupID: 2, strGroupName: "912", status: false },
        { intGroupID: 3, strGroupName: "913", status: false },
        { intGroupID: 4, strGroupName: "914", status: false },
        { intGroupID: 5, strGroupName: "915", status: false },
        // ข้อมูลบัณฑิตยังไม่มีใน phase 1
        // { intGroupID: 6, strGroupName: "916" },
      ];
      this.listSelect = [
        { id: 1, name: "911" },
        { id: 2, name: "912" },
        { id: 3, name: "913" },
        { id: 4, name: "914" },
        { id: 5, name: "915" },
        // ข้อมูลบัณฑิตยังไม่มีใน phase 1
        // { id: 6, name: "916" },
      ];
    } else if (this.$route.query.intUserTypeID == 2) {
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911", status: false },
        { intGroupID: 3, strGroupName: "913", status: false },
        { intGroupID: 2, strGroupName: "912", status: false },
        {
          intGroupID: 8,
          strGroupName: "993",
          status: false,
        },
      ];
      this.listSelect = [
        { id: 1, name: "911" },
        { id: 3, name: "913" },
        { id: 2, name: "912" },
        { id: 8, name: "993" },
      ];
    } else if (this.$route.query.intUserTypeID == 3 || this.$route.query.intUserTypeID == 4) {
      this.listMenu = [
        { intGroupID: 1, strGroupName: "911" },
        { intGroupID: 9, strGroupName: "1003" },
      ];
      this.listSelect = [
        { id: 1, name: "911" },
        { id: 9, name: "1003" },
      ];
    }
    this.processLoadingData().then(() => {
      this.progress = 100;
    });
  },
  methods: {
    async processLoadingData() {
      this.intUserTypeID = this.$route.query.intUserTypeID;
      this.progress = 0;
      if (this.intUserTypeID != 3) {
        await this.searchUserinfo();
      }
      await this.fetchUserfields(this.intindexOfGroupID);
      await this.fetchUserInfo(this.intGroupID);
    },
    async selectMenu(intGroupID, indexOfGroupID) {
      this.blnLoading = true;
      await this.fetchUserfields(indexOfGroupID);
      await this.fetchUserInfo(intGroupID);
      this.intGroupID = intGroupID;
      this.intindexOfGroupID = indexOfGroupID;
      setTimeout(() => {
        this.blnLoading = false;
      }, 1000);
    },
    async searchUserinfo() {
      var res = await axios.post(
        urlapi +
          apiuserinfo.GetUserInfoListByKeyword +
          this.$route.query.keyword +
          "/" +
          this.$route.query.intUsergrouptypeID,
        this.listUserfieldIDcard
      );
      // console.log(res);
      this.listUserInfo = await res.data.data[0].listUserInfoData;
      this.listUserInfo.forEach((element) => {
        if (element.intMemberID == this.memberID) {
          this.listUserInfo = [];
          this.listUserInfo.push(element);
          // console.log(this.listUserInfo);
          this.strUserTypeGroup = this.listUserInfo[0].strUserTypeGroupNameEN;
        }
      });
    },
    async fetchUserfields(intindexOfGroupID) {
      // console.log("res");
      // console.log(this.intUserTypeID);
      let intUserTemplateID = this.intUserTypeID
      intUserTemplateID == 4 ? (intUserTemplateID = 3) : intUserTemplateID;
      try {
        // apitemplate
        const res = await axios.post(
          urlapi + apitemplate.getUserFields,
          this.listUserFieldsID[intUserTemplateID - 1][intindexOfGroupID]
        );
        // console.log(res);
        this.listUserFields[intUserTemplateID - 1][
          intindexOfGroupID
        ].fieldsGroup.forEach((group, intGroupIndex) => {
          group.fieldList[0].forEach((row, intRowIndex) => {
            row.forEach((column, intColumnIndex) => {
              res.data.dataFields.forEach((field) => {
                if (field.strID == column.strID) {
                  this.listUserFields[intUserTemplateID - 1][
                    intindexOfGroupID
                  ].fieldsGroup[intGroupIndex].fieldList[0][intRowIndex][
                    intColumnIndex
                  ] = field;
                  // console.log(this.listUserFields[0][this.intGroupID - 1].fieldsGroup[intGroupIndex].fieldList[intRowIndex][intColumnIndex]);
                }
              });
            });
          });
        });
        if (res.data.code == 500) {
          this.blnAltStatusunsucess = true;
        }
        // console.log(res.data.dataFields);
      } catch (error) {
        this.blnAltStatusunsucess = true;
      }
    },
    async fetchUserInfo(intGroupID) {
      try {
        // this.strCodeID = "";
        // this.strFirstName = "";

        const res = await axios.get(
          urlapi + apiuserinfo.LoadUserinfobyID + this.memberID,
          {
            params: { UserInfoID: intGroupID },
          }
        );
        // console.log(res,intGroupID);

        this.userInfo[intGroupID] = res.data.data.userinfo;

        res.data.data.userinfo.forEach((ele) => {
          if (
            ele.intUserFieldID == 1 ||
            ele.intUserFieldID == 145 ||
            ele.intUserFieldID == 205
          ) {
            this.strCodeID = ele.data;
          }
          if (
            ele.intUserFieldID == 4 ||
            ele.intUserFieldID == 148 ||
            ele.intUserFieldID == 207
          ) {
            this.strFirstName = ele.data;
          }
        });
        // เปลี่ยนชื่อ tab
        document.title = this.strCodeID + this.strFirstName;
      } catch (error) {
        console.error(error, intGroupID);
      }
    },
    updateData(event) {
      console.log(event);
    },
    async onSelect(event) {
      // console.log(event);
      this.blnLoading = true;
      await this.fetchUserfields(event.index);
      await this.fetchUserInfo(event.id);
      this.intGroupID = event.id;
      this.intindexOfGroupID = event.index;
      setTimeout(() => {
        this.blnLoading = false;
      }, 1000);
    },
  },
};
</script>
  
  <style lang="scss">
.left-menu {
  .menu {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div>
    <div v-if="inactive == false">
      <div
        class="w-100 box-inp bg-white"
        :class="alertMsgStatus == true ? 'inp-alert' : 'border rounded'"
        style=""
      >
        <label class="inp-num d-flex align-items-center w-100">
          <div class="d-flex w-100 px-2">
            <div class="div-icon">
              <Icon
                icon="bx:time-five"
                class="icon text-black-500"
                width="24"
                height="24"
              />
            </div>
            <div class="d-flex w-100">
              <input
                type="number"
                v-model="inputHours"
                max="23"
                :maxlength="2"
                @input="checkValueHours($event)"
                @keyup.enter="EnterValueHours($event)"
                class="mx-1 pe-2 ps-3"
                ref="inputHousr"
              />
              <span>:</span>
              <input
                type="number"
                max="23"
                maxlength="2"
                v-model="inputMinutes"
                @input="checkValueMinutes($event)"
                @keyup.enter="EnterValueMinutes($event)"
                class="mx-1 pe-2 ps-3"
              />
            </div>

            <span
              class="label-text bg-white"
              :class="device == 'mobile' ? 'fnt-caption' : ''"
            >
              {{ $t(label) }}
              <span class="sub">{{ labelSub }}</span>
              <span class="require ms-1" v-if="request === true">*</span>
            </span>
          </div>
        </label>
        <!-- <span class="d-none">{{ valueFromInput() }}</span> -->
      </div>
      <div
        :class="alertMsgStatus == true ? '' : 'd-none'"
        class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
      >
        <Icon
          :class="alertMsgStatus == true ? '' : 'd-none'"
          icon="carbon:warning"
          class="me-1"
        />
        {{ $t(alertMsgInput) }}
      </div>
    </div>
    <div v-if="inactive == true">
      <div
        class="w-100 box-inp-disable bg-black-400"
        :class="alertMsgStatus == true ? 'inp-alert' : 'border rounded'"
        style=""
      >
        <label class="inp-num d-flex align-items-center w-100">
          <div class="d-flex w-100 px-2">
            <div class="div-icon">
              <Icon
                icon="bx:time-five"
                class="icon text-black-500"
                width="24"
                height="24"
              />
            </div>
            <div class="d-flex w-100">
              <input
                type="number"
                v-model="inputHours"
                max="23"
                :maxlength="2"
                @input="checkValueHours($event)"
                @keyup.enter="EnterValueHours($event)"
                class="mx-1 pe-2 ps-3"
                ref="inputHousr"
              />
              <span>:</span>
              <input
                type="number"
                max="23"
                maxlength="2"
                v-model="inputMinutes"
                @input="checkValueMinutes($event)"
                @keyup.enter="EnterValueMinutes($event)"
                class="mx-1 pe-2 ps-3"
              />
            </div>

            <span
              class="label-text bg-black-400"
              :class="device == 'mobile' ? 'fnt-caption' : ''"
            >
              {{ $t(label) }}
              <span class="sub">{{ labelSub }}</span>
              <span class="require ms-1" v-if="request === true">*</span>
            </span>
          </div>
        </label>
      </div>
      <div
        :class="alertMsgStatus == true ? '' : 'd-none'"
        class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
      >
        <Icon
          :class="alertMsgStatus == true ? '' : 'd-none'"
          icon="carbon:warning"
          class="me-1"
        />
        {{ $t(alertMsgInput) }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  data() {
    return {
      strValueHours: "",
      strValueMinutes: "",
      alertMsgStatus: false,
      alertMsgInput: "",
      inputHours: "00",
      inputMinutes: "00",
    };
  },

  components: { Icon },
  props: {
    label: { type: String },
    labelSub: { type: String },
    ValueHours: { type: String },
    ValueMinutesl: { type: String },
    blnDrop: { type: Boolean },
    AddHours: { type: Number, default: 0 },
    AddMinut: { type: Number, default: 0 },
    alertMsg: { type: String },
    inactive: { type: Boolean, default: false },
  },
  computed: {
    valueHouse() {
      return this.ValueHours;
    },
    valueMinut() {
      return this.ValueMinutesl;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.ValueHours != null && this.ValueMinutesl != null) {
        this.inputHours = this.ValueHours;
        this.inputMinutes = this.ValueMinutesl;
        this.strValueHours = this.ValueHours;
        this.strValueMinutes = this.ValueMinutesl;
      }

      if (this.ValueHours == "") {
        this.strValueHours =
          (new Date().getHours() + this.AddHours).toString().length < 2
            ? "0" + (new Date().getHours() + this.AddHours).toString()
            : new Date().getHours() + this.AddHours > 23
            ? "00"
            : new Date().getHours() + this.AddHours;
        this.inputHours =
          this.strValueHours.toString().length < 2
            ? "0" + this.strValueHours.toString()
            : this.strValueHours;
      }
      if (this.ValueMinutesl == "") {
        this.strValueMinutes =
          new Date().getMinutes().toString().length < 2
            ? "0" + new Date().getMinutes().toString()
            : new Date().getMinutes();
        this.inputMinutes =
          this.strValueMinutes.toString().length < 2
            ? "0" + this.strValueMinutes.toString()
            : this.strValueMinutes;
      }
    }, 1000);
  },
  methods: {
    updateValue() {
      if (this.ValueHours != null && this.ValueMinutesl != null) {
        setTimeout(() => {
          this.inputHours = this.valueHouse;
          this.inputMinutes = this.valueMinut;
          this.strValueHours = this.valueHouse;
          this.strValueMinutes = this.valueMinut;
        }, 500);
      }
    },
    checkValueHours(e) {
      // console.log(e);
      if (e.target != undefined) {
        if (e.target.value.length > 2) {
          e.target.value = e.target.value.slice(2);
        }
      }

      if (this.inputHours.toString().length > 2) {
        this.inputHours = this.inputHours.toString().slice(2);
      }
      setTimeout(() => {
        if (this.inputHours.toString().length <= 1) {
          this.strValueHours = "0" + this.inputHours;
          this.inputHours = this.strValueHours;
          this.strValueHours = this.inputHours;
        } else {
          this.strValueHours = this.inputHours;
        }
        this.valueFromInput();
      }, 1000);

      if (this.inputHours > 23) {
        this.inputHours = 23;
        this.strValueHours = this.inputHours;
      }
      this.valueFromInput();
    },
    checkValueMinutes(e) {
      // console.log(e);
      if (e.target != undefined) {
        if (e.target.value.length > 2) {
          e.target.value = e.target.value.slice(2);
        }
      }

      if (this.inputMinutes.toString().length > 2) {
        this.inputMinutes = this.inputMinutes.toString().slice(2);
      }

      setTimeout(() => {
        if (this.inputMinutes.toString().length <= 1) {
          this.strValueMinutes = "0" + this.inputMinutes;
          this.inputMinutes = this.strValueMinutes;
          this.strValueMinutes = this.inputMinutes;
        } else {
          this.strValueMinutes = this.inputMinutes;
        }
        this.valueFromInput();
      }, 1000);

      if (this.inputMinutes > 59) {
        this.inputMinutes = 59;
        this.strValueMinutes = this.inputMinutes;
      }
      this.valueFromInput();
    },
    valueFromInput() {
      if (this.blnDrop == true) {
        let currenttimeHours;
        let currenttimeMinut;
        if (this.ValueHours == undefined) {
          if (this.strValueHours == "") {
            if (new Date().getHours() + this.AddHours > 23) {
              currenttimeHours = "23";
              currenttimeMinut = "59";
            } else {
              currenttimeHours = new Date().getHours() + this.AddHours;
            }
            this.strValueHours =
              currenttimeHours.toString().length == 2
                ? currenttimeHours
                : "0" + currenttimeHours.toString();
          }
        }
        // else {
        //   this.strValueHours = this.ValueHours;
        // }
        if (this.ValueMinutesl == undefined) {
          if (this.strValueMinutes == "") {
            if (new Date().getMinutes() + this.AddMinut > 59) {
              currenttimeMinut = "59";
            } else {
              if (currenttimeMinut == undefined) {
                currenttimeMinut = new Date().getMinutes() + this.AddMinut;
              }
            }
            this.strValueMinutes =
              currenttimeMinut.toString().length == 2
                ? currenttimeMinut
                : "0" + currenttimeMinut.toString();
          }
        }
      }

      if (this.strValueHours != "" && this.strValueMinutes != "") {
        let value = this.strValueHours + ":" + this.strValueMinutes;
        this.$emit("strValue", value);
      }
    },
    EnterValueHours(e) {
      // console.log(e);
      if (e.target != undefined) {
        if (e.target.value.length != 2) {
          this.strValueHours = "0" + e.target.value;
        } else {
          this.strValueHours = e.target.value;
        }
      }
    },
    EnterValueMinutes(e) {
      // console.log(e);
      if (e.target != undefined) {
        if (e.target.value.length != 2) {
          this.strValueMinutes = "0" + e.target.value;
        } else {
          this.strValueMinutes = e.target.value;
        }
      }
    },
    Valid() {
      if (
        this.strValueHours == null ||
        this.strValueHours == "" ||
        this.strValueHours == undefined ||
        this.strValueMinutes == null ||
        this.strValueMinutes == "" ||
        this.strValueMinutes == undefined
      ) {
        this.alertMsgStatus = true;
      } else {
        this.alertMsgStatus = false;
      }
    },
    alertRequest() {
      if (
        this.strValueHours == null ||
        this.strValueHours == "" ||
        this.strValueHours == undefined ||
        this.strValueMinutes == null ||
        this.strValueMinutes == "" ||
        this.strValueMinutes == undefined
      ) {
        this.alertMsgInput = this.$t(this.alertMsg, {
          fieldname: this.$t(this.label).toLowerCase(),
        });
        this.alertMsgStatus = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inp-alert {
  border-radius: 0.25rem !important;
  border: 1px solid $danger !important;
  .label-text {
    color: $danger;
  }
}
.box-inp {
  &:hover {
    border: solid 1px $primary !important;
    .label-text {
      color: $primary;
    }
    .icon {
      color: $primary;
    }
  }
  .inp-num {
    height: 46px;
    position: relative;
    input {
      border: 0;
      width: 50%;
      // padding: 10px 17px !important;
    }
    input:focus-visible {
      outline: none;
      border: 1px solid $black-300;
      border-radius: 0.25rem;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .label-text {
      position: absolute;
      font-size: 14px;
      bottom: 37px;
    }
    .div-icon {
      width: 24px;
      .icon {
        position: absolute;
      }
    }
  }
}
.box-inp-disable {
  pointer-events: none;
  .inp-num {
    height: 46px;
    position: relative;
    input {
      border: 0;
      width: 50%;
      // padding: 10px 17px !important;
    }
    input:focus-visible {
      outline: none;
      border: 1px solid $black-600;
      border-radius: 0.25rem;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      background-color: $black-400;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
      background-color: $black-400;
    }

    .label-text {
      position: absolute;
      font-size: 14px;
      bottom: 37px;
    }
    .div-icon {
      width: 24px;
      .icon {
        position: absolute;
      }
    }
  }
}
</style >
<template>
  <div class="form-check" :class="canChange == true ? 'pe-none' : ''">
    <!-- {{ checker }} -->
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      :name="checkboxLabel"
      :id="strId || checkboxLabel"
      :checked="checked"
      v-model="check"
      @change="onSelect()"
      :disabled="checker"
    />
    <label
      class="ms-1 form-check-label text-black"
      :for="strId || checkboxLabel"
      :class="fontSize"
    >
      {{ checkboxLabel }}
    </label>
  </div>
</template>

<script>
export default {
  emits: ["select"],
  props: {
    fontSize: { type: String, default: "fnt-body" },
    checkboxLabel: { type: String },
    checked: { type: Boolean },
    canChange: { type: Boolean },
    strId: { type: String },
    inactive: { type: Boolean },
    blnLookup: { type: Boolean, default: false },
  },
  data() {
    return {
      check: false,
      blnChange: false,
    };
  },
  mounted() {
    // this.check = this.checked
    if (this.blnLookup == true) {
      setTimeout(() => {
        // console.log(this.checked);
        this.check = this.checked;
        setTimeout(() => {
          this.onSelect();
        }, 3000);
      }, 1000);
    }
  },
  watch: {
    canChange: {
      deep: true,
      handler() {
        this.checker;
      },
    },
    checked:{
      deep: true,
      handler() {
        setTimeout(() => {
          this.check = this.checked;
          this.onSelect();
        }, 1000);
      },
    },
  },
  computed: {
    checker() {
      if (this.blnChange == true || this.canChange == true) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onSelect() {
      this.$emit("select", this.check);
    },
  },
};
</script>

<style scoped lang="scss">
.form-check {
  cursor: pointer !important;
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 2px !important;
    &:hover,
    &:focus {
      border: solid 1px $primary;
    }
  }
}
</style>

<template>
  <div class="otpCode" :class="size" v-if="device != 'mobile'">
    <div
      v-for="(digit, index) in digitbox"
      :key="index"
      class="d-flex align-items-center justify-content-center"
    >
      <input
        v-if="showTxtInp == false"
        :class="{
          otpCode__digit: !showSeparator(index),
          otpCode__digit__hypens: showSeparator(index),
          valid_box: alertMsgStatus[index],
        }"
        :type="type"
        maxlength="1"
        :value="
          finalCode == null || finalCode == undefined
            ? onfinalcCode(index)
            : finalCode[index]
        "
        :digitbox="digitbox"
        required
        @keypress="onKeypress"
        @keyup="onKeyup(index, $event.target.value, $event)"
        @keydown="onKeydown(index, $event)"
        ref="digits"
      />
      <input
        v-if="showTxtInp == true"
        :class="{
          otpCode__digit: !showSeparator(index),
          otpCode__digit__hypens: showSeparator(index),
        }"
        :type="type"
        maxlength="1"
        :value="
          finalCode == null || finalCode == undefined
            ? onfinalcCode(index)
            : finalCode[index]
        "
        :digitbox="digitbox"
        disabled
        required
        @keypress="onKeypress"
        @keyup="onKeyup(index, $event.target.value, $event)"
        @keydown="onKeydown(index, $event)"
        ref="digits"
      />

      <span v-if="showSeparator(index)" class="px-1">-</span>
    </div>
  </div>
  <div class="otpCode" :class="size" v-if="device == 'mobile'">
    <div
      v-for="(digit, index) in digitbox"
      :key="index"
      class="d-flex flex-row align-items-center justify-content-center"
    >
      <input
        v-if="showTxtInp == false"
        :class="{
          otpCode__digit_mobile: !showSeparator(index),
          otpCode__digit__hypens_mobile: showSeparator(index),
          valid_box: alertMsgStatus[index],
        }"
        :type="type"
        maxlength="1"
        :value="
          finalCode == null || finalCode == undefined
            ? onfinalcCode(index)
            : finalCode[index]
        "
        :digitbox="digitbox"
        required
        @keypress="onKeypress"
        @keyup="onKeyup(index, $event.target.value, $event)"
        @keydown="onKeydown(index, $event)"
        ref="digits"
      />
      <input
        v-if="showTxtInp == true"
        :class="{
          otpCode__digit_mobile: !showSeparator(index),
          otpCode__digit__hypens_mobile: showSeparator(index),
        }"
        :type="type"
        maxlength="1"
        :value="
          finalCode == null || finalCode == undefined
            ? onfinalcCode(index)
            : finalCode[index]
        "
        :digitbox="digitbox"
        disabled
        required
        @keypress="onKeypress"
        @keyup="onKeyup(index, $event.target.value, $event)"
        @keydown="onKeydown(index, $event)"
        ref="digits"
      />
    </div>
  </div>
  <div
    :class="[alertMsgStatus.includes(true) ? '' : 'd-none']"
    class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
  >
    <Icon
      :class="[alertMsgStatus.includes(true) ? '' : 'd-none']"
      icon="carbon:warning"
      class="me-1"
    />
    {{ alertMsgInput }}
  </div>
</template>
  
<script>
import { Icon } from "@iconify/vue";
//import Validation from "@/typescript/validator/validator";
export default {
  props: {
    digitbox: {
      type: Number,
    },
    type: { type: String, required: true },
    showHypens: { type: Array },
    value: { type: String },
    validate: { type: String },
    size: { type: String },
    showTxtInp: { type: Boolean, default: false }, // Input disuntedabled
  },

  computed: {
    showSeparator() {
      return (index) => this.showHypens.includes(index);
    },
  },
  components: {
    Icon,
  },
  mounted() {
    // console.log("mounted");
    if (this.value != null) {
      this.finalCode = this.value.split("");
    }
  },
  methods: {
    onfinalcCode(index) {
      // console.log(this.value);
      if (this.value != null) {
        this.finalCode = this.value.split("");
        return this.finalCode[index];
      } else {
        this.finalCode = [];
      }
    },
    alertRequest() {
      // console.log(this.finalCode);
      for (let index = 0; index < this.digitbox; index++) {
        if (this.$refs.digits[index].value == "") {
          this.alertMsgInput = this.$t("202") + this.$t("917");
          this.alertMsgStatus[index] = true;

          // console.log("alertMsgStatus :: " + this.alertMsgStatus);
        }
      }
    },
    Valid() {
      this.alertRequest();
    },
    onKeypress(e) {
      if (!this.isValid(e.key)) {
        e.preventDefault();
      }
    },
    onKeyup(index, value, event) {
      if (event.type === "keyup") {
        this.finalCode[index] = value;

        if (
          (this.isValid(event.key) || event.code.startsWith("Numpad")) &&
          this.finalCode[index] !== ""
        ) {
          this.alertMsgStatus[index] = false;
          this.$refs.digits[index + 1]?.focus();
        }
        this.$emit("input", this.finalCode.join(""));
        // console.log(this.finalCode.join(""));
        this.keyHeldDown = false;
      }
    },
    onKeydown(index, event) {
      // console.log(event.keyCode);

      if (this.keyHeldDown) {
        // Key is already being held down, do nothing
        return;
      }
      switch (event.keyCode) {
        case 8:
          this.handleBackspace(this.$refs.digits[index], index);
          break;
        case 37:
          this.focusPrev(this.$refs.digits[index], index);
          break;
        case 39:
          this.focusNext(this.$refs.digits[index], index);
          break;
        default:
          this.keyHeldDown = true;
          break;
      }
    },
    focusNext(currEle, index) {
      const next = this.$refs.digits[index + 1];
      if (next) {
        next.focus();
      }
    },
    focusPrev(currEle, index) {
      const prev = this.$refs.digits[index - 1];

      if (prev) {
        prev.focus();
      }
    },
    handleBackspace(currEle, index) {
      if (currEle.value === "" && this.finalCode[index] === "") {
        this.focusPrev(currEle, index);
      } else {
        this.finalCode[index] = "";
        // currEle.value = "";
      }
    },
    isValid(key) {
      return /^\d$/.test(key);
    },
    onclear() {
      this.finalCode.forEach((_,index) => {
        this.finalCode[index]=''
      });
      this.$emit("input", this.finalCode);
    },
  },
  data() {
    return {
      alertMsgStatus: [],
      alertMsgInput: "",
      finalCode: this.value,
      keyHeldDown: false,
    };
  },
};
</script>
<style lang="scss">
.otpCode {
  display: flex;
  // justify-content: center;
}
.otpCode_inline {
  align-items: center;
}
.otpCode__digit {
  border: 1px solid $black-500;
  border-radius: 4px;
  width: 35px;
  height: 40px;
  text-align: center;

  margin-right: 4px;

  line-height: 1.5;
  padding: 0;
  &:disabled {
    background-color: $black-200;
    border: 1px solid $black-400;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }
}
.valid_box {
  border: solid 1px $danger !important;
}
.otpCode__digit__hypens {
  border: 1px solid $black-500;
  border-radius: 4px;
  width: 35px;
  height: 40px;
  text-align: center;

  line-height: 1.5;
  padding: 0;
  &:disabled {
    background-color: $black-200;
    border: 1px solid $black-400;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }
}

.otpCode__digit_mobile {
  border: 1px solid $black-500;
  border-radius: 4px;
  width: 23px;
  height: 23px;
  text-align: center;
  margin-right: 1px;

  line-height: 1.5;
  padding: 0;
  font-size: 14px;
  &:disabled {
    background-color: $black-200;
    border: 1px solid $black-400;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }
}
.otpCode__digit__hypens_mobile {
  border: 1px solid $black-500;
  border-radius: 4px;
  width: 23px;
  height: 23px;
  text-align: center;
  margin-right: 1px;
  font-size: 14px;

  line-height: 1.5;
  padding: 0;
  &:disabled {
    background-color: $black-200;
    border: 1px solid $black-400;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }
}
</style>
<template>
  <div
    v-if="device != 'mobile'"
    class="d-flex w-100"
    :class="device == 'mobile' ? 'align-items-center' : ''"
  >
    <div
      class="dropFile d-flex align-items-center justify-content-center p-2 position-relative"
      :class="size"
      style="border: none !important"
    >
      <img :src="image" alt="" />
      <div
        class="d-flex justify-content-center align-items-center overlay-action"
      >
        <div class="d-flex justify-content-center align-items-center action">
          <Button
            icon="OpenEye"
            class="ms-2"
            classBtnIcon="fill-black-200"
            @click="showImage()"
          />
          <!-- <Button
            icon="DeleteFill"
            @click="removeImage()"
            classBtnIcon="fill-black-200"
            class="me-2"
            :class="canClick == true ? 'notclick d-none' : ''"
          /> -->
        </div>
      </div>
    </div>

    <div class="text-black fnt-body mt-2 mx-0 px-0">
      {{ ValidNumber(helper) ? helper : $t(helper) }}
    </div>
    <!-- <div
      class="text-danger mt-2 d-flex"
      :class="
        device == 'mobile'
          ? 'fnt-caption align-items-start'
          : 'fnt-body align-items-center'
      "
    >
      <Icon
        icon="carbon:warning"
        class="me-1"
        :class="device == 'mobile' ? 'm-1' : ''"
        v-if="msgError != '' || null"
      />
      {{ $t(msgError) }}
    </div> -->

    <div
      class="image-show d-flex align-items-center justify-content-center"
      @click="closeShowimg()"
      v-if="showimage"
    >
      <Button
        icon="CloseOutline"
        classBtnIcon="fill-white"
        widthIcon="40"
        heightIcon="40"
        class="close"
        classBtn="btn"
      />
      <img :src="image" alt="" />
    </div>

    <div class="" :class="device == 'mobile' ? '' : 'ms-5'">
      <inputFile
        name="JPEG"
        subname="JPEG"
        Labelsize="fnt-subtitle"
        :widthicon="48"
        @base64="filedata($event)"
        @input="dataOnremove($event)"
        :value="valueInput"
        :heighticon="48"
        filetype="image/jpeg"
        :size="size"
        maxSize="5000000"
        :canClick="canClick"
        :showTxtInp="showTxtInp"
        :alertMsg="alertMsg"
        :widthInp="widthInp"
        :heightInp="heightInp"
        ref="inputfiles"
      />
    </div>
  </div>
  <div v-if="device == 'mobile'" class="d-flex w-100 row">
    <div class="col p-1">
      <div
        class="dropFile d-flex align-items-center justify-content-center p-2 position-relative"
        :class="size"
        style="border: none !important"
      >
        <img :src="image" alt="" />
        <div
          class="d-flex justify-content-center align-items-center overlay-action"
        >
          <div class="d-flex justify-content-center align-items-center action">
            <Button
              icon="OpenEye"
              class="ms-2"
              classBtnIcon="fill-black-200"
              @click="showImage()"
            />
            <!-- <Button
            icon="DeleteFill"
            @click="removeImage()"
            classBtnIcon="fill-black-200"
            class="me-2"
            :class="canClick == true ? 'notclick d-none' : ''"
          /> -->
          </div>
        </div>
      </div>

      <div class="text-black fnt-body mt-2 mx-0 px-0">
        {{ ValidNumber(helper) ? helper : $t(helper) }}
      </div>

      <div
        class="image-show d-flex align-items-center justify-content-center"
        @click="closeShowimg()"
        v-if="showimage"
      >
        <Button
          icon="CloseOutline"
          classBtnIcon="fill-white"
          widthIcon="40"
          heightIcon="40"
          class="close"
          classBtn="btn"
        />
        <img :src="image" alt="" />
      </div>
    </div>

    <div class="col p-0">
      {{ widthInp }}
      {{ heightInp }}
      <inputFile
        name="JPEG"
        subname="JPEG"
        Labelsize="fnt-subtitle"
        :widthicon="48"
        @base64="filedata($event)"
        :heighticon="48"
        filetype="image/jpeg"
        :size="size"
        :value="valueInput"
        maxSize="5000000"
        :canClick="canClick"
        :showTxtInp="showTxtInp"
        :alertMsg="alertMsg"
        @input="dataOnremove($event)"
        :widthInp="widthInp"
        :heightInp="heightInp"
        ref="inputfiles"
      />
    </div>
  </div>
</template>
  
  <script>
// import { Icon } from "@iconify/vue";
import Button from "@/components/button/button.vue";
import inputFile from "./inputFile.vue";
// import { images } from "@/components/icon/index";

export default {
  data() {
    return {
      dropOver: false,
      image: "",
      msgError: "",
      showimage: false,
      fileImage: "",
      alertMsgStatus: false,
    };
  },
  props: {
    name: { type: String, default: "" },
    value: { type: String, required: true },
    valueInput: { type: String, required: true },
    alertMsg: { type: String },
    helper: { type: String },
    error: { type: String },
    maxSize: { type: Number, default: 2000000 },
    size: { type: String },
    widthicon: { type: String, default: "24" },
    heighticon: { type: String, default: "24" },
    subname: { type: String, default: "" },
    canClick: { type: Boolean, default: false },
    style: { type: String },
    filetype: { type: String, default: "image/jpeg,image/png" },
    blnShowInput: { type: Boolean, default: true },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    blnBase64: { type: Boolean, default: false },
    widthInp: { type: Number },
    heightInp: { type: Number },
  },
  components: {
    Button,
    // Icon,
    // images,
    inputFile,
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.value != null && this.value != "" && this.value != undefined) {
          this.image = this.value;
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.value != null && this.value != "" && this.value != undefined) {
        this.image = this.value;
        // console.log(this.value);
      }
      // console.log(this.valueInput)
    }, 1000);
  },
  methods: {
    closeShowimg() {
      if (this.image !== "") {
        this.showimage = false;
      }
    },
    showImage() {
      if (this.image !== "") {
        this.showimage = true;
      } else {
        this.showimage = false;
      }
      // console.log(this.showimage);
    },
    ValidNumber(value) {
      return isNaN(value);
    },
    Valid() {
      if (this.$refs.inputfiles != undefined) {
        if (this.$refs.inputfiles.image == "") {
          this.$refs.inputfiles.msgError = this.$t("202", {
            fieldname: this.$t("920").toLocaleLowerCase(),
          });
          this.alertMsgStatus = true;
        } else {
          this.alertMsgStatus = false;
        }
      }

      // return true;
    },
    alertRequest() {
      if (this.$refs.inputfiles != undefined) {
        if (this.$refs.inputfiles.image == "") {
          this.msgError = this.$t(this.alertMsg, {
            fieldname: this.$t(this.name).toLowerCase(),
          });
          this.alertMsgStatus = true;
        } else {
          this.alertMsgStatus = false;
        }
      }
    },
    // onInput(e){
    //   console.log(e)
    //   this.$emit("input", e);
    // },
    filedata(e) {
      // if (this.blnBase64 == true) {
      //   if (e != undefined || e != "" || e != null) {
      //     let base64 = e.split(",")[1];
      //     console.log(base64);
      //     this.$emit("input", base64);
      //   }
      // } else {
      this.$emit("input", e);
      // }
    },
    onFileChange(e) {
      let file = e.target.files || e.dataTransfer.files;
      // console.log(this.filetype);
      if (this.filetype == "image/svg+xml") {
        if (!file.length) return;
        if (file[0].type.indexOf("image/svg+xml") != 0) {
          this.msgError = "350";
        }

        if (file[0].size > this.maxSize) {
          this.msgError = this.$t("220", { filesize: this.maxSize / 1000000 });
        }

        if (
          file[0].type.indexOf("image/svg+xml") == 0 &&
          file[0].size < this.maxSize
        ) {
          this.alertMsgStatus = false;
          this.msgError = "";
          this.createImage(file[0]);
        }
      } else {
        if (!file.length) return;
        if (
          file[0].type.indexOf("image/jpeg") != 0 ||
          file[0].type.indexOf("image/png") != 0
        ) {
          this.msgError = "230";
        }

        if (file[0].size > this.maxSize) {
          this.msgError = this.$t("220", { filesize: this.maxSize / 1000000 });
        }

        if (
          (file[0].type.indexOf("image/jpeg") == 0 ||
            file[0].type.indexOf("image/png") == 0) &&
          file[0].size < this.maxSize
        ) {
          this.alertMsgStatus = false;
          this.msgError = "";
          this.createImage(file[0]);
        }
      }
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;
      // console.log(file);
      this.$emit("input", file);

      this.$emit("imgUrl", file);

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      this.image = "";
      this.$emit("input", null);
      console.log(e);
    },
    dataOnremove(event){
      if(event == null){
        this.$emit("input", null);
      }
    },
  },
};
</script>
  
  <style lang="scss">
.image-show {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 4px;
  }
  .close {
    position: absolute;
    top: 50px;
    right: 10%;
  }
}
.overlay-action {
  background: rgba(142, 142, 142, 0.65);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 150ms;
}
.dropFile {
  // width: 402px !important;
  // height: 422px !important;
  width: 86px !important;
  height: 86px !important;
  // border: dashed 1px $black-500 !important;
  border-radius: 4px !important;
  overflow: hidden;
  background: $white;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover {
    border-color: $primary !important;
    .overlay-action {
      opacity: 1;
      visibility: visible;
    }
  }
  &:active {
    background-color: $black-200;
    border-color: $primary !important;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
  }
  .iconUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    svg {
      font-size: 2rem;
    }
    span {
      font-size: 14px;
    }
  }
  .resize {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    svg {
      font-size: 18px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.drop-over {
  background-color: #cacaca !important;
  transition: 0.3s;
}
.drop-leave {
  background-color: #fff;
  transition: 0.3s;
}
.full-size {
  width: 100% !important;
  height: 100% !important;
}
.sizeLarge {
  width: 500px !important;
  height: 500px !important;
}
.sizeLarge-edit {
  width: 403px !important;
  height: 422px !important;
}
.sizeMedium {
  width: 99px !important;
  height: 114px !important;
}
.sizeSmall {
  max-width: 60px !important;
  max-height: 60px !important;
  border-radius: 2.5px !important;
  border: dashed 1px $black-500 !important;
  svg {
    width: 16px;
    height: 16px;
  }
  button {
    padding: 0 !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.sizeSmall-edit {
  max-width: 50px !important;
  max-height: 50px !important;
  border-radius: 2.5px !important;
  border: dashed 1px $black-500 !important;
  svg {
    width: 16px;
    height: 16px;
  }
  button {
    padding: 0 !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.label-vw {
  font-size: 0.9vw !important;
}
.fit-100 {
  width: 100% !important;
  height: 100% !important;
}
.drop-zone > div {
  width: 100%;
}
.notclick {
  pointer-events: none;
}
</style>
  
<template>
  <div>
    <div class="bg-white shadow-sm rounded p-5 mb-3" v-if="progress == 0">
      <!-- <Loading1 /> -->
      <div
        class="rounded-2 skeleton-item bg-black-200 mb-3"
        style="height: 70px; width: 100%"
      ></div>
      <div
        class="rounded-2 skeleton-item bg-black-200"
        style="height: 70px; width: 100%"
      ></div>
    </div>
    <div v-if="device != 'mobile' && progress == 100">
      <div
        class="py-4 px-5 p-0 shadow-sm bg-white rounded-2 w-100 mb-3 gx-3 m-0"
        v-for="(item, indexCard) in listuserinfoData"
        :key="indexCard"
      >
        <div class="d-flex">
          <div class="number me-3" v-if="blnShowIndex == true">
            <span>{{ indexCard + 1 }}</span>
          </div>
          <div class="row w-100">
            <div
              class="col-lg-3 col-sm-12 d-flex flex-column align-items-center justify-content-center"
            >
              <Avatar
                :image="
                  (filterUserField(item.listUserFieldData, 19) ||
                    filterUserField(item.listUserFieldData, 163) ||
                    filterUserField(item.listUserFieldData, 216)) ==
                  'https://cesfile.wu.ac.th/files'
                    ? undefined
                    : filterUserField(item.listUserFieldData, 19) ||
                      filterUserField(item.listUserFieldData, 163) ||
                      filterUserField(item.listUserFieldData, 216)
                "
                class="mb-2"
                :usertype="
                  item.strUserTypeGroupNameEN == 'Student'
                    ? 'student'
                    : item.strUserTypeGroupNameEN == 'Officer'
                    ? 'officer'
                    : ''
                "
                :blnvalue="
                  (filterUserField(item.listUserFieldData, 19) != '' &&
                    filterUserField(item.listUserFieldData, 19) !=
                      'https://cesfile.wu.ac.th/files') ||
                  (filterUserField(item.listUserFieldData, 163) != '' &&
                    filterUserField(item.listUserFieldData, 163) !=
                      'https://cesfile.wu.ac.th/files') ||
                  (filterUserField(item.listUserFieldData, 216) != '' &&
                    filterUserField(item.listUserFieldData, 216) !=
                      'https://cesfile.wu.ac.th/files')
                    ? false
                    : true
                "
              />
              <!-- Heading 3 -->
              <div
                class="contact mt-3"
                :class="
                  device == 'mobile'
                    ? 'd-flex flex-column align-items-start w-100'
                    : ''
                "
              >
                <div
                  class="d-flex align-items-center justify-content-start flex-wrap flex-column fnt-medium fnt-title"
                >
                  {{
                    $i18n.locale == "en"
                      ? item.strUserTypeGroupNameEN
                      : item.strUserTypeGroupNameTH
                  }}
                  <!-- {{
                  filterUserField(item.listUserFieldData, 39) == undefined ||
                  filterUserField(item.listUserFieldData, 39) == ""
                    ? filterUserField(item.listUserFieldData, 39)
                    : " (" + filterUserField(item.listUserFieldData, 39) + ")"
                }} -->
                </div>
                <div v-if="item.strUserTypeGroupNameEN == 'Student'">
                  {{ filterUserField(item.listUserFieldData, 39) }}
                  <!-- <Status
                  v-if="filterUserField(item.listUserFieldData, 228) == '1'"
                  :labelclass="
                    filterUserField(item.listUserFieldData, 228) == '1'
                      ? 'bg-success'
                      : 'bg-black-500'
                  "
                  class="justify-content-center"
                  :name="
                    filterUserField(item.listUserFieldData, 228) == '1'
                      ? 'สถานะปัจจุบัน'
                      : 'ไม่ได้ใช้งาน'
                  "
                /> -->
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 p-0">
              <div class="d-flex justify-content-between align-items cene">
                <div class="d-flex align-items-start justify-content-start">
                  <span
                    class="me-2 h-100 d-flex text-primary fnt-medium fnt-h6"
                    :class="device == 'mobile' ? 'fnt-title' : ''"
                  >
                    <!-- ID -->
                    <span>{{
                      filterUserField(item.listUserFieldData, 1) ||
                      filterUserField(item.listUserFieldData, 145) ||
                      filterUserField(item.listUserFieldData, 205)
                    }}</span>
                  </span>
                  <div class="me-2 text-primary fnt-medium fnt-h6">
                    <div
                      class="d-flex"
                      v-if="
                        (filterUserField(item.listUserFieldData, 8) ||
                          filterUserField(item.listUserFieldData, 152) ||
                          // filterUserField(item.listUserFieldData, 211) ||
                          filterUserField(item.listUserFieldData, 10) ||
                          filterUserField(item.listUserFieldData, 154)) !=
                        // ||
                        // filterUserField(item.listUserFieldData, 213)
                        ''
                      "
                    >
                      <!-- prefixEN -->
                      <div
                        :class="
                          (filterUserField(item.listUserFieldData, 7) ||
                            filterUserField(item.listUserFieldData, 151) ||
                            filterUserField(item.listUserFieldData, 210)) == ''
                            ? ''
                            : 'me-2 text-nowrap'
                        "
                      >
                        {{
                          filterUserField(item.listUserFieldData, 7) ||
                          filterUserField(item.listUserFieldData, 151) ||
                          filterUserField(item.listUserFieldData, 210)
                        }}
                      </div>
                      <!-- firstNameEN -->
                      <div
                        :class="
                          (filterUserField(item.listUserFieldData, 8) ||
                            filterUserField(item.listUserFieldData, 152)) ==
                          // ||
                          // filterUserField(item.listUserFieldData, 211)
                          ''
                            ? ''
                            : 'me-2'
                        "
                      >
                        {{
                          filterUserField(item.listUserFieldData, 8) ||
                          filterUserField(item.listUserFieldData, 152)
                        }}
                      </div>
                      <!-- middleNmaeEN -->
                      <div
                        v-if="filterUserField(item.listUserFieldData, 9) != ''"
                        class="me-2"
                      >
                        {{ filterUserField(item.listUserFieldData, 9) }}
                      </div>
                      <div
                        v-if="
                          filterUserField(item.listUserFieldData, 153) != ''
                        "
                        class="me-2"
                      >
                        {{ filterUserField(item.listUserFieldData, 153) }}
                      </div>
                      <!-- <div
                        v-if="
                          filterUserField(item.listUserFieldData, 212) != ''
                        "
                        class="me-2"
                      >
                        {{ filterUserField(item.listUserFieldData, 212) }}
                      </div> -->
                      <!-- lastNameEN -->
                      <div>
                        {{
                          filterUserField(item.listUserFieldData, 10) ||
                          filterUserField(item.listUserFieldData, 154)
                        }}
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      v-if="
                        (filterUserField(item.listUserFieldData, 4) ||
                          filterUserField(item.listUserFieldData, 148) ||
                          // filterUserField(item.listUserFieldData, 207) ||
                          filterUserField(item.listUserFieldData, 6) ||
                          filterUserField(item.listUserFieldData, 150)) != ''
                      "
                    >
                      <!-- prefixTH -->
                      <div
                        :class="
                          (filterUserField(item.listUserFieldData, 3) ||
                            filterUserField(item.listUserFieldData, 147) ||
                            filterUserField(item.listUserFieldData, 206)) == ''
                            ? ''
                            : 'me-2 text-nowrap'
                        "
                      >
                        {{
                          filterUserField(item.listUserFieldData, 3) ||
                          filterUserField(item.listUserFieldData, 147) ||
                          filterUserField(item.listUserFieldData, 206)
                        }}
                      </div>
                      <!-- firstNameTH -->
                      <div
                        :class="
                          (filterUserField(item.listUserFieldData, 4) ||
                            filterUserField(item.listUserFieldData, 148)) ==
                          // ||filterUserField(item.listUserFieldData, 207)
                          ''
                            ? ''
                            : 'me-2'
                        "
                      >
                        {{
                          filterUserField(item.listUserFieldData, 4) ||
                          filterUserField(item.listUserFieldData, 148)
                        }}
                      </div>
                      <!-- middleNmaeTH -->
                      <div
                        v-if="filterUserField(item.listUserFieldData, 5) != ''"
                        class="me-2"
                      >
                        <!-- middleNmaeTH(Thai Student) -->
                        {{ filterUserField(item.listUserFieldData, 5) }}
                      </div>
                      <div
                        v-if="
                          filterUserField(item.listUserFieldData, 149) != ''
                        "
                        class="me-2"
                      >
                        <!-- middleNmaeTH(Foreign Student) -->
                        {{ filterUserField(item.listUserFieldData, 149) }}
                      </div>
                      <!-- <div
                        v-if="
                          filterUserField(item.listUserFieldData, 208) != ''
                        "
                        class="me-2"
                      >
                        middleNmaeTH(Officer)
                        {{ filterUserField(item.listUserFieldData, 208) }}
                      </div> -->
                      <!-- lastNameTH -->
                      <div>
                        {{
                          filterUserField(item.listUserFieldData, 6) ||
                          filterUserField(item.listUserFieldData, 150)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <Button
                    v-if="blnHidEdit == false"
                    icon="OpenEye"
                    classBtn="btn pe-0"
                    classBtnIcon="fill-secondary"
                    @click="
                      previewuserinfo(item.intMemberID, item.intUserTemplateID)
                    "
                  />
                  <Button
                    v-if="blnHidPreview == false"
                    icon="EditFill"
                    classBtn="btn"
                    classBtnIcon="fill-secondary"
                    @input="
                      edituserinfo(
                        item.intMemberID,
                        item.intUserTypeID,
                        item.intUserTemplateID
                      )
                    "
                  />
                </div>
              </div>

              <hr style="border: solid 1px $black" class="w-100" />
              <div class="d-flex justify-content-between align-items w-100">
                <div class="detail-userinfo d-flex flex-column w-100">
                  <!-- ระดับการศึกษา -->
                  <!-- <div
                    class="row w-100"
                    v-if="
                      filterUserField(item.listUserFieldData, 39) != '' &&
                      filterUserField(item.listUserFieldData, 39) !=
                        undefined &&
                      strAction == 'Update'
                    "
                  >
                    <div
                      class="col-2 me-2 h-100 text-nowrap fnt-medium fnt-title"
                      :class="device == 'mobile' ? 'fnt-title' : ''"
                    >
                      {{ filterUserField(item.listUserFieldData, 39, true) }}
                    </div>
                    <div class="col me-2 fnt-regular fnt-title">
                      ระดับการศึกษา
                      <span class="me-3 d-flex">
                        <span class="me-2">:</span>
                        {{ filterUserField(item.listUserFieldData, 39) }}
                      </span>
                    </div>
                  </div> -->
                  <!-- หลักสูตร -->
                  <div
                    class="row w-100"
                    v-if="
                      (filterUserField(item.listUserFieldData, 41) ||
                        filterUserField(item.listUserFieldData, 40)) != '' &&
                      (filterUserField(item.listUserFieldData, 41) ||
                        filterUserField(item.listUserFieldData, 40)) !=
                        undefined
                    "
                  >
                    <div
                      class="me-2 h-100 text-nowrap fnt-medium fnt-title"
                      :class="
                        ((filterUserField(item.listUserFieldData, 20) ||
                          filterUserField(item.listUserFieldData, 161) ||
                          filterUserField(item.listUserFieldData, 214)) !=
                          undefined &&
                          (filterUserField(item.listUserFieldData, 20) ||
                            filterUserField(item.listUserFieldData, 161) ||
                            filterUserField(item.listUserFieldData, 214)) !=
                            '') ||
                        ((filterUserField(item.listUserFieldData, 23) ||
                          filterUserField(item.listUserFieldData, 226) ||
                          filterUserField(item.listUserFieldData, 215)) !=
                          undefined &&
                          (filterUserField(item.listUserFieldData, 23) ||
                            filterUserField(item.listUserFieldData, 226) ||
                            filterUserField(item.listUserFieldData, 215)) != '')
                          ? 'col-2'
                          : 'col-1'
                      "
                    >
                      {{ filterUserField(item.listUserFieldData, 41, true) }}
                    </div>
                    <div
                      class="col me-2 fnt-regular fnt-title"
                      :class="
                        ((filterUserField(item.listUserFieldData, 20) ||
                          filterUserField(item.listUserFieldData, 161) ||
                          filterUserField(item.listUserFieldData, 214)) !=
                          undefined &&
                          (filterUserField(item.listUserFieldData, 20) ||
                            filterUserField(item.listUserFieldData, 161) ||
                            filterUserField(item.listUserFieldData, 214)) !=
                            '') ||
                        ((filterUserField(item.listUserFieldData, 23) ||
                          filterUserField(item.listUserFieldData, 226) ||
                          filterUserField(item.listUserFieldData, 215)) !=
                          undefined &&
                          (filterUserField(item.listUserFieldData, 23) ||
                            filterUserField(item.listUserFieldData, 226) ||
                            filterUserField(item.listUserFieldData, 215)) != '')
                          ? ''
                          : 'ps-5'
                      "
                    >
                      <!-- หลักสูตร -->
                      <span class="me-3 d-flex">
                        <span class="me-2">:</span>
                        {{ filterUserField(item.listUserFieldData, 41) }}
                        {{ filterUserField(item.listUserFieldData, 40) }}
                      </span>
                    </div>
                  </div>
                  <!-- ข้อมูลติดต่อ -->
                  <div class="row w-100">
                    <div
                      class="col-2 me-2 h-100 text-nowrap fnt-medium fnt-title"
                      :class="device == 'mobile' ? 'fnt-title' : ''"
                      v-if="
                        ((filterUserField(item.listUserFieldData, 20) ||
                          filterUserField(item.listUserFieldData, 161) ||
                          filterUserField(item.listUserFieldData, 214)) !=
                          undefined &&
                          (filterUserField(item.listUserFieldData, 20) ||
                            filterUserField(item.listUserFieldData, 161) ||
                            filterUserField(item.listUserFieldData, 214)) !=
                            '') ||
                        ((filterUserField(item.listUserFieldData, 23) ||
                          filterUserField(item.listUserFieldData, 226) ||
                          filterUserField(item.listUserFieldData, 215)) !=
                          undefined &&
                          (filterUserField(item.listUserFieldData, 23) ||
                            filterUserField(item.listUserFieldData, 226) ||
                            filterUserField(item.listUserFieldData, 215)) != '')
                      "
                    >
                      {{
                        // filterUserField(item.listUserFieldData, 21, true) ||
                        // filterUserField(item.listUserFieldData, 161, true) ||
                        // filterUserField(item.listUserFieldData, 214, true)
                        $i18n.locale == "en" ? "Contact" : "ข้อมูลติดต่อ"
                      }}
                    </div>
                    <div class="col me-2 fnt-regular fnt-title">
                      <!-- เบอร์โทร -->
                      <span
                        class="me-3"
                        v-if="
                          (filterUserField(item.listUserFieldData, 20) ||
                            filterUserField(item.listUserFieldData, 161) ||
                            filterUserField(item.listUserFieldData, 214)) !=
                            undefined &&
                          (filterUserField(item.listUserFieldData, 20) ||
                            filterUserField(item.listUserFieldData, 161) ||
                            filterUserField(item.listUserFieldData, 214)) != ''
                        "
                      >
                        <span class="me-2">:</span>
                        <PhoneOutline width="24" class="me-1" />
                        <!-- {{ infoData.strPhone }} -->
                        {{
                          filterUserField(item.listUserFieldData, 20) ||
                          filterUserField(item.listUserFieldData, 161) ||
                          filterUserField(item.listUserFieldData, 214)
                        }}
                      </span>
                      <!--อีเมล -->
                      <span
                        class="me-3"
                        v-if="
                          (filterUserField(item.listUserFieldData, 23) ||
                            filterUserField(item.listUserFieldData, 226) ||
                            filterUserField(item.listUserFieldData, 215)) !=
                            undefined &&
                          (filterUserField(item.listUserFieldData, 23) ||
                            filterUserField(item.listUserFieldData, 226) ||
                            filterUserField(item.listUserFieldData, 215)) != ''
                        "
                      >
                        <EmailOutline
                          width="24"
                          :class="device == 'mobile' ? 'me-2' : ''"
                        />
                        {{
                          filterUserField(item.listUserFieldData, 23) ||
                          filterUserField(item.listUserFieldData, 226) ||
                          filterUserField(item.listUserFieldData, 215)
                        }}
                      </span>
                    </div>
                  </div>
                  <!-- ตำแหน่ง -->
                  <div
                    class="row w-100 d-flex flex-nowrap"
                    v-if="
                      filterUserField(item.listUserFieldData, 218) !=
                        undefined &&
                      filterUserField(item.listUserFieldData, 218) != '' &&
                      filterUserField(item.listUserFieldData, 219) !=
                        undefined &&
                      filterUserField(item.listUserFieldData, 219) != ''
                    "
                  >
                    <div
                      class="col-2 me-2 h-100 text-nowrap fnt-medium fnt-title"
                    >
                      <!-- {{ 'ตำแหน่ง' }} -->
                      {{ filterUserField(item.listUserFieldData, 218, true) }}
                    </div>
                    <div class="col me-2 fnt-regular fnt-title">
                      <div class="" v-if="pregressPosition == 0">
                        <div
                          class="rounded-2 skeleton-item bg-black-200 mb-2 w-100"
                          style="height: 32px"
                        >
                          <div class="animated-background rounded-2"></div>
                        </div>
                        <div
                          class="rounded-2 skeleton-item bg-black-200"
                          style="height: 32px"
                        >
                          <div class="animated-background rounded-2"></div>
                        </div>
                      </div>

                      <!-- ตำแหน่ง -->
                      <div
                        class="d-flex flex-column"
                        v-if="pregressPosition == 100"
                      >
                        <span
                          class="me-3"
                          v-for="(Position, index) in filterUserField(
                            item.listUserFieldData,
                            218
                          )"
                          :key="index"
                        >
                          <div
                            class="d-flex"
                            :class="
                              index != 0
                                ? StatusPosition(index, item.intMemberID) ==
                                  'bg-black-500'
                                  ? 'd-none'
                                  : ''
                                : ''
                            "
                            :ref="'position' + indexCard"
                          >
                            <span class="me-2" v-if="index == 0">:</span>
                            <span
                              class="d-flex text-nowrap"
                              :class="index > 0 ? 'ms-2 ps-1' : ''"
                            >
                              <Status
                                :labelclass="
                                  StatusPosition(index, item.intMemberID)
                                "
                                :Editclass="
                                  device == 'mobile'
                                    ? 'align-items-start mt-1 pt-1'
                                    : 'align-items-start mt-1 pt-2'
                                "
                              />
                              <span
                                class="text-wrap"
                                v-for="(Agency, i) in filterUserField(
                                  item.listUserFieldData,
                                  219
                                )"
                                :key="i"
                              >
                                <span
                                  v-if="Position.grouplink == Agency.grouplink"
                                  >{{ Position.position + " " + Agency.Agency }}
                                </span>
                              </span>
                            </span>
                          </div>
                          <Button
                            v-if="
                              filterUserField(item.listUserFieldData, 218)
                                .length > 1 &&
                              filterUserField(item.listUserFieldData, 218)
                                .length ==
                                index + 1 &&
                              StatusPosition(index, item.intMemberID) ==
                                'bg-black-500'
                            "
                            @click="ShowAllPosition(indexCard, $event)"
                            :name="$t('1164')"
                            classBtn="ms-2 ps-3 pt-2 btn-hover"
                            className="text-secondary"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="device == 'mobile' && progress == 100">
      <div
        class="p-3 shadow-sm bg-white rounded-2 w-100 mb-2"
        v-for="(item, indexCard) in listuserinfoData"
        :key="indexCard"
      >
        <div class="row">
          <div class="number-mobile col">
            <span v-if="blnShowIndex == true">{{ indexCard + 1 }}</span>
          </div>
          <div class="col">
            <Avatar
              :image="
                (filterUserField(item.listUserFieldData, 19) ||
                  filterUserField(item.listUserFieldData, 163) ||
                  filterUserField(item.listUserFieldData, 216)) ==
                'https://cesfile.wu.ac.th/files'
                  ? undefined
                  : filterUserField(item.listUserFieldData, 19) ||
                    filterUserField(item.listUserFieldData, 163) ||
                    filterUserField(item.listUserFieldData, 216)
              "
              class="mb-2"
              size="medium-mobile"
              :usertype="
                item.strUserTypeGroupNameEN == 'Student'
                  ? 'student'
                  : item.strUserTypeGroupNameEN == 'Officer'
                  ? 'officer'
                  : ''
              "
              :blnvalue="
                (filterUserField(item.listUserFieldData, 19) != '' &&
                  filterUserField(item.listUserFieldData, 19) !=
                    'https://cesfile.wu.ac.th/files') ||
                (filterUserField(item.listUserFieldData, 163) != '' &&
                  filterUserField(item.listUserFieldData, 163) !=
                    'https://cesfile.wu.ac.th/files') ||
                (filterUserField(item.listUserFieldData, 216) != '' &&
                  filterUserField(item.listUserFieldData, 216) !=
                    'https://cesfile.wu.ac.th/files')
                  ? false
                  : true
              "
            />
          </div>
          <div class="col text-end">
            <div>
              <Button
                v-if="blnHidEdit == false"
                icon="OpenEye"
                classBtn="btn pe-0"
                classBtnIcon="fill-secondary"
                @click="
                  previewuserinfo(item.intMemberID, item.intUserTemplateID)
                "
              />
              <Button
                v-if="blnHidPreview == false"
                icon="EditFill"
                classBtn="btn pe-0 ps-1"
                classBtnIcon="fill-secondary"
                @input="
                  edituserinfo(
                    item.intMemberID,
                    item.intUserTypeID,
                    item.intUserTemplateID
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="mt-2 mb-3">
          <div
            class="d-flex align-items-center justify-content-start flex-wrap flex-column number-mobile"
          >
            {{
              $i18n.locale == "en"
                ? item.strUserTypeGroupNameEN
                : item.strUserTypeGroupNameTH
            }}
            <!-- {{
            filterUserField(item.listUserFieldData, 39) == undefined ||
            filterUserField(item.listUserFieldData, 39) == ""
              ? filterUserField(item.listUserFieldData, 39)
              : " (" + filterUserField(item.listUserFieldData, 39) + ")"
          }} -->
          </div>
          <div
            v-if="item.strUserTypeGroupNameEN == 'Student'"
            class="d-flex align-items-center justify-content-start flex-wrap flex-column"
          >
            {{ filterUserField(item.listUserFieldData, 39) }}
            <!-- <Status
            v-if="filterUserField(item.listUserFieldData, 228) == '1'"
            :labelclass="
              filterUserField(item.listUserFieldData, 228) == '1'
                ? 'bg-success'
                : 'bg-black-500'
            "
            class="justify-content-center"
            :name="
              filterUserField(item.listUserFieldData, 228) == '1'
                ? 'สถานะปัจจุบัน'
                : 'ไม่ได้ใช้งาน'
            "
          /> -->
          </div>
        </div>
        <div class="d-flex border-bottom border-1 pb-3 mb-3">
          <!-- ID -->
          <span class="text-primary fnt-medium fnt-subtitle me-2">{{
            filterUserField(item.listUserFieldData, 1) ||
            filterUserField(item.listUserFieldData, 145) ||
            filterUserField(item.listUserFieldData, 205)
          }}</span>
          <div class="me-1 text-primary fnt-medium fnt-subtitle">
            <div
              class="d-flex gap-1 flex-wrap"
              v-if="
                (filterUserField(item.listUserFieldData, 8) ||
                  filterUserField(item.listUserFieldData, 152) ||
                  // filterUserField(item.listUserFieldData, 211) ||
                  filterUserField(item.listUserFieldData, 10) ||
                  filterUserField(item.listUserFieldData, 154)) !=
                // ||
                // filterUserField(item.listUserFieldData, 213)
                ''
              "
            >
              <!-- prefixEN -->
              <div>
                {{
                  filterUserField(item.listUserFieldData, 7) ||
                  filterUserField(item.listUserFieldData, 151) ||
                  filterUserField(item.listUserFieldData, 210)
                }}
              </div>
              <!-- firstNameEN -->
              <div>
                {{
                  filterUserField(item.listUserFieldData, 8) ||
                  filterUserField(item.listUserFieldData, 152)
                }}
              </div>
              <!-- middleNmaeEN -->
              <div v-if="filterUserField(item.listUserFieldData, 9) != ''">
                {{ filterUserField(item.listUserFieldData, 9) }}
              </div>
              <div v-if="filterUserField(item.listUserFieldData, 153) != ''">
                {{ filterUserField(item.listUserFieldData, 153) }}
              </div>
              <!-- <div v-if="filterUserField(item.listUserFieldData, 212) != ''">
                {{ filterUserField(item.listUserFieldData, 212) }}
              </div> -->
              <!-- lastNameEN -->
              <div>
                {{
                  filterUserField(item.listUserFieldData, 10) ||
                  filterUserField(item.listUserFieldData, 154)
                }}
              </div>
            </div>
            <div
              class="d-flex gap-1 flex-wrap"
              v-if="
                (filterUserField(item.listUserFieldData, 4) ||
                  filterUserField(item.listUserFieldData, 148) ||
                  // filterUserField(item.listUserFieldData, 207) ||
                  filterUserField(item.listUserFieldData, 6) ||
                  filterUserField(item.listUserFieldData, 150)) !=
                // ||
                // filterUserField(item.listUserFieldData, 209)
                ''
              "
            >
              <!-- prefixTH -->
              <div>
                {{
                  filterUserField(item.listUserFieldData, 3) ||
                  filterUserField(item.listUserFieldData, 147) ||
                  filterUserField(item.listUserFieldData, 206)
                }}
              </div>
              <!-- firstNameTH -->
              <div>
                {{
                  filterUserField(item.listUserFieldData, 4) ||
                  filterUserField(item.listUserFieldData, 148)
                }}
              </div>
              <!-- middleNmaeTH -->
              <div v-if="filterUserField(item.listUserFieldData, 5) != ''">
                <!-- middleNmaeTH(Thai Student) -->
                {{ filterUserField(item.listUserFieldData, 5) }}
              </div>
              <div v-if="filterUserField(item.listUserFieldData, 149) != ''">
                <!-- middleNmaeTH(Foreign Student) -->
                {{ filterUserField(item.listUserFieldData, 149) }}
              </div>
              <!-- <div v-if="filterUserField(item.listUserFieldData, 208) != ''">
                middleNmaeTH(Officer)
                {{ filterUserField(item.listUserFieldData, 208) }}
              </div> -->
              <!-- lastNameTH -->
              <div>
                {{
                  filterUserField(item.listUserFieldData, 6) ||
                  filterUserField(item.listUserFieldData, 150)
                }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between align-items w-100">
            <div class="detail-userinfo d-flex flex-column w-100">
              <!-- ระดับการศึกษา -->
              <!-- <div
                class="row w-100"
                v-if="
                  filterUserField(item.listUserFieldData, 39) != '' &&
                  filterUserField(item.listUserFieldData, 39) != undefined &&
                  strAction == 'Update'
                "
              >
                <div
                  class="me-2 h-100 d-flex fnt-medium fnt-body"
                  :class="device == 'mobile' ? 'col-4 pe-0' : 'col-2'"
                >
                  {{ filterUserField(item.listUserFieldData, 39, true) }}
                </div>
                <div
                  class="col me-2 fnt-regular fnt-body"
                  :class="device == 'mobile' ? 'p-0' : ''"
                >
                  <span class="me-3">
                    <span class="me-2">:</span>
                    {{ filterUserField(item.listUserFieldData, 39) }}
                  </span>
                </div>
              </div> -->
              <!-- หลักสูตร -->
              <div
                class="row w-100"
                v-if="
                  (filterUserField(item.listUserFieldData, 41) ||
                    filterUserField(item.listUserFieldData, 40)) != '' &&
                  (filterUserField(item.listUserFieldData, 41) ||
                    filterUserField(item.listUserFieldData, 40)) != undefined
                "
              >
                <div
                  class="me-2 h-100 d-flex fnt-medium fnt-body"
                  :class="device == 'mobile' ? 'col-4 pe-0' : 'col-2'"
                >
                  {{ filterUserField(item.listUserFieldData, 41, true) }}
                </div>
                <div
                  class="col me-2 fnt-regular fnt-body"
                  :class="device == 'mobile' ? 'p-0' : ''"
                >
                  <!-- หลักสูตร -->
                  <span class="me-3 d-flex">
                    <span class="me-2">:</span>
                    {{ filterUserField(item.listUserFieldData, 41) }}
                    {{ filterUserField(item.listUserFieldData, 40) }}
                  </span>
                </div>
              </div>
              <!-- ข้อมูลติดต่อ -->
              <div class="row w-100">
                <div
                  class="me-2 h-100 d-flex fnt-medium fnt-body"
                  :class="
                    device == 'mobile' ? 'col-4 pe-0 text-nowrap' : 'col-2'
                  "
                  v-if="
                    ((filterUserField(item.listUserFieldData, 20) ||
                      filterUserField(item.listUserFieldData, 161) ||
                      filterUserField(item.listUserFieldData, 214)) !=
                      undefined &&
                      (filterUserField(item.listUserFieldData, 20) ||
                        filterUserField(item.listUserFieldData, 161) ||
                        filterUserField(item.listUserFieldData, 214)) != '') ||
                    ((filterUserField(item.listUserFieldData, 23) ||
                      filterUserField(item.listUserFieldData, 226) ||
                      filterUserField(item.listUserFieldData, 215)) !=
                      undefined &&
                      (filterUserField(item.listUserFieldData, 23) ||
                        filterUserField(item.listUserFieldData, 226) ||
                        filterUserField(item.listUserFieldData, 215)) != '')
                  "
                >
                  {{
                    // filterUserField(item.listUserFieldData, 21, true) ||
                    // filterUserField(item.listUserFieldData, 161, true) ||
                    // filterUserField(item.listUserFieldData, 214, true)
                    $i18n.locale == "en" ? "Contact" : "ข้อมูลติดต่อ"
                  }}
                </div>
                <div
                  class="col fnt-regular fnt-body d-flex flex-column"
                  :class="device == 'mobile' ? 'p-0' : 'me-2'"
                >
                  <!-- เบอร์โทร -->
                  <span
                    class="me-3"
                    v-if="
                      (filterUserField(item.listUserFieldData, 20) ||
                        filterUserField(item.listUserFieldData, 161) ||
                        filterUserField(item.listUserFieldData, 214)) !=
                        undefined &&
                      (filterUserField(item.listUserFieldData, 20) ||
                        filterUserField(item.listUserFieldData, 161) ||
                        filterUserField(item.listUserFieldData, 214)) != ''
                    "
                  >
                    <span class="me-2">:</span>
                    <PhoneOutline width="24" class="me-1" />
                    <!-- {{ infoData.strPhone }} -->
                    {{
                      filterUserField(item.listUserFieldData, 20) ||
                      filterUserField(item.listUserFieldData, 161) ||
                      filterUserField(item.listUserFieldData, 214)
                    }}
                  </span>
                  <!--อีเมล -->
                  <span
                    class=""
                    :class="device == 'mobile' ? 'ms-2' : 'me-3 ms-2'"
                    v-if="
                      (filterUserField(item.listUserFieldData, 23) ||
                        filterUserField(item.listUserFieldData, 226) ||
                        filterUserField(item.listUserFieldData, 215)) !=
                        undefined &&
                      (filterUserField(item.listUserFieldData, 23) ||
                        filterUserField(item.listUserFieldData, 226) ||
                        filterUserField(item.listUserFieldData, 215)) != ''
                    "
                  >
                    <!-- <span class="me-1"></span> -->
                    <EmailOutline
                      width="24"
                      class=""
                      :class="device == 'mobile' ? 'me-2' : 'me-0'"
                    />
                    <span>{{
                      filterUserField(item.listUserFieldData, 23) ||
                      filterUserField(item.listUserFieldData, 226) ||
                      filterUserField(item.listUserFieldData, 215)
                    }}</span>
                  </span>
                </div>
              </div>
              <!-- ตำแหน่ง -->
              <div class="row w-100 d-flex flex-nowrap">
                <div
                  class="me-2 h-100 d-flex fnt-medium fnt-body"
                  :class="device == 'mobile' ? 'fnt-body col-4' : 'col-2'"
                >
                  <!-- {{ 'ตำแหน่ง' }} -->
                  {{ filterUserField(item.listUserFieldData, 218, true) }}
                </div>
                <div
                  class="col me-2 fnt-regular fnt-body"
                  :class="device == 'mobile' ? 'p-0' : ''"
                >
                  <!-- ตำแหน่ง -->
                  <div>
                    <div
                      class="me-3 d-flex"
                      v-for="(Position, index) in filterUserField(
                        item.listUserFieldData,
                        218
                      )"
                      :key="index"
                    >
                      <div
                        class="d-flex"
                        :class="
                          StatusPosition(index, item.intMemberID) ==
                          'bg-black-500'
                            ? 'd-none'
                            : ''
                        "
                        :ref="'position' + indexCard"
                      >
                        <span class="me-2" v-if="index == 0">:</span>
                        <span
                          class="d-flex text-nowrap"
                          :class="index > 0 ? 'ms-2 ps-1' : ''"
                        >
                          <Status
                            :labelclass="
                              StatusPosition(index, item.intMemberID)
                            "
                            class="pt-1"
                          />
                          <span
                            class="text-wrap"
                            v-for="(Agency, i) in filterUserField(
                              item.listUserFieldData,
                              219
                            )"
                            :key="i"
                          >
                            <span v-if="Position.grouplink == Agency.grouplink">
                              {{ Position.position + " " + Agency.Agency }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <Button
                      v-if="
                        filterUserField(item.listUserFieldData, 218).length > 1
                      "
                      @click="ShowAllPosition(indexCard, $event)"
                      :name="$t('1164')"
                      classBtn="ms-2 ps-3 pt-2 btn-hover"
                      className="text-secondary fnt-body"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/avatar.vue";
import Button from "@/components/button/button.vue";
import { PhoneOutline, EmailOutline } from "@/components/icon/index";
// import Loading1 from "@/components/Skeleton.vue";
import Status from "@/components/label/statusLabel.vue";

export default {
  components: {
    Avatar,
    Button,
    PhoneOutline,
    EmailOutline,
    // Loading1,
    Status,
  },
  props: {
    listuserinfoData: { type: Object },
    blnShowIndex: { type: Boolean, default: true },
    blnHidEdit: { type: Boolean, default: false },
    blnHidPreview: { type: Boolean, default: false },
    strAction: { type: String },
  },
  data() {
    return {
      progress: 100,
      listEndPosition: [],
      listLicense: [],
      pregressPosition: 0,
    };
  },
  mounted() {
    // this.processLoadingData();
    this.precessPosition();
  },
  methods: {
    processLoadingData() {
      this.progress = 0;
      setTimeout(() => {
        this.progress = 100;
      }, 1000);
    },
    precessPosition() {
      this.pregressPosition = 0;
      setTimeout(() => {
        this.pregressPosition = 100;
      }, 800);
    },
    edituserinfo(intMemberID, typeID, intUserTemplateID) {
      // console.log(intMemberID);
      this.$emit("edituserinfo", {
        intMemberID: intMemberID,
        typeID: typeID,
        intUserTemplateID: intUserTemplateID,
      });
    },
    previewuserinfo(intMemberID, typeID) {
      // console.log(intMemberID);
      this.$emit("previewuserinfo", {
        intMemberID: intMemberID,
        typeID: typeID,
      });
    },
    filterUserField(listUserfield, id, UserTypeName) {
      let listUserfieldData = "";
      let listPosition = [];
      this.listEndPosition = [];
      let listagency = [];
      Object.values(listUserfield).forEach((data) => {
        if (data.userfieldID == id) {
          if (UserTypeName != true && data.strData != "") {
            if (id == 218) {
              // ตำแหน่ง
              listPosition.push({
                position:
                  this.$i18n.locale == "th"
                    ? JSON.parse(data.strData).strNameTH
                    : JSON.parse(data.strData).strNameEN,
                status: false,
                grouplink: data.intGroupLink,
              });
              listPosition.sort((a, b) => {
                return a.grouplink - b.grouplink;
              });
              // console.log(listPosition);
            } else if (id == 219) {
              // หน่วยงาน
              listagency.push({
                Agency:
                  this.$i18n.locale == "th"
                    ? JSON.parse(data.strData).strNameTH
                    : JSON.parse(data.strData).strNameEN,
                grouplink: data.intGroupLink,
              });
            } else {
              if (
                data.userfieldID == 3 ||
                data.userfieldID == 147 ||
                data.userfieldID == 206
              ) {
                // Prefix(TH)
                listUserfieldData = JSON.parse(data.strData).strNameTH;
              } else if (
                data.userfieldID == 7 ||
                data.userfieldID == 151 ||
                data.userfieldID == 210
              ) {
                // Prefix(EN)
                listUserfieldData = JSON.parse(data.strData).strNameEN;
              } else if (data.userfieldID == 39) {
                // ระดับการศึกษา
                listUserfieldData =
                  this.$i18n.locale == "th"
                    ? JSON.parse(data.strData).strNameTH
                    : JSON.parse(data.strData).strNameEN;
              } else if (data.userfieldID == 40 || data.userfieldID == 41) {
                // หลักสูตร+สำนักวิชา
                listUserfieldData =
                  this.$i18n.locale == "th"
                    ? JSON.parse(data.strData).strNameTH
                    : JSON.parse(data.strData).strNameEN;
              } else {
                listUserfieldData = data.strData;
              }
            }
          } else if (UserTypeName == true) {
            listUserfieldData =
              this.$i18n.locale == "en" ? data.strLabelENG : data.strLabelTHAI;
          }

          // if (UserTypeName != true) {
          //   if (id == 221) {
          //     this.listEndPosition.push(data.strData);
          //     console.log(this.listEndPosition);
          //   }
          // }
        }
      });
      if (id == 218) {
        if (UserTypeName != true) {
          return listPosition;
        } else if (UserTypeName == true) {
          return listUserfieldData;
        }
      } else if (id == 219) {
        // console.log(listagency);
        return listagency;
      } else {
        return listUserfieldData;
      }
    },
    hidPosition(index) {
      // this.pregressPosition = 0;
      let blnHidePosition = false;
      console.log(index);
      // this.listEndPosition = [];
      // this.listLicense = [];
      // this.listuserinfoData.forEach((userinfo) => {
      //   userinfo.listUserFieldData.forEach((data) => {
      //     if (data.userfieldID == 221) {
      //       // วันสิ้นสุดตำแหน่ง
      //       this.listEndPosition.push(data.strData);
      //     }
      //     if (data.userfieldID == 222) {
      //       // สิทะฺการใช้งานตำแหน่ง
      //       this.listLicense.push(data.strData);
      //     }
      //   });
      // });

      // console.log(index,this.listLicense[index])
      // // ตรวจสอบสิทธืการใช้งานตำแหน่ง
      // if (this.listLicense[index] == 0) {
      //   blnHidePosition = false;
      // } else {
      //   blnHidePosition = true;

      //   // ตรวจสอบวันสิ้นสุดดำรงตำแหน่ง
      //   if (
      //     this.listEndPosition[index] != "" &&
      //     this.listEndPosition[index] != undefined
      //   ) {
      //     let EndPositionDate = parseInt(
      //       this.listEndPosition[index]?.split("/")[0]
      //     );
      //     let EndPositionMonth = parseInt(
      //       this.listEndPosition[index]?.split("/")[1]
      //     );
      //     let EndPositionYear = parseInt(
      //       this.listEndPosition[index]?.split("/")[2] - 543
      //     );
      //     let nowDate = new Date().getDate();
      //     let nowMonth = new Date().getMonth();
      //     let nowYeare = new Date().getFullYear();

      //     if (EndPositionYear < nowYeare) {
      //       blnHidePosition = false;
      //       // return false;
      //     } else if (EndPositionYear >= nowYeare) {
      //       if (EndPositionMonth < nowMonth) {
      //         blnHidePosition = false;
      //         // return false;
      //       } else if (EndPositionMonth >= nowMonth) {
      //         if (EndPositionDate < nowDate) {
      //           blnHidePosition = false;
      //           // return false;
      //         } else {
      //           blnHidePosition = true;
      //           // return true;
      //         }
      //       }
      //     }
      //   } else if (
      //     this.listEndPosition[index] == "" ||
      //     this.listEndPosition[index] == undefined
      //   ) {
      //     blnHidePosition = true;
      //     // return true;
      //   }
      // }

      // this.pregressPosition = 100;

      blnHidePosition = true;
      return blnHidePosition;
    },
    StatusPosition(index, memberID) {
      let strColorStatus = "bg-success";
      let listEndPosition = [];
      this.listuserinfoData.forEach((userinfo) => {
        userinfo.listUserFieldData.forEach((data) => {
          if (data.userfieldID == 221 && userinfo.intMemberID == memberID) {
            // วันสิ้นสุดตำแหน่ง
            listEndPosition.push(data.strData);
          }
        });
      });
      // ตรวจสอบวันสิ้นสุดดำรงตำแหน่ง
      if (listEndPosition[index] != "" && listEndPosition[index] != undefined) {
        let EndPositionDate = parseInt(listEndPosition[index]?.split("/")[0]);
        let EndPositionMonth = parseInt(listEndPosition[index]?.split("/")[1]);
        let EndPositionYear = parseInt(
          listEndPosition[index]?.split("/")[2] - 543
        );
        let nowDate = new Date().getDate();
        let nowMonth = new Date().getMonth();
        let nowYeare = new Date().getFullYear();

        // console.log(EndPositionYear, EndPositionMonth, EndPositionDate);
        // console.log(nowYeare, nowMonth, nowDate);
        if (EndPositionYear < nowYeare) {
          strColorStatus = "bg-black-500";
        } else if (EndPositionYear >= nowYeare) {
          if (EndPositionMonth == nowMonth) {
            if (EndPositionDate < nowDate) {
              strColorStatus = "bg-black-500";
            } else {
              strColorStatus = "bg-success";
            }
          } else if (EndPositionMonth > nowMonth) {
            strColorStatus = "bg-success";
          } else if (EndPositionMonth < nowMonth) {
            strColorStatus = "bg-black-500";
          }
        }
      } else {
        strColorStatus = "bg-success";
      }
      return strColorStatus;
    },
    ShowAllPosition(indexCard, event) {
      // console.log("event", indexCard);
      // console.log(this.$refs["position" + indexCard]);
      this.$refs["position" + indexCard]?.forEach((element) => {
        // ลบ d-none ออกจากตำแหน่งที่หมดอายุ
        element.classList.remove("d-none");

        // เพิ่ม d-none ให้กับปุ่ม Button
        event.target.classList.add("d-none");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.number-mobile {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.btn-hover {
  &:hover {
    text-decoration: underline $secondary;
  }
}
.skeleton-item {
  padding: 10px;
  background-color: #fff;
  .animated-background {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: $black-200;
    background: linear-gradient(
      to right,
      $black-200 8%,
      $black-300 18%,
      $black-200 33%
    );
    // background-size: 800px 104px;
    height: 32px;
    position: relative;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
</style>
<template>
  <div v-if="showTxtInp == true">
    <label class="txt-inp">
      <input
        type="number"
        required
        :max="maxvalue"
        :maxlength="maxcharacters"
        :min="0"
        :name="name"
        :id="name"
        :value="value == '' ? strResult : value"
        @keypress="isLetter($event)"
        @change="ValidNumber($event)"
        @keyup.delete="deleteLetter($event)"
        :class="alertMsgStatus == true ? 'invalid' : ''"
        class="w-100 text-end"
        title=""
        ref="inputnumber"
      />
      <p class="label-text" :class="device == 'mobile' ? 'fnt-caption' : ''">
        {{ $t(label) }}
        <span>{{ labelSub }}</span>
        <span>{{ require ? "*" : "" }}</span>
      </p>

      <div class="d-flex flex-column arrow-number">
        <moveUp width="16" height="16" class="fill-black-500" />
        <moveDown width="16" height="16" class="fill-black-500" />
      </div>
    </label>
    <div
      v-if="alertMsgStatus == true"
      class="d-flex alertInput align-items-center p-0 mt-1"
    >
      <Icon icon="carbon:warning" class="me-1" />
      {{ $t(alertMsgInput) }}
    </div>
  </div>

  <div class="txt-inp disable-inp" v-else>
    <label class="txt-inp">
      <input
        type="text"
        required
        :max="maxvalue"
        :maxlength="maxcharacters"
        :min="0"
        :name="name"
        :id="name"
        :value="value"
        @keypress="isLetter($event)"
        :class="alertMsgStatus == true ? 'invalid' : ''"
        class="w-100"
        title=""
        disabled
      />
      <p
        class="label-text"
        :class="
          value == nul || value == undefined || value == ''
            ? 'movedow'
            : 'label-text'
        "
        :style="device == 'mobile' ? 'font-size:12px !important;' : ''"
      >
        {{ $t(label) }}
        <span>{{ labelSub }}</span>
        <span>{{ require ? "*" : "" }}</span>
      </p>
    </label>
  </div>
  <!-- </div> -->
</template>

<script>
import { moveUp, moveDown } from "@/components/icon/index";
import { Icon } from "@iconify/vue";
import Validation from "../../typescript/validator/validator";
// import Validation from "../../typescript/validator/validator";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      intNumber: null,
    };
  },

  components: {
    Icon,
    moveUp,
    moveDown,
  },
  emit: ["input"],
  props: {
    require: { type: Boolean, default: false },
    name: { type: String },
    label: { type: String },
    value: { type: Number },
    statename: { type: String },
    maxcharacters: { type: String },
    maxvalue: { type: String },
    validate: { type: String },
    labelSub: { type: String }, // เป็น Label เสริมเมื่อทำการ Select จะหายไป
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    showTxtInp: { type: Boolean, default: true }, // Input disabled
  },
  mounted() {
    //this.$emit("input", "00");
  },

  methods: {
    deleteLetter(e) {
      setTimeout(() => {
        this.$emit("input", e.target.value);
      }, 5);
      this.alertMsgStatus = false;
    },
    alertRequest() {
      // this.alertMsg, { fieldname: this.$t(this.label).toLowerCase() };
      // console.log(this.value);
      if (this.value == null || this.value.toString() == "") {
        this.alertMsgInput = this.$t(this.alertMsg, {
          fieldname: this.$t(this.label).toLowerCase(),
        });
        this.alertMsgStatus = true;
        // console.log(this.alertMsgStatus);
      } else {
        this.Valid();
      }
    },
    ValidNumber(e) {
      setTimeout(() => {
        if (/^[0-9]+$/.test(e.target.value)) {
          this.alertMsgStatus = false;
          setTimeout(() => {
            // console.log(e.target.value);
            e.target.value = Number(e.target.value);
            // console.log(this.value > this.maxvalue);
            if (Number(e.target.value) > Number(this.maxvalue)) {
              setTimeout(() => {
                e.target.value = Number(this.maxvalue);
                this.$emit("input", Number(e.target.value));
              }, 5);

              //e.target.value = this.maxvalue.toString();
            } else {
              setTimeout(() => {
                this.$emit("input", Number(e.target.value));
              }, 5);
            }
          }, 5);
        } else {
          // console.log(e.target.value.toString() == "");
          if (e.target.value.toString() == "") {
            e.target.value = Number(e.target.value);
            setTimeout(() => {
              this.$emit("input", Number(e.target.value));
            }, 5);
          } else {
            this.alertMsgStatus = true;
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "56";
              e.preventDefault();
            } else {
              this.alertMsgStatus = false;
            }
          }
        }
      }, 20);
    },
    Valid() {
      // this.alertMsgStatus = false;
      // if (this.value === "" || this.value === undefined) {
      //   return;
      // }else
      if (this.validate == "number") {
        this.alertMsgStatus = !Validation[this.validate](this.value);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "56";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.validate](this.value);
        }
      } else if (this.validate == "WorkPeriod") {
        if (/^[0-9]+$/.test(this.value)) {
          this.alertMsgInput = "56";
          return true;
        } else {
          return false;
        }
      }
    },

    isLetter(e) {
      this.alertMsgStatus = false;
      let char = String.fromCharCode(e.keyCode);
      if (this.validate == "number") {
        if (/^[0-9]+$/.test(char)) {
          this.alertMsgStatus = false;
          return false;
        } else {
          this.alertMsgInput = "56";
          this.alertMsgStatus = true;
          e.preventDefault();
          return true;
        }
      } else if (this.validate == "WorkPeriod") {
        // console.log(/^[0-9]+$/.test(String.fromCharCode(e.keyCode)));
        if (/^[0-9]+$/.test(String.fromCharCode(e.keyCode))) {
          this.alertMsgStatus = false;
          setTimeout(() => {
            // console.log(e.target.value);
            e.target.value = Number(e.target.value);
            // console.log(this.value > this.maxvalue);
            if (Number(e.target.value) > Number(this.maxvalue)) {
              setTimeout(() => {
                e.target.value = Number(this.maxvalue);
                this.$emit("input", Number(e.target.value));
              }, 5);

              //e.target.value = this.maxvalue.toString();
            } else {
              setTimeout(() => {
                this.$emit("input", Number(e.target.value));
              }, 5);
            }
          }, 5);
        } else {
          this.alertMsgStatus = true;
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "56";
            e.preventDefault();
          } else {
            this.alertMsgStatus = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-inp {
  .label-text {
    top: 8% !important;
    background-color: $black-200;
  }
  input:disabled {
    background-color: $black-200;
    color: $black;
    border: solid 1px $black-400;
  }
  .movedow {
    top: 50% !important;
    background-color: $black-200 !important;
    color: $black-500 !important;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
  z-index: 10;
  padding: 8px;
}
.arrow-number {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
</style>

const apitemplate = {
  insertTemplate: "/v1/api/AdsUserTemplate/InsertUserTemplate",
  updateTemplate: "/v1/api/AdsUserTemplate/UpdateUserTemplate/",
  deleteTemplate: "/v1/api/AdsUserTemplate/DeleteUserTemplate/",
  getTemplate: "/v1/api/AdsUserTemplate/UserTemplateList",
  getTemplateByID: "/v1/api/AdsUserTemplate/UsertemplateByID/",
  // duplicate: "/v1/api/AdsUserTemplate/TemplateAgency",
  checkdDeleteTemplate: "AdsUserField/CheckDeleteAgency/",
  UserfieldsetList: "/v1/api/AdsUserTemplate/UserFieldsetList",
  UserfieldForTemplateList: "/v1/api/AdsUserTemplate/UserfieldForTemplateList",
  checkusageusertemplate: "/v1/api/AdsUserTemplate/CheckUsageStatusUsertemplate/",
  getUserTemplateList:"/v1/api/AdsUserTemplate/GetUserTemplateList",
  getUserFields: "/v1/api/AdsUserTemplate/GetUserField",
  getControlFieldData: "/v1/api/AdsUserTemplate/GetControlFieldData/",
  insertUserTemplate:"/v1/api/AdsUserTemplate/insertUserTemplate",
  updateUserTemplate:"/v1/api/AdsUserTemplate/updateUserTemplate",
};

export default apitemplate;

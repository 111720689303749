const apiuserinfo = {
  template: "/v1/api/AdsUserInfo/LoadUserTypeTemplate/",
  usertype: "/v1/api/AdsUserType/UserTypeList",
  LoadTemplateForm: "/v1/api/AdsUserInfo/LoadTemplateForm/",
  CheckDuplicateUserInfo: "/v1/api/AdsUserInfo/CheckDuplicateUserInfo",
  InsertUserInfo: "/v1/api/AdsUserInfo/InsertUserInfo",
  GetUserInfoListByKeyword: "/v1/api/AdsUserInfo/GetUserInfoList/",
  GetUserInfoByID: "/v1/api/AdsUserInfo/LoadUserinfobyID/",
  UpdateUserInfoByID: "/v1/api/AdsUserInfo/UpdateUserInfo/",
  GetUserTypesforUserinfo:"/v1/api/AdsUserType/GetUserTypesforUserinfo",
  SearchAfterInsert:"/v1/api/AdsUserInfo/GetUserInfoListByMemberID",
  LoadUserinfobyID: "/v1/api/AdsUserInfo/LoadUserinfobyID/",
  UpdateUserInfo: "/v1/api/AdsUserInfo/UpdateUserInfo/",
  GetCheckUserInfo:"/v1/api/AdsUserInfo/GetCheckUserInfo/",
  GetCheckUserinfoRequire:"/v1/api/AdsUserInfo/GetCheckUserinfoRequire"
};

export default apiuserinfo;


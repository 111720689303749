<template>
  <div class="overlay" v-if="select" @click="select = false"></div>

  <div class="select" :class="size" v-if="blnEnableSelectHeading == true">
    <div class="selector" :class="background" @click="select = !select">
      <input
        type="text"
        name=""
        id=""
        readonly
        :placeholder="placeholder"
        :value="$t(value)"
      />
      <moveDown width="16" height="16" class="icon" />
    </div>
    <div class="lists shadow-sm" v-if="select">
      <div
        class="list fnt-light fnt-body"
        v-for="(list, index) in listItem"
        :key="index"
        @click="selectList(list.name, list.id, index)"
      >
        {{ $t(list.name) }}
      </div>
    </div>
  </div>
  <div
    class="select-disable"
    :class="size"
    v-if="blnEnableSelectHeading == false"
  >
    <div class="selector">
      <input
        type="text"
        name=""
        id=""
        readonly
        :placeholder="placeholder"
        :value="$t(value)"
      />
      <moveDown width="16" height="16" class="icon fill-black-400" />
    </div>
  </div>
</template>

<script>
import { moveDown } from "@/components/icon/index";
export default {
  data() {
    return {
      updateSelect: null,
      select: false,
      value: "",
      listSelect: [
        {
          id: 1,
          name: "List 1",
        },
        {
          id: 2,
          name: "List 2",
        },
        {
          id: 3,
          name: "List 3",
        },
        {
          id: 4,
          name: "List 4",
        },
        {
          id: 5,
          name: "List 5",
        },
      ],
    };
  },

  emits: ["onSelect"],
  props: {
    placeholder: { type: String },
    size: { type: String },
    background: { type: String },
    listItem: { type: Object },
    blnEnableSelectHeading: { type: Boolean, default: true },
    intHeadinglevel: { type: String },
    SelectedValue: { type: String },
  },
  components: {
    moveDown,
  },
  // watch: {
  //   intHeadinglevel: function (val) {
  //     this.value = this.listItem[val].name;
  //     this.select = false;
  //   },
  // },
  unmounted() {
    clearInterval(this.updateSelect);
  },
  mounted() {
    // console.log(this.intHeadinglevel);
    this.updateSelect = setInterval(() => {
      if (this.intHeadinglevel != "" && this.intHeadinglevel != 0) {
        this.listItem.filter((ele) => {
          // console.log(ele);
          if (ele.id == this.intHeadinglevel) {
            // console.log(ele.name);
            this.value = ele.name;
          }
        });
        // this.value = "";
        // console.log("value : " + this.value);
        // this.select = false;
      } else {
        this.value = "";
      }
    }, 1000);

    setTimeout(() => {
      this.listItem.filter((ele) => {
        if (ele.id == this.SelectedValue) {
          this.value = ele.name;
        }
      });
    }, 1000);
  },
  methods: {
    selectList(event, id, index) {
      this.value = event;
      this.select = false;
      this.$emit("onSelect", id);
      this.$emit("index", index);
      this.$emit("input", { id: id, index: index });
      // console.log(event, id);
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  .selector {
    background-color: $black-200 !important;
    input {
      &::placeholder {
        color: $black-400 !important;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }
}
.select {
  position: relative;
  width: fit-content;
  cursor: pointer;
  .selector {
    position: relative;
    background-color: $black-100;
    border-radius: 4px;
    // width: max-content;
    height: 26px;
    padding-left: 8px;
    transition: 150ms;
    width: 100%;
    input {
      border: none;
      cursor: pointer;
      outline: none;
      background: none;
      font-size: 14px;
      &::placeholder {
        font-size: 14px;
        color: $black;
        font-weight: 300;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: $black-300;
    }
    &:active {
      background-color: $black-400;
    }
  }
  .lists {
    position: absolute;
    left: 0;
    top: calc(26px + 5px);
    border-radius: 4px;
    background-color: $white;
    z-index: 99;
    width: 100% !important;
    .list {
      width: 100% !important;
      padding: 4px 8px;
      color: $black;
      &:hover {
        background-color: $black-300;
      }
      &:active {
        background-color: $black-400;
      }
    }
  }
}
.select-disable {
  position: relative;
  width: fit-content;
  pointer-events: none;
  color: $black-400 !important;
  .selector {
    position: relative;
    background-color: $black-200 !important;
    border-radius: 4px;
    width: max-content;
    height: 26px;
    padding-left: 8px;
    transition: 150ms;
    // width: 100%;
    input {
      border: none;
      cursor: pointer;
      outline: none;
      background: none;
      color: $black-400 !important;
      font-size: 14px;
      &::placeholder {
        font-size: 14px;
        font-weight: 300;
        color: $black-400 !important;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: $black-300;
    }
    &:active {
      background-color: $black-400;
    }
  }
  .lists {
    position: absolute;
    left: 0;
    top: calc(26px + 5px);
    border-radius: 4px;
    background-color: $white;
    z-index: 99;
    width: 100% !important;
    .list {
      width: 100% !important;
      padding: 4px 8px;
      color: $black;
      &:hover {
        background-color: $black-300;
      }
      &:active {
        background-color: $black-400;
      }
    }
  }
}
.overlay {
  width: 100%;
  height: 100%;
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
}
.small {
  width: 140px !important;
}
.medium {
  .selector {
    height: 36px !important;
  }
  input {
    height: 100%;
  }
}
</style>

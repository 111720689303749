<template>
  <div class="txt-inp disable-inp">
    <label class="txt-inp">
      <Button icon="RemoveFill" classBtnIcon="fill-black-400" class="icon" />
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="value"
        :class="size"
        class="w-100"
        title=""
        disabled
        :placeholder="$t(placeholder)"
        :style="
          intTemplateDifinest != 1 ? '' : 'background-color: white !important'
        "
        ref="input"
      />
      <Button
        v-if="intFieldtype == 4 || intFieldtype == 1"
        icon="moveDown"
        classBtnIcon="fill-black-500"
        class="icon-right"
      />
      <p
        class="label-text rounded-2 bg-white d-inline-block text-truncate"
        :class="
          label == 'PrefixTHStudent' || label == 'PrefixENStudent'
            ? 'w-50'
            : ''
        "
        :style="device == 'mobile' ? 'font-size:12px !important;' : ''"
      >
        {{ ValidNumber(label) ? label : $t(label)
        }}<span class="sub">{{ labelSub }}</span>
        <span class="require" v-if="request === true"> *</span>
      </p>
    </label>
  </div>
</template>
  
<script>
import Button from "@/components/button/button.vue";
// import dropdown from "@/components/input/dropdown/select.vue";
export default {
  components: {
    Button,
    // dropdown
  },
  data() {
    return {
      DropdownItem: "",
    };
  },
  props: {
    request: { type: Boolean, default: false },
    maxlength: { type: String },
    name: { type: String },
    value: { type: String },
    label: { type: String },
    size: { type: String },
    placeholder: { type: String, default: "" },
    intFieldtype: { type: String, default: "4" },
    intTemplateDifinest: { type: String, default: "0" },
  },
  methods: {
    ValidNumber(value) {
      return isNaN(value);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.disable-inp {
  input {
    padding: 10px 16px !important;
    &:hover {
      border: solid 1px $black-400;
    }
  }
  .label-text {
    // top: -6% !important;
    left: 38px;
    background-color: $black-200 !important;
    color: $black !important;
    font-size: 14px !important;
    width: 70%;
  }
  input:disabled {
    background-color: $black-200 !important;
    color: $black;
    border: solid 1px $black-400 !important;
  }
}
.txt-inp {
  .icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: auto;
  }
  .icon-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: auto;
  }
  position: relative;
  width: 100%;
  p {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 16px;
    background: none;
    color: $black-500;
    cursor: text;
    display: flex;
    user-select: none;
  }

  input {
    outline: 0;
    font-size: 16px;
    padding: 10px 10px 10px 42px !important;
    border: solid 1px $black-300;
    width: 100%;
    border-radius: 4px;
    transition: all 0.2s;
    overflow: hidden;
    padding-top: 4px;
    &::placeholder {
      color: $white;
    }
    &:hover {
      border: solid 1px $primary;

      & + p {
        color: $black-500;
      }
    }

    &:focus {
      border: solid 1px $primary;

      & + p {
        top: -4%;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 14px;

        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
      &::placeholder {
        color: $black-500 !important;
      }
    }

    &:valid + p {
      transition: all 0.2s;
      background-color: #fff;
      font-size: 14px;
      top: -4%;

      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
    &:valid {
      &:hover + p {
        color: $primary !important;
      }
    }
  }
}
</style>
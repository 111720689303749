export const listUserFieldsID = [
  [
    [
      {
        "listUserFielID": [
          1,
          2,

          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19]
      }],
    [{
      "listUserFielID": [
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        231,
        34,
        35,
        36,
        37,
        38]
    }],
    [{
      "listUserFielID": [
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52
      ]
    }],
    [{
      "listUserFielID": [
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70,
        71,
        72,
        73,
        74,
        75,
        232,
        76,
        77,
        78,
        79,
        80,
        241,
        81,
        82,
        83,
        84,
        85,
        86,
        87,
        88,
        89,
        90,
        91,
        242,
        92,
        93,
        94,
        95,
        96,
        97,
        98,
        99,
        100,
        101,
        102,
        233,
        103,
        104,
        105,
        106,
        107,
        243,
        108,
        109,
        110,
        111,
        112,
        113,
        114,
        115,
        116,
        117,
        118,
        119,
        120,
        121,
        122,
        244,
        123,
        124,
        125,
        126,
        127,
        128,
        129,
        233,
        234,
        130,
        131,
        132,
        133,
        134,
        135,
        136,
        137
      ]
    }],
    [{
      "listUserFielID": [
        138,
        139,
        140,
        141
       
      ]
    }],
    [{
      "listUserFielID": [
        142,
        143,
        144,
     
      ]
    }]
  ],
  [
    [
      {
        "listUserFielID":
          [
            1,
            146,  
            151,
            152,
            153,
            154,
            147,
            148,
            149,
            150,
            155,
            156,
            157,
            158,
            159,
            160,
            161,
            162,
            163,
          ]
      }
    ],
    [{
      "listUserFielID": [
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        164,
        165
      ]
    }],
    [{
      "listUserFielID": [
        161,
        226,
        224,
        225,
        227,
        166,
        167,
        168,
        169,
        170,
        171,
        172,
        173,
        174,
        175,
        235,
        176,
        177,
        178,
        179,
        180,
        181]
    }],
    [{
      "listUserFielID": [
        182,
        183,
        184,
        185,
        186,
        187,
        188,
        189,
        190,
        191,
        192,
        193,
        194,
        195,
        196,
        197,
        198,
        199,
        200,
        201,
        202,
        203,
        204,
      ]
    }]
  ],
  [
    [{
      "listUserFielID": [
        205,
        206,
        4,
        5,
        6,
        210,
        8,
        9,
        10,
        214,
        215,
        216
      ]
    }],
    [{
      "listUserFielID": [

        217,
        218,
        219,
        220,
        221,
        222
      ]
    }]
  ]
]

// render form
export const listUserFields = [ //userType
  [ // Th student
    // [
    {
      //right box
      formName: "911",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 1,
              },
              {}
            ],
          ]],
        },
        {
          fieldGroupName: "917",
          fieldList: [[
            [
              {
                strID: 2,
              },
            ],
            [
              {
                strID: 3,

              },
              {
                strID: 4,

              },
              {
                strID: 5,

              },

              {
                strID: 6,
              },
            ],
            [
              {
                strID: 7,
              },
              {
                strID: 8,
              },
              {
                strID: 9,
              },

              {
                strID: 10,
              },
            ],
            [
              {
                strID: 11,
              },
              {
                strID: 12,
              },
              {
                strID: 13,
              },
            ],
            [
              {
                strID: 14,
              },
              {
                strID: 15,
              },
              {
                strID: 16,
              },
            ],
            [
              {
                strID: 17,
              },
            ],
            [
              {
                strID: 18,
              },
            ],
          ]],
        },
        {
          fieldGroupName: "920",
          fieldList: [[
            [
              {
                strID: 19,
              },
            ],
          ]],
        },
      ],
    },
    // ข้อมูลติดต่อ
    {
      
      formName: "912",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 20,
              },
              {
                strID: 23,
              },
            ],
            [
              {
                strID: 21,
              },
              {
                strID: 22,
              },
            ],
            [
              {
                strID: 24,
              },
              {

              }
            ],
          ]],
        },
        //ที่อยู่ตามบัตรประชาชน
        {
          fieldGroupName: "932",
          fieldList: [[
            [
              {
                strID: 27
              },
              {}
            ],
            [
              {
                strID: 25,
              },
              {
                strID: 26,
              },
            ],
            [
              {
                strID: 28,
              },
              {
                strID: 29,
                strRelation: 28
              },
            ],
            [
              {
                strID: 30,
                strRelation: 29
              },
              {
                strID: 31,
                strRelation: 20
              },
            ],
          ]],
        },
        //ที่อยู่จัดส่งเอกสารสำคัญ
        {
          fieldGroupName: "939",
          fieldList: [[
            [
              {
                strID: 231,
                listSource: 1
              }
            ],

            [
              {
                strID: 34
              },
              {}
            ],
            [
              {
                strID: 32,
              },
              {
                strID: 33,
              },
            ],
            [
              {
                strID: 35,
              },
              {
                strID: 36,
                strRelation: 35
              },
            ],
            [
              {
                strID: 37,
                strRelation: 36
              },
              {
                strID: 38,
                strRelation: 37
              },
            ],
          ]],
        },
      ],
    },
    // ข้อมูลการศึกษา
    {
      //right box
      formName: "913",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 39,
              }, {
                strID: 44,
              },
            ],
            [
              {
                strID: 40,
              },
            ],
            [
              {
                strID: 41,
              },
            ],
            [
              {
                strID: 42,
              },
            ],
            [
              {
                strID: 43,
              },
            ],
            // [

            // ],
          ]],
        },
        //ข้อมูลการเข้าศึกษา
        {
          fieldGroupName: "944",
          fieldList: [[
            [
              {
                strID: 45,
              },
              {
                strID: 46,
              },
            ],
            [

              {
                strID: 47,
              },

              {
                strID: 48,
              },
            ],
          ]],
        },
        //ประวัติการศึกษา
        {
          fieldGroupName: "949",
          blnCanAdd: true,
          fieldList: [[
            [
              {
                strID: 50,
              },
              {
                strID: 51,
              },
            ],
            [
              {
                strID: 49,
                strRelation: [50,51]
              },
              {
                strID: 52,
              },
            ],
          ]],
        },
      ],
    },
    //
    {
      //right box
      formName: "914",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 53,
              },
              {}
            ]],
          ]
        },
        {
          fieldGroupName: "953",
          fieldList: [[
            [
              {
                strID: 54,

              },
              {
                strID: 55,
              },
              {
                strID: 56,
              },
              {
                strID: 57,
              },
            ],
            [
              {
                strID: 58,

              },
              {
                strID: 59,
              },
              {
                strID: 60,
              },
              {
                strID: 61,
              },
            ],
            [
              {
                strID: 62,
              },
              {
                strID: 63,
              },
              {
                strID: 64,
              },
            ],
            [
              {
                strID: 65,
              },
              {
                strID: 66,
              },

            ],
          ]],
        },
        {
          fieldGroupName: "932",
          fieldList: [[
            [
              {
                strID: 69,
              },
              {
              },
            ],
            [
              {
                strID: 67,
              },
              {
                strID: 68,
              },
            ],
            [
              {
                strID: 70,
              },
              {
                strID: 71,
              },
            ],
            [
              {
                strID: 72,
              },
              {
                strID: 73,
              },
            ]
          ]]
        },
        {
          fieldGroupName: "939",
          fieldList: [[
            [
              {
                strID: 232,
                listSource: 2
              }
            ],
            [
              {
                strID: 76,
              },
              {}
            ],
            [
              {
                strID: 74,
              },
              {
                strID: 75,
              },

            ],
            [
              {
                strID: 77,
              },
              {
                strID: 78,
              },

            ],
            [
              {
                strID: 79,
              },
              {
                strID: 80,
              },

            ],


          ]]
        },
        {
          fieldGroupName: "964",
          fieldList: [[
            [
              {
                strID: 241,
                listSource: 1,
              }
            ],
            [
              {
                strID: 81,
              },
              {
                strID: 82,
              },
              {
                strID: 83,
              },
              {
                strID: 84,
              },
            ],
            [
              {
                strID: 85,
              },
              {
                strID: 86,
              },
              {
                strID: 87,
              },
              {
                strID: 88,
              },
            ],
            [
              {
                strID: 92,
              },
              {
                strID: 93,
              },
            ],
            [
              {
                strID: 89,
              },
            ],
            [
              {
                strID: 90,
              },
              {
                strID: 91,
              },
            ],
          ]]
        },
        {
          fieldGroupName: "932",
          fieldList: [[
            [
              {
                strID: 242,
                listSource: 2
              }
            ],
            [
              {
                strID: 96,
              },
              {
              },
            ],
            [
              {
                strID: 94,
              },
              {
                strID: 95,
              },
            ],
            [
              {
                strID: 97,
              },
              {
                strID: 98,
              },
            ],
            [
              {
                strID: 99,
              },
              {
                strID: 100,
              },
            ]
          ]]
        },
        {
          fieldGroupName: "939",
          fieldList: [[
            [
              {
                strID: 233,
                listSource: [null, 3, 5], // 3 ที่อยู่จัดส่ง ผปค, 5 ตามทะเบียน
              }
            ],
            [
              {
                strID: 103,
              },
              {}
            ],
            [
              {
                strID: 101,
              },
              {
                strID: 102,
              },

            ],
            [
              {
                strID: 104,
              },
              {
                strID: 105,
              },

            ],
            [
              {
                strID: 106,
              },
              {
                strID: 107,
              },

            ]
          ]]
        },
        {
          fieldGroupName: "965",
          fieldList: [[
            [
              {
                strID: 243,
                listSource: 1
              }
            ],
            [
              {
                strID: 108,
              },
              {
                strID: 109,
              },
              {
                strID: 110,
              },
              {
                strID: 111,
              },
            ],
            [
              {
                strID: 112,
              },
              {
                strID: 113,
              },
              {
                strID: 114,
              },
              {
                strID: 115,
              },
            ],
            [
              {
                strID: 119,
              },
              {
                strID: 120,
              },
            ],
            [
              {
                strID: 116,
              },
            ],
            [
              {
                strID: 117,
              },
              {
                strID: 118,
              },
            ],

          ]]
        },
        {
          fieldGroupName: "932",
          fieldList: [[
            [
              {
                strID: 244,
                listSource: 2

              }
            ],
            [
              {
                strID: 123,
              },
              {
              },
            ],
            [
              {
                strID: 121,
              },
              {
                strID: 122,
              },
            ],
            [
              {
                strID: 124,
              },
              {
                strID: 125,
              },
            ],
            [
              {
                strID: 126,
              },
              {
                strID: 127,
              },
            ]
          ]]
        },
        {
          fieldGroupName: "939",
          fieldList: [[
            [
              {
                strID: 234,
                listSource: [null, 3, 8], // 3 ที่อยู่จัดส่ง ผปค, 8 ตามทะเบียน
              }
            ],
            [
              {
                strID: 130,
              },
              {}
            ],
            [
              {
                strID: 128,
              },
              {
                strID: 129,
              },

            ],
            [
              {
                strID: 131,
              },
              {
                strID: 132,
              },

            ],
            [
              {
                strID: 133,
              },
              {
                strID: 134,
              },

            ]
          ]]
        },
        {
          fieldGroupName: "966",
          fieldList: [[
            [
              {
                strID: 135,
              },
              {
                strID: 136,
              },
              {
                strID: 137,
              },
            ]
          ]]
        }
      ],
    },
    {
      formName: "915",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 138,

              },
            ],
            [
              {
                strID: 139,

              },
            ],
            [
              {
                strID: 140,

              },
            ],
            [
              {
                strID: 141,

              },
            ],
          ]]
        },
      ]
    },
    {
      formName: "916",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 142,

              },
              {
                strID: 143,

              },
            ],
            [
              {
                strID: 144,

              },
            ],
          ]]
        },
      ]
    }
    // ]
  ],

  [ // Foreign Student
    {
      //right box
      formName: "911",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 1,
              },
              {}
            ],
          ]],
        },
        {
          fieldGroupName: "985",
          fieldList: [[
            [
              {
                strID: 146,
              },
              {},
            ],
            [
              {
                strID: 151,
              },
              {
                strID: 152,
              },
              {
                strID: 153,
              },
              {
                strID: 154,
              },
            ],
            [
              {
                strID: 147,
              },
              {
                strID: 148,
              },
              {
                strID: 149,
              },
              {
                strID: 150,
              },
            ],
            [
              {
                strID: 155,
              },
              {
                strID: 156,
              },
              {
                strID: 157,
              },
            ],
            [
              {
                strID: 158,
              },
              {
                strID: 159,
              },
              {
                strID: 160,
              },
            ],
            [
              {
                strID: 162,
              },
              {},
            ],
          ]],
        },
        {
          fieldGroupName: "920",
          fieldList: [[
            [
              {
                strID: 163,
              },
            ],
          ]]
        }
      ]
    },
    {
      //right box
      formName: "913",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 39,
              }, {
                strID: 44,
              },
            ],
            [
              {
                strID: 40,
              },
            ],
            [
              {
                strID: 41,
              },
            ],
            [
              {
                strID: 42,
              },
            ],
            [
              {
                strID: 43,
              },
            ],
            // [

            // ],
          ]],
        },
        {
          fieldGroupName: "944",
          fieldList: [[
            [
              {
                strID: 45,
              },
              {
                strID: 46,
              },
            ],
            [

              {
                strID: 47,
              },

              {
                strID: 48,
              },
            ],
          ]],
        },
        {
          fieldGroupName: "949",
          blnCanAdd: true,
          fieldList: [[

            [
              {
                strID: 164,
              },
              {
                strID: 165,
              },
            ],
          ]],
        }
      ]
    },
    {
      //right box
      formName: "912",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 161,
              },
              {
                strID: 226,
              },
            ],
            [
              {
                strID: 224,
              },
              {
                strID: 225,
              },
            ],
            [
              {
                strID: 227,
              },
              {

              }
            ],
          ]],
        },
        {
          fieldGroupName: "1000",
          fieldList: [[
            [
              {
                strID: 166
              },
              {}
            ],
            [
              {
                strID: 167,
              },
              {
                strID: 168,
              }
            ],
            [
              {
                strID: 169,
              },
              {
                strID: 170,
              }
            ],
            [
              {
                strID: 171,
              },
              {
                strID: 172,
              }
            ]
          ]]
        },
        {
          fieldGroupName: "990",
          fieldList: [[
            [
              {
                strID: 235,
                listSource: 1
              },
              {}
            ],
            [
              {
                strID: 175,
              },
              {}
            ],
            [
              {
                strID: 173,
              },
              {
                strID: 174,
              },
            ],
            [
              {
                strID: 176,
              },
              {
                strID: 177,
              },
            ],
            [
              {
                strID: 178,
              },
              {
                strID: 179,
              },
            ]
          ]]
        },
        {
          fieldGroupName: "991",
          fieldList: [[
            [
              {
                strID: 180
              },
              {}
            ],
            [
              {
                strID: 181,
              }
            ],

          ]]
        }
      ],
    },
    {
      //right box
      formName: "993",
      fieldsGroup: [
        {
          fieldGroupName: "994",
          fieldList: [[
            [
              {
                strID: 182
              },
              {
                strID: 183
              },
              {
                strID: 184
              },
              {
                strID: 185
              },
            ],
            [
              {
                strID: 186
              },
            ],
            [
              {
                strID: 187
              },
              {
                strID: 188
              },
            ],
            [
              {
                strID: 189
              },
              {}
            ],
            [
              {
                strID: 190
              },
              {
                strID: 191
              },
            ],
            [
              {
                strID: 192
              },
              {
                strID: 193
              },
            ],
            [
              {
                strID: 194
              },
              {
                strID: 195
              },
            ]
          ]]
        },
        {
          fieldGroupName: "995",
          fieldList: [[
            [
              {
                strID: 196
              },
              {
                strID: 197
              },
              {
                strID: 198
              },
              {
                strID: 199
              },
            ],
            [
              {
                strID: 200
              },
            ],
            [
              {
                strID: 201
              },
              {
                strID: 202
              },
            ],
            [
              {
                strID: 203
              },
              {}
            ],
            [
              {
                strID: 204
              },
            ]
          ]]
        }
      ]
    }
  ],

  [ // Staff
    {
      //right box
      formName: "911",
      fieldsGroup: [
        {
          fieldGroupName: "",
          fieldList: [[
            [
              {
                strID: 205
              }
            ],
            [
              {
                strID: 206
              },
              {
                strID: 4
              },
              {
                strID: 5
              },
              {
                strID: 6
              }
            ],
            [
              {
                strID: 210
              },
              {
                strID: 8
              },
              {
                strID: 9
              },
              {
                strID: 10
              }
            ],
            [
              {
                strID: 214
              },
            ],
            [
              {
                strID: 215
              },
            ]
          ]]
        },
        {
          fieldGroupName: "920",
          fieldList: [[
            [
              {
                strID: 216
              }
            ],
          ]]
        }
      ]
    },
    {
      //right box
      formName: "1003",
      fieldsGroup: [
        {
          fieldGroupName: "",
          blnCanAdd: true,
          fieldList: [[
            [
              {
                strID: 217
              },
              {
                strID: 218
              }
            ],
            [
              {
                strID: 219
              },
            ],
            [
              {
                strID: 220
              },
              {
                strID: 221
              }
            ],
            [
              {
                strID: 222
              },
            ],
          ]]
        },
      ]
    }
  ],
]

<template>
  <div class="shadow-sm tree bg-white">
    <template v-for="(item, index) in treeData" :key="index">
      <!-- {{ index == treeData.length - 1  }} -->
      <div class="d-flex align-items-center list">
        <Icon
          @click="item.blnshow = !item.blnshow"
          icon="gg:remove-r"
          width="18"
          height="18"
          class="text-secondary"
          :class="item.children.length == 0 ? 'opacity-0' : ''"
          v-if="item.blnshow == true"
        />
        <Icon
          @click="item.blnshow = !item.blnshow"
          icon="gg:add-r"
          width="18"
          height="18"
          class="text-secondary"
          :class="item.children.length == 0 ? 'opacity-0' : ''"
          v-else
        />

        <div
          class="list d-flex align-items-center w-100"
          @click="checkLevelFt(item, item)"
        >
          <!-- {{ item }} -->
          <span class="w-100">{{
            blnSystemword == true ? item.lang : item[fillterLang(item)]
          }}</span>
        </div>
      </div>

      <div
        v-for="(items, indexes) in item.children"
        :key="indexes"
        v-show="item.blnshow"
      >
        <!-- {{ indexes == item.children.length - 1  }} -->
        <!-- @click="items.blnshow = !items.blnshow" -->
        <!-- {{ indexes }} -->
        <div class="d-flex align-items-center list">
          <div
            class="d-flex align-items-center justify-content-center ps-2 pe-3"
          >
            <div
              class="line"
              :class="index == treeData.length - 1 ? 'opacity-0' : ''"
            ></div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center ps-2 pe-3"
            :class="items.children && items.children.length ? 'd-none' : ''"
          >
            <div class="line-hori"></div>
          </div>
          <Icon
            icon="gg:remove-r"
            width="18"
            height="18"
            class="text-secondary me-2"
            :class="items.children && items.children.length ? '' : 'opacity-0'"
            v-if="items.blnshow == true"
            @click="items.blnshow = !items.blnshow"
          />
          <Icon
            icon="gg:add-r"
            @click="items.blnshow = !items.blnshow"
            width="18"
            height="18"
            class="text-secondary me-2"
            :class="items.children && items.children.length ? '' : 'opacity-0'"
            v-else
          />
          <div
            @click="checkLevelFt(items, item)"
            class="list d-flex align-items-center"
          >
            <!-- <div class=""> -->

            <span class="">
              {{
                blnSystemword == true ? items.lang : items[fillterLang(items)]
              }}</span
            >

            <!-- </div> -->
          </div>
        </div>

        <div
          v-for="(items1, index1) in items.children"
          :key="index1"
          v-show="items.blnshow"
        >
          <!-- {{ index1 == items.children.length - 1 }} -->
          <!-- @click="items1.blnshow = !items1.blnshow" -->
          <div class="d-flex align-items-center list">
            <div
              class="d-flex align-items-center justify-content-center ps-2 pe-3"
            >
              <div
                class="line"
                :class="index == treeData.length - 1 ? 'opacity-0' : ''"
              ></div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center ps-2 pe-3"
            >
              <div
                class="line"
                :class="indexes == item.children.length - 1 ? 'opacity-0' : ''"
              ></div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center ps-2 pe-3"
              :class="items1.children && items1.children.length ? 'd-none' : ''"
            >
              <div class="line-hori"></div>
            </div>
            <Icon
              icon="gg:remove-r"
              width="18"
              height="18"
              class="text-secondary me-2"
              v-if="items1.blnshow == true"
              @click="items1.blnshow = !items1.blnshow"
              :class="
                items1.children && items1.children.length ? '' : 'opacity-0'
              "
            />
            <Icon
              icon="gg:add-r"
              width="18"
              height="18"
              class="text-secondary me-2"
              @click="items1.blnshow = !items1.blnshow"
              :class="
                items1.children && items1.children.length ? '' : 'opacity-0'
              "
              v-else
            />
            <div
              @click="checkLevelSn(items1, items, item)"
              class="list d-flex align-items-center"
            >
              <span @click="items1.blnshow = !items1.blnshow">
                {{
                  blnSystemword == true
                    ? items1.lang
                    : items1[fillterLang(items1)]
                }}</span
              >
            </div>
          </div>

          <div
            v-for="(items2, index2) in items1.children"
            :key="index2"
            v-show="items1.blnshow"
          >
            <!-- @click="items2.blnshow = !items2.blnshow -->
            <div class="d-flex align-items-center list">
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  ps-2
                  pe-3
                "
              >
                <div
                  class="line"
                  :class="index == treeData.length - 1 ? 'opacity-0' : ''"
                ></div>
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  ps-2
                  pe-3
                "
              >
                <div
                  class="line"
                  :class="
                    indexes == item.children.length - 1 ? 'opacity-0' : ''
                  "
                ></div>
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  ps-2
                  pe-3
                "
              >
                <div
                  class="line"
                  :class="
                    index1 == items.children.length - 1 ? 'opacity-0' : ''
                  "
                ></div>
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  ps-2
                  pe-3
                "
                :class="
                  items2.children && items2.children.length ? 'd-none' : ''
                "
              >
                <div class="line-hori"></div>
              </div>
              <Icon
                icon="gg:remove-r"
                width="18"
                height="18"
                class="text-secondary me-2"
                v-if="items2.blnshow == true"
                @click="items2.blnshow = !items2.blnshow"
                :class="
                  items2.children && items2.children.length ? '' : 'opacity-0'
                "
              />
              <Icon
                icon="gg:add-r"
                width="18"
                height="18"
                class="text-secondary me-2"
                @click="items2.blnshow = !items2.blnshow"
                :class="
                  items2.children && items2.children.length ? '' : 'opacity-0'
                "
                v-else
              />
              <div
                @click="checkLevelTh(items2, items1, items, item)"
                class="list d-flex align-items-center"
              >
                <span @click="items2.blnshow = !items2.blnshow">
                  {{
                    blnSystemword == true
                      ? items2.lang
                      : items2[fillterLang(items2)]
                  }}</span
                >
              </div>
            </div>

            <div
              v-for="(items3, index3) in items2.children"
              :key="index3"
              v-show="items2.blnshow"
            >
              <div
                @click="items2.blnshow = !items.blnshow"
                class="list d-flex align-items-center"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    ps-2
                    pe-3
                  "
                >
                  <div
                    class="line"
                    :class="index == treeData.length - 1 ? 'opacity-0' : ''"
                  ></div>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    ps-2
                    pe-3
                  "
                >
                  <div
                    class="line"
                    :class="
                      indexes == item.children.length - 1 ? 'opacity-0' : ''
                    "
                  ></div>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    ps-2
                    pe-3
                  "
                >
                  <div
                    class="line"
                    :class="
                      index1 == items.children.length - 1 ? 'opacity-0' : ''
                    "
                  ></div>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    ps-2
                    pe-3
                  "
                >
                  <div
                    class="line"
                    :class="
                      index2 == items1.children.length - 1 ? 'opacity-0' : ''
                    "
                  ></div>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    ps-2
                    pe-3
                  "
                  :class="
                    items3.children && items3.children.length ? 'd-none' : ''
                  "
                >
                  <div class="line-hori"></div>
                </div>
                <Icon
                  icon="gg:remove-r"
                  width="18"
                  height="18"
                  class="text-secondary me-2"
                  :class="
                    items3.children && items3.children.length ? '' : 'opacity-0'
                  "
                  v-if="items3.blnshow == true"
                />
                <Icon
                  icon="gg:add-r"
                  width="18"
                  height="18"
                  class="text-secondary me-2"
                  :class="
                    items3.children && items3.children.length ? '' : 'opacity-0'
                  "
                  v-else
                />
                {{
                  blnSystemword == true
                    ? items3.lang
                    : items3[fillterLang(items3)]
                }}
              </div>
              <div
                v-for="(items4, index4) in items3.children"
                :key="index4"
                v-show="items3.blnshow"
              >
                <div
                  @click="items4.blnshow = !items4.blnshow"
                  class="list d-flex align-items-center"
                >
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      ps-2
                      pe-3
                    "
                  >
                    <div
                      class="line"
                      :class="index == treeData.length - 1 ? 'opacity-0' : ''"
                    ></div>
                  </div>
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      ps-2
                      pe-3
                    "
                  >
                    <div
                      class="line"
                      :class="
                        indexes == item.children.length - 1 ? 'opacity-0' : ''
                      "
                    ></div>
                  </div>
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      ps-2
                      pe-3
                    "
                  >
                    <div
                      class="line"
                      :class="
                        index1 == items.children.length - 1 ? 'opacity-0' : ''
                      "
                    ></div>
                  </div>
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      ps-2
                      pe-3
                    "
                  >
                    <div
                      class="line"
                      :class="
                        index2 == items1.children.length - 1 ? 'opacity-0' : ''
                      "
                    ></div>
                  </div>

                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      ps-2
                      pe-3
                    "
                    :class="
                      items4.children && items4.children.length ? 'd-none' : ''
                    "
                  >
                    <div class="line-hori"></div>
                  </div>
                  <Icon
                    icon="gg:remove-r"
                    width="18"
                    height="18"
                    class="text-secondary me-2"
                    :class="
                      items4.children && items4.children.length
                        ? ''
                        : 'opacity-0'
                    "
                    v-if="items4.blnshow == true"
                  />
                  <Icon
                    icon="gg:add-r"
                    width="18"
                    height="18"
                    class="text-secondary me-2"
                    :class="
                      items4.children && items4.children.length
                        ? ''
                        : 'opacity-0'
                    "
                    v-else
                  />
                  {{
                    blnSystemword == true
                      ? items4.lang
                      : items4[fillterLang(items4)]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import customfilter from "@/typescript/filter";
export default {
  data() {
    return {
      intID: "",
      result: "",
      locales: "en",
      listSortData: null,
    };
  },
  props: {
    treeData: { type: Object },
    blnSystemword: { type: Boolean, default: true },
    blnselected: { type: Boolean, default: false },
    selectedValue: { type: String },
  },
  components: {
    Icon,
  },
  emits: ["onLevFt", "onLevSn"],
  mounted() {
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }

    // console.log("mounted");
    setTimeout(() => {
      this.listSortData = this.treeData;
      // console.log(this.listSortData);
      // console.log(this.selectedValue);
      this.SearchListDataTable(this.selectedValue);
    }, 2000);
  },

  methods: {
    checkLevelFt(event, item) {
      // console.log(event);
      // console.log(item);
      //   this.blnshow = !this.blnshow;
      if (event.children && event.children.length) {
        // this.blnshow = !this.blnshow;
        // this.result = "Fail";

        this.result =
          this.blnSystemword == true
            ? event.name
            : event[this.fillterLang(event)];
        // console.log(this.result);
        this.$emit("onLevFt", this.result);

        this.intID = event.strID;
        this.$emit("getID", this.intID);
      } else {
        // console.log(
        //   this.blnSystemword == true ? item.name : item[this.fillterLang(item)]
        // );
        // this.blnshow = !this.blnshow;
        this.result =
          this.blnSystemword == true
            ? item.name
            : item[this.fillterLang(item)] + "/ " + this.blnSystemword == true
            ? event.name
            : event[this.fillterLang(event)];
        // console.log(this.result);
        this.$emit("onLevFt", this.result);

        this.intID = event.strID;
        this.$emit("getID", this.intID);
      }
    },
    checkLevelSn(event, items, item) {
      // console.log(event);
      //   this.blnshow = !this.blnshow;
      if (event.children && event.children.length) {
        // this.blnshow = !this.blnshow;
        // this.result = "Fail";
        this.result =
          this.blnSystemword == true
            ? event.name
            : event[this.fillterLang(event)];
        // console.log(this.result);
        this.$emit("onLevSn", this.result);

        this.intID = event.strID;
        this.$emit("getID", this.intID);
      } else {
        // this.blnshow = !this.blnshow;
        this.result =
          this.blnSystemword == true
            ? item.name
            : item[this.fillterLang(item)] + "/ " + this.blnSystemword == true
            ? items.name
            : items[this.fillterLang(items)] + "/ " + this.blnSystemword == true
            ? event.name
            : event[this.fillterLang(event)];
        // console.log(this.result);
        this.$emit("onLevSn", this.result);

        this.intID = event.strID;
        this.$emit("getID", this.intID);
      }
    },
    checkLevelTh(event, items1, items, item) {
      // console.log(event);
      //   this.blnshow = !this.blnshow;
      if (event.children && event.children.length) {
        // this.blnshow = !this.blnshow;
        // this.result = "Fail";
        this.result =
          this.blnSystemword == true
            ? event.name
            : event[this.fillterLang(event)];
        // console.log(this.result);
        this.$emit("onLevSn", this.result);

        this.intID = event.strID;
        this.$emit("getID", this.intID);
      } else {
        // this.blnshow = !this.blnshow;
        this.result =
          this.blnSystemword == true
            ? item.name
            : item[this.fillterLang(item)] + "/ " + this.blnSystemword == true
            ? items.name
            : items[this.fillterLang(items)] + "/ " + this.blnSystemword == true
            ? items1.name
            : items1[this.fillterLang(items1)] + "/ " + this.blnSystemword ==
              true
            ? event.name
            : event[this.fillterLang(event)];
        // console.log(this.result);
        this.$emit("onLevSn", this.result);

        this.intID = event.strID;
        this.$emit("getID", this.intID);
      }
    },

    //function การค้นหา ชื่อตามภาษาที่กำหนด
    fillterLang(items) {
      if (items !== undefined) {
        return customfilter.filterLang(items, this.locales);
      }
    },

    //แสดงผลการเลือกข้อมูลจกาครั้งก่อนหน้า
    displayResultOfSelected(item) {
      // console.log(item);
      // console.log(item[0]);
      // console.log(item[0].children.length);
      // console.log(this.selectedValue);
      if (item.length !== 0) {
        if (item[0].strID == this.selectedValue) {
          // level1
          this.result =
            this.blnSystemword == true
              ? item.name
              : item[0][this.fillterLang(item[0])];
          // console.log(item[0][this.fillterLang(item[0])]);
          // console.log(this.result);
          // if (Array.isArray(item[0].children) && item[0].children.length !== 0) {
          //   this.result += "/ ";
          //   this.displayResultOfSelected(item[0].children);
          // }
        } else if (item[0].children[0].strID == this.selectedValue) {
          // level2
          this.result =
            this.blnSystemword == true
              ? item.name
              : item[0].children[0][this.fillterLang(item[0].children[0])];
          // console.log(this.result);
        } else if (
          item[0].children[0].children[0].strID == this.selectedValue
        ) {
          // level3
          this.result =
            this.blnSystemword == true
              ? item.name
              : item[0].children[0].children[0][
                  this.fillterLang(item[0].children[0].children[0])
                ];
          // console.log(this.result);
        } else if (
          item[0].children[0].children[0].children[0].strID ==
          this.selectedValue
        ) {
          // level4
          this.result =
            this.blnSystemword == true
              ? item.name
              : item[0].children[0].children[0].children[0][
                  this.fillterLang(item[0].children[0].children[0].children[0])
                ];
          // console.log(this.result);
        } else if (
          item[0].children[0].children[0].children[0].children[0].strID ==
          this.selectedValue
        ) {
          // level5
          this.result =
            this.blnSystemword == true
              ? item.name
              : item[0].children[0].children[0].children[0].children[0][
                  this.fillterLang(
                    item[0].children[0].children[0].children[0].children[0]
                  )
                ];
          // console.log(this.result);
        } else if (
          item[0].children[0].children[0].children[0].children[0].children[0]
            .strID == this.selectedValue
        ) {
          // level6
          this.result =
            this.blnSystemword == true
              ? item.name
              : item[0].children[0].children[0].children[0].children[0]
                  .children[0][
                  this.fillterLang(
                    item[0].children[0].children[0].children[0].children[0]
                      .children[0]
                  )
                ];
          // console.log(this.result);
        }
      }
    },
    //ค้นหาข้อมูลที่เลือกในครั้งก่อนหน้าาแสดงผลที่ result
    async SearchListDataTable(event) {
      // try {
      // console.log(event);
      if (event !== null && event !== "") {
        // console.log(this.treeData);

        //เรียก function การครั้งหาข้อมูลใน tree list
        this.listSortData = await this.filter({ ...this.treeData }, event);
        // console.log(this.listSortData);
        //ค้นหาชื่อมาแสดงผล
        this.displayResultOfSelected(this.listSortData);

        this.$emit("onLevSn", this.result);
      } else if (event == null || event == "") {
        this.listSortData = this.resetData(this.ListDataTable.data);
      }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    //function การ loop ค้าหาข้อมูลแบบ treee list
    async filter(array, text) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.children)) {
          const children = object.children.reduce(getNodes, []);

          if (children.length) {
            result.push({ ...object, children });
            return result;
          }
        }
        if (this.filterSelected(object, text)) {
          object.highlight = true;

          result.push(object);
          return result;
        }

        return result;
      };

      return Object.values(array).slice().reduce(getNodes, []);
    },

    //ค้นหาข้อมูลที่มีค่าตรงกับที่กำหนด
    filterSelected(item, event) {
      // console.log(item);

      const filterKeys = Object.keys(item);
      return filterKeys.some((key) => {
        if (key.toString().toLowerCase().indexOf("strid") !== -1) {
          //console.log(event);
          // console.log(item[key]);
          return item[key] == event;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding: 13px 16px;
  height: 44px;
  cursor: pointer;
  transition: 150ms;
  position: relative;
  &:hover {
    background-color: $black-50;
  }
  &:active {
    background-color: $black-200;
  }
  .line {
    height: 44px;
    width: 1px;
    background-color: $black-400;
    position: relative;
  }
  .line-hori {
    height: 44px;
    width: 1px;
    background-color: $black-400;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: 50%;
      width: 24px;
      height: 1px;
      background-color: $black-400;
      transform: translateY(-50%);
    }
  }
}
.tree {
  max-height: calc(44px * 7);
  min-height: fit-content;
  overflow-y: scroll;
}
</style>